import axios from 'axios';
import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../contexts/Store';

function useAxiosHook() {

    const { state, setState } = useContext(Context);

    const [loadingBar, setLoadingBar] = useState(false);

    const service = axios.create({
        baseURL: 'https://awingvisual.balerservice.com/api/v1/admin/'
        // baseURL: 'http://127.0.0.1:8000/api/v1/admin/'
    });

    const navigate = useNavigate();

    const successResponse = (response) => { return response; }

    const errorResponse = (e) => {

        if (!e.response) {
            return {};
        }

        if (e.response.status && e.response.status === 403) {
            localStorage.removeItem('awing-admin-client');
            setState({ ...state, reLoginRequired: true });
            setLoadingBar(false);
            setTimeout(() => {
                navigate('/login');
                return;
            }, 500);
            return e.response;
        }

        if (e.response.status && e.response.status === 401) {
            localStorage.removeItem('awing-admin-client');
            setState({ ...state, reLoginRequired: true });
            setLoadingBar(false);
            setTimeout(() => {
                navigate('/login');
                return;
            }, 500);
            return e.response;
        }

        if (e.response.data && e.response.data.message) {
            setLoadingBar(false);
            alert(e.response.data.message);
        }

        return e.response;
    }

    service.interceptors.response.use(successResponse, errorResponse);
    service.interceptors.request.use((config) => {
        config.headers.Accept = "application/json";
        // config.headers.Content = "application/json";
        config.headers.Authorization = localStorage.getItem('awing-admin-client');
        // config.headers.Authorization = "Bearer 39|pwYWyNIXdyA6Nqcqf8RZSvi4CFzarVkudcq3ELpf";
        // config.headers.sY2wEddduTtey4IrCignature = 'bwdShopV4.';
        return config;
    });

    return {
        service
    };
}

export default useAxiosHook
