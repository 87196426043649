import LogoutIcon from "@mui/icons-material/Logout";
import { Alert, Box, Button, LinearProgress } from "@mui/material";
import { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Context } from "../../contexts/Store";
import useUserHook from "../../hooks/UserHook";
import DropdownMenu from "./DropdownMenu";

interface actionButton {
    headTitle: String;
    buttonIcon?: any;
    buttonText?: String;
    buttonURL?: String;
}

function Header(props: actionButton) {
    const { state, setState } = useContext(Context);
    const { user } = useUserHook();

    const navigate = useNavigate();
    const handleLogOut = () => {
        localStorage.removeItem("awing-admin-client");
        navigate("/login");
    };

    return (
        <>
            <div className="flex justify-between shadow-sm items-center bg-white py-3">
                <div className=" flex text-gray-700  ml-2 justify-center items-center h-fit">
                    <h1 className=" pl-1 text-lg">{props.headTitle}</h1>
                </div>
                <div className=" ml-0  pr-3 flex justify-end space-x-4 items-center">
                    {props.buttonText && (
                        <Button
                            variant="outlined"
                            startIcon={props.buttonIcon}
                            style={{
                                borderColor: "#898989",
                            }}
                            component={Link}
                            to={`/${props.buttonURL}`}
                        >
                            {props.buttonText}
                        </Button>
                    )}

                    <button onClick={handleLogOut}>
                        <LogoutIcon />
                    </button>
                    <div className=" flex px-1  items-center">
                        <DropdownMenu />
                    </div>
                </div>
            </div>
            {state.loading && (
                <Box sx={{ width: "100%" }}>
                    <LinearProgress />
                </Box>
            )}

            {state.successMessage.length > 0 && (
                <Alert severity="success" color="success">
                    {state.successMessage}
                </Alert>
            )}
        </>
    );
}

export default Header;
