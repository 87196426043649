import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SnackbarOrigin,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import { useQuery } from "@tanstack/react-query";
import { SyntheticEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";
import { checkTeamValidation } from "../../utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

export interface State extends SnackbarOrigin {
    open: boolean;
}

interface TeamState {
    name: string;
    url: string;
    designation: string | null;
    aboutMe: string;
    info: string;
    services: string[];
    position: any;
    status: any;
    hero_image_alt: string;
    avatar_image_alt: string;
    facebook: string;
    twitter: string;
    linkedIn: string;
    avatar: string;
    heroImage: string;
    heroVideo: string;
    display_home: any;
    meta_title: string;
    meta_description: string;
    meta_keywords: string;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function TeamAdd() {
    // responsible for tab changing
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { data } = useQuery(
        ["get-all-services"],
        () => service.get("getService"),
        {
            refetchOnWindowFocus: false,
        }
    );

    const { data: categoryResponse } = useQuery(
        ["get-team-categories"],
        () => service.get("team-categories"),
        {
            refetchOnWindowFocus: false,
        }
    );

    const [team, setTeam] = useState<TeamState>({
        name: "",
        url: "",
        designation: "",
        position: "",
        aboutMe: "",
        info: "",
        services: [],
        status: false,
        facebook: "",
        twitter: "",
        hero_image_alt: "",
        avatar_image_alt: "",
        linkedIn: "",
        avatar: "",
        heroImage: "",
        heroVideo: "",
        display_home: false,
        meta_title: "",
        meta_description: "",
        meta_keywords: "",
    });

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setTeam((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleContentChange = (content, editor) => {
        setTeam((prev) => ({ ...prev, aboutMe: content }));
    };

    // services array
    const [services, setServices] = useState<any[]>([]);

    // handling the name and automatic url slug generator
    const handleNameChange = (event) => {
        setTeam((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setTeam((prev) => ({ ...prev, url: slugValue }));
    };

    // const [heroImage, setHeroImage] = useState("");
    const [previewImage, setPreviewImage] = useState(null);
    const previewImageInputRef = useRef(null);

    const handleHeroImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setTeam((prev) => ({
                ...prev,
                heroImage: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        setTeam((prev) => ({
            ...prev,
            heroImage: "",
        }));
        // Reset the value of the input element
        previewImageInputRef.current.value = null;
    };

    // handle avatar image upload
    const [previewAvatarImage, setPreviewAvatarImage] = useState(null);
    const previewAvatarImageInputRef = useRef(null);

    const handleAvatarImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setTeam((prev) => ({
                ...prev,
                avatar: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewAvatarImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewAvatarImage = () => {
        setPreviewAvatarImage(null);
        setTeam((prev) => ({
            ...prev,
            avatar: "",
        }));
        // Reset the value of the input element
        previewAvatarImageInputRef.current.value = null;
    };

    //  faqs section starts here
    const [faqInputs, setFaqInputs] = useState([{ question: "", answer: "" }]);

    const handleQuestionInputChange = (index, event) => {
        const values = [...faqInputs];
        values[index].question = event.target.value;
        setFaqInputs(values);
    };

    const handleAnswerInputChange = (index, event) => {
        const values = [...faqInputs];
        values[index].answer = event.target.value;
        setFaqInputs(values);
    };

    const handleAddInput = () => {
        const values = [...faqInputs];
        values.push({ question: "", answer: "" });
        setFaqInputs(values);
    };

    const handleRemoveInput = (index) => {
        const values = [...faqInputs];
        values.splice(index, 1);
        setFaqInputs(values);
    };
    // end faqs inputs

    //  stats section starts here
    const [statsInputs, setStatsInputs] = useState([
        { title: "", description: "" },
    ]);

    const handleStatTitleChange = (index, event) => {
        const values = [...statsInputs];
        values[index].title = event.target.value;
        setStatsInputs(values);
    };

    const handleStatDescChange = (index, event) => {
        const values = [...statsInputs];
        values[index].description = event.target.value;
        setStatsInputs(values);
    };

    const handleAddStatInput = () => {
        const values = [...statsInputs];
        values.push({ title: "", description: "" });
        setStatsInputs(values);
    };

    const handleRemoveStatInput = (index) => {
        const values = [...statsInputs];
        values.splice(index, 1);
        setStatsInputs(values);
    };
    // end stats inputs

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    // handling the submission
    const { service } = useAxiosHook();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        const validationPassed = checkTeamValidation(
            team,
            services,
            statsInputs,
            faqInputs
        );

        formData.append("name", team.name);
        formData.append("url", team.url);
        formData.append("about_me", team.aboutMe);
        formData.append("team_category_id", team.designation);
        formData.append("position", team.position);
        formData.append("status", team.status ? "1" : "0");
        formData.append("display_home", team.display_home ? "1" : "0");
        formData.append("facebook", team.facebook);
        formData.append("twitter", team.twitter);
        formData.append("linked_in", team.linkedIn);
        formData.append("hero_image_id", team.heroImage);
        formData.append("avatar_image_alt", team.avatar_image_alt);
        formData.append("hero_image_alt", team.hero_image_alt);
        formData.append("avatar_image_id", team.avatar);
        formData.append("hero_video_id", team.heroVideo);
        formData.append("meta_title", team.meta_title);
        formData.append("meta_description", team.meta_description);
        formData.append("meta_keywords", team.meta_keywords);

        services.forEach((service) => {
            formData.append("service_ids[]", service.id);
        });

        faqInputs.forEach((faq) => {
            formData.append("question[]", faq.question);
            formData.append("answer[]", faq.answer);
        });

        statsInputs.forEach((statData) => {
            formData.append("title[]", statData.title);
            formData.append("description[]", statData.description);
        });

        if (validationPassed) {
            try {
                setLoading(true);
                const response = await service.post("team", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                    },
                });
                if (response.status === 200) {
                    setLoading(false);
                    setAlertInfo({
                        show: true,
                        message: response.data.message,
                    });
                    setTimeout(() => {
                        navigate("/teams");
                    }, 1500);
                } else {
                    setAlertInfo({
                        show: true,
                        message: "Something went wrong",
                    });
                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: "",
                        });
                    }, 1500);
                    setLoading(false);
                }
            } catch (error) {
                setLoading(false);
            }
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />
            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Add Team Member"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Team List"
                    buttonURL="teams"
                />
                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="Stats" {...a11yProps(1)} />
                                    <Tab label="FAQ" {...a11yProps(2)} />
                                    <Tab label="SEO" {...a11yProps(3)} />
                                </Tabs>
                            </Box>
                            <Box component="div">
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                    name="team-create-form"
                                    id="team-create-form"
                                    encType="multipart/form-data"
                                    className="myForm"
                                >
                                    <FormGroup>
                                        {/* Team Tab */}
                                        <TabPanel value={value} index={0}>
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    className="bg-white p-4"
                                                    xs={12}
                                                    md={8}
                                                >
                                                    <TextField
                                                        id="name"
                                                        label="Name"
                                                        name="name"
                                                        variant="outlined"
                                                        value={team.name}
                                                        required
                                                        onChange={
                                                            handleNameChange
                                                        }
                                                        fullWidth
                                                        sx={{ mb: 3 }}
                                                    />
                                                    <TextField
                                                        id="url"
                                                        label="URL"
                                                        name="url"
                                                        variant="outlined"
                                                        value={team.url}
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                        onChange={
                                                            handleChangeValue
                                                        }
                                                    />

                                                    <FormControl
                                                        fullWidth
                                                        sx={{ mb: 3 }}
                                                    >
                                                        <InputLabel id="demo-simple-select-label">
                                                            Selct Designation *
                                                        </InputLabel>
                                                        <Select
                                                            required
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                team.designation
                                                            }
                                                            label="Select Designation"
                                                            name="designation"
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                        >
                                                            {categoryResponse?.data?.map(
                                                                (
                                                                    category,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            category.id
                                                                        }
                                                                        value={
                                                                            category.id
                                                                        }
                                                                    >
                                                                        {
                                                                            category.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </FormControl>

                                                    <span className="text-gray-800 text-lg">
                                                        About *
                                                    </span>
                                                    <TinyEditor
                                                        handleEditorChange={(
                                                            content,
                                                            editor
                                                        ) =>
                                                            handleContentChange(
                                                                content,
                                                                editor
                                                            )
                                                        }
                                                        contentData={
                                                            team.aboutMe
                                                        }
                                                    />

                                                    <FormControl
                                                        variant="outlined"
                                                        fullWidth
                                                        sx={{ mt: 4, mb: 3 }}
                                                    >
                                                        <Autocomplete
                                                            multiple
                                                            value={services}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setServices(
                                                                    newValue
                                                                );
                                                            }}
                                                            id="categories-id-demo"
                                                            options={
                                                                data?.data
                                                                    ?.data || []
                                                            }
                                                            disableCloseOnSelect
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li
                                                                    {...props}
                                                                    key={
                                                                        option.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Services"
                                                                    placeholder="Select Categories"
                                                                />
                                                            )}
                                                        />
                                                    </FormControl>
                                                </Grid>

                                                <Grid
                                                    item
                                                    style={{
                                                        paddingTop: 0,
                                                        marginTop: "-8px",
                                                        paddingLeft: 0,
                                                    }}
                                                    className="bg-gray-100"
                                                    xs={12}
                                                    md={4}
                                                >
                                                    <Box
                                                        component="div"
                                                        className="p-3"
                                                    >
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {previewImage && (
                                                                <div className="w-full relative mb-4">
                                                                    <img
                                                                        className="h-80 w-full object-cover rounded-md"
                                                                        src={
                                                                            previewImage
                                                                        }
                                                                        alt="Preview"
                                                                    />
                                                                    <TextField
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                        fullWidth
                                                                        label="Image Alt Text"
                                                                        variant="outlined"
                                                                        required
                                                                        id="hero_image_alt"
                                                                        name="hero_image_alt"
                                                                        value={
                                                                            team.hero_image_alt
                                                                        }
                                                                        onChange={
                                                                            handleChangeValue
                                                                        }
                                                                        placeholder="Write the Alt text for this image"
                                                                        helperText="This field is required for the SEO purpose"
                                                                    />
                                                                    <button
                                                                        onClick={
                                                                            handleRemovePreviewImage
                                                                        }
                                                                        type="button"
                                                                        className="absolute border border-red-500 rounded text-red-500 right-4 top-4"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M6 18L18 6M6 6l12 12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}

                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                color="info"
                                                                startIcon={
                                                                    <FileUploadIcon />
                                                                }
                                                            >
                                                                Upload Hero
                                                                Image (Required)
                                                                <input
                                                                    type="file"
                                                                    ref={
                                                                        previewImageInputRef
                                                                    }
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    hidden
                                                                    onChange={
                                                                        handleHeroImage
                                                                    }
                                                                />
                                                            </Button>
                                                            <p
                                                                className="helper-text"
                                                                id="heroImage-helper-text"
                                                            >
                                                                Image upload
                                                                maximum size 5MB
                                                            </p>
                                                            <span className="mt-1 block font-medium">
                                                                Size (1200px X
                                                                1400px)
                                                            </span>
                                                        </Box>

                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {previewAvatarImage && (
                                                                <div className="w-full relative mb-4">
                                                                    <img
                                                                        className="h-80 w-full object-cover rounded-md"
                                                                        src={
                                                                            previewAvatarImage
                                                                        }
                                                                        alt="Preview"
                                                                    />
                                                                    <TextField
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                        fullWidth
                                                                        label="Image Alt Text"
                                                                        variant="outlined"
                                                                        required
                                                                        id="avatar_image_alt"
                                                                        name="avatar_image_alt"
                                                                        value={
                                                                            team.avatar_image_alt
                                                                        }
                                                                        onChange={
                                                                            handleChangeValue
                                                                        }
                                                                        placeholder="Write the Alt text for this image"
                                                                        helperText="This field is required for the SEO purpose"
                                                                    />
                                                                    <button
                                                                        onClick={
                                                                            handleRemovePreviewAvatarImage
                                                                        }
                                                                        type="button"
                                                                        className="absolute border border-black rounded text-black right-4 top-4"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M6 18L18 6M6 6l12 12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}

                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                color="info"
                                                                startIcon={
                                                                    <FileUploadIcon />
                                                                }
                                                            >
                                                                Upload Avatar
                                                                Image (Required)
                                                                <input
                                                                    type="file"
                                                                    ref={
                                                                        previewAvatarImageInputRef
                                                                    }
                                                                    accept="image/png, image/gif, image/jpeg"
                                                                    hidden
                                                                    onChange={
                                                                        handleAvatarImage
                                                                    }
                                                                />
                                                            </Button>
                                                            <p
                                                                className="helper-text"
                                                                id="heroImage-helper-text"
                                                            >
                                                                Image upload
                                                                maximum size 5MB
                                                            </p>
                                                            <span className="mt-1 block font-medium">
                                                                Size (600px X
                                                                600px)
                                                            </span>
                                                        </Box>

                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="heroVideo"
                                                                label="Hero Video URL"
                                                                name="heroVideo"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={
                                                                    team.heroVideo
                                                                }
                                                                onChange={
                                                                    handleChangeValue
                                                                }
                                                                helperText="Add the video url of ie(Vimeo, YouTube)"
                                                            />
                                                        </Box>

                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="facebook"
                                                                label="Facebook"
                                                                name="facebook"
                                                                type="url"
                                                                variant="outlined"
                                                                value={
                                                                    team.facebook
                                                                }
                                                                onChange={
                                                                    handleChangeValue
                                                                }
                                                                fullWidth
                                                            />
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="twitter"
                                                                label="Twitter"
                                                                name="twitter"
                                                                type="url"
                                                                value={
                                                                    team.twitter
                                                                }
                                                                onChange={
                                                                    handleChangeValue
                                                                }
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="linked_in"
                                                                label="Linked In"
                                                                name="linkedIn"
                                                                value={
                                                                    team.linkedIn
                                                                }
                                                                onChange={
                                                                    handleChangeValue
                                                                }
                                                                type="url"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="position"
                                                                label="Position"
                                                                name="position"
                                                                value={
                                                                    team.position
                                                                }
                                                                onChange={
                                                                    handleChangeValue
                                                                }
                                                                type="number"
                                                                variant="outlined"
                                                                fullWidth
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        {/* SEO Tab */}
                                        <TabPanel value={value} index={3}>
                                            <TextField
                                                id="meta_title"
                                                label="Meta Title"
                                                name="meta_title"
                                                value={team.meta_title}
                                                onChange={handleChangeValue}
                                                type="text"
                                                required
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mb: 3 }}
                                            />
                                            <TextField
                                                id="meta_description"
                                                label="Meta Description"
                                                name="meta_description"
                                                value={team.meta_description}
                                                multiline
                                                rows={4}
                                                required
                                                onChange={handleChangeValue}
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mb: 3 }}
                                            />
                                        </TabPanel>

                                        {/* FAQ Tab */}
                                        <TabPanel value={value} index={2}>
                                            {faqInputs.map((input, index) => (
                                                <Box
                                                    key={index}
                                                    mb={2}
                                                    component="div"
                                                    border={1}
                                                    borderColor="ActiveBorder"
                                                    borderRadius={1}
                                                    marginBottom={2}
                                                    padding={2}
                                                >
                                                    <TextField
                                                        id={"question_" + index}
                                                        label="Question"
                                                        variant="outlined"
                                                        name="question"
                                                        fullWidth
                                                        value={input.question}
                                                        onChange={(event) =>
                                                            handleQuestionInputChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        label="Answer"
                                                        name="answer"
                                                        required
                                                        multiline
                                                        sx={{ mb: 3 }}
                                                        rows={4}
                                                        value={input.answer}
                                                        onChange={(event) =>
                                                            handleAnswerInputChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                    />

                                                    <Button
                                                        sx={{ mr: 4 }}
                                                        onClick={() =>
                                                            handleRemoveInput(
                                                                index
                                                            )
                                                        }
                                                        disabled={
                                                            faqInputs.length ===
                                                            1
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            ))}

                                            <div className="flex justify-end mt-4">
                                                <Button
                                                    onClick={handleAddInput}
                                                    variant="contained"
                                                    startIcon={
                                                        <AddOutlinedIcon />
                                                    }
                                                >
                                                    Add More
                                                </Button>
                                            </div>
                                        </TabPanel>

                                        {/* STATS Tab */}
                                        <TabPanel value={value} index={1}>
                                            {statsInputs.map((input, index) => (
                                                <Box
                                                    key={index}
                                                    mb={2}
                                                    component="div"
                                                    border={1}
                                                    borderColor="ActiveBorder"
                                                    borderRadius={1}
                                                    marginBottom={2}
                                                    padding={2}
                                                >
                                                    <TextField
                                                        id={"title" + index}
                                                        label="Title"
                                                        variant="outlined"
                                                        name="title"
                                                        fullWidth
                                                        value={input.title}
                                                        onChange={(event) =>
                                                            handleStatTitleChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        label="Description"
                                                        name="description"
                                                        multiline
                                                        sx={{ mb: 3 }}
                                                        rows={4}
                                                        value={
                                                            input.description
                                                        }
                                                        onChange={(event) =>
                                                            handleStatDescChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                    />

                                                    <Button
                                                        sx={{ mr: 4 }}
                                                        onClick={() =>
                                                            handleRemoveStatInput(
                                                                index
                                                            )
                                                        }
                                                        disabled={
                                                            statsInputs.length ===
                                                            1
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            ))}

                                            <div className="flex justify-end mt-4">
                                                <Button
                                                    onClick={handleAddStatInput}
                                                    variant="contained"
                                                    startIcon={
                                                        <AddOutlinedIcon />
                                                    }
                                                >
                                                    Add More
                                                </Button>
                                            </div>
                                        </TabPanel>
                                    </FormGroup>

                                    <Box
                                        component="div"
                                        sx={{
                                            borderTop: 1,
                                            borderColor: "divider",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            py: "20px",
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            className="text-gray-900"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={team.status}
                                                        name="status"
                                                        value={team.status}
                                                        onChange={(e) =>
                                                            setTeam((prev) => ({
                                                                ...prev,
                                                                status: e.target
                                                                    .checked
                                                                    ? true
                                                                    : false,
                                                            }))
                                                        }
                                                    />
                                                }
                                                label="Status"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            team.display_home
                                                        }
                                                        name="status"
                                                        value={
                                                            team.display_home
                                                        }
                                                        onChange={(e) =>
                                                            setTeam((prev) => ({
                                                                ...prev,
                                                                display_home: e
                                                                    .target
                                                                    .checked
                                                                    ? true
                                                                    : false,
                                                            }))
                                                        }
                                                    />
                                                }
                                                label="Display in Home"
                                            />
                                        </Box>

                                        {loading ? (
                                            <Backdrop
                                                sx={{
                                                    color: "#fff",
                                                    zIndex: (theme) =>
                                                        theme.zIndex.drawer + 1,
                                                }}
                                                open={true}
                                            >
                                                <Typography sx={{ mr: 2 }}>
                                                    Saving...
                                                </Typography>
                                                <CircularProgress color="secondary" />
                                            </Backdrop>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                size="large"
                                                type="submit"
                                                endIcon={<SendIcon />}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default TeamAdd;
