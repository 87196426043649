import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { SyntheticEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

import PostCategoryInterface from "../../components/Interfaces/PostCategoryInterface";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useAxiosHook from "../../hooks/AxiosHook";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function PostCategoryAdd() {
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [pc, setTagValue] = useState<PostCategoryInterface>({
        name: "",
        url: "",
        status: false,
        meta_title: "",
        meta_description: "",
    });

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setTagValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleNameChange = (event) => {
        setTagValue((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setTagValue((prev) => ({ ...prev, url: slugValue }));
    };

    const navigate = useNavigate();

    const { service } = useAxiosHook();
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    const [loading, setLoading] = useState(false);
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        let statusValue: number = pc.status === true ? 1 : 0;

        const formData = new FormData();

        if (pc.meta_title === "" || pc.meta_description === "") {
            return alert(
                "Meta fields cannot be empty, they are for SEO purpose"
            );
        }

        formData.append("name", pc.name);
        formData.append("url", pc.url);
        formData.append("status", statusValue.toString());
        formData.append("meta_title", pc.meta_title);
        formData.append("meta_description", pc.meta_description);
        setLoading(true);
        try {
            const response = await service.post("post-category", formData);
            if (response.status === 200) {
                setLoading(false);
                setAlertInfo({
                    show: true,
                    message: "Post Category Added",
                });

                setTimeout(() => {
                    navigate("/post-categories");
                }, 3000);
            } else {
                setLoading(false);
                setAlertInfo({
                    show: true,
                    message: "Something went wrong",
                });
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />
            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Add Post Category"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Post Categories"
                    buttonURL="post-categories"
                />
                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:text-white dark:shadow-none w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="SEO" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="our-customer-create-form"
                                id="our-customer-create-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <TextField
                                            id="name"
                                            label="Name"
                                            name="name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={pc.name}
                                            onChange={handleNameChange}
                                        />
                                        <TextField
                                            id="url"
                                            label="URL"
                                            name="url"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={pc.url}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mb: 3 }}
                                            value={pc.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mb: 3 }}
                                            multiline
                                            rows={4}
                                            value={pc.meta_description}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <FormControlLabel
                                        className="text-gray-900"
                                        control={
                                            <Switch
                                                checked={pc.status}
                                                name="status"
                                                value={pc.status}
                                                onChange={(e) =>
                                                    setTagValue((prev) => ({
                                                        ...prev,
                                                        status: e.target.checked
                                                            ? true
                                                            : false,
                                                    }))
                                                }
                                            />
                                        }
                                        label="Status"
                                    />
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default PostCategoryAdd;
