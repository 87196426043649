export const apiKey = "kugqzag6sf0jxnetl94v542ompov15bixo47unpcy0jafwr3";

export const init = {
    height: 500,
    width: 700,
    toolbar_mode: "sliding",
    browser_spellcheck: true,
    relative_urls: false,
    remove_script_host: false,
    image_title: true,
    convert_urls: false,
    image_advtab: false,
    menubar: true,
    branding: false,
    block_unsupported_drop: false,
    paste_data_images: false,
    automatic_uploads: true,
    autosave_ask_before_unload: false,
    noneditable_noneditable_class: "mceNonEditable",
    images_upload_credentials: false,
    directionality: "ltr",
    file_picker_types: "file image media",
    images_upload_handler: function (blobInfo, success, failure) {
        setTimeout(function () {
            /* no matter what you upload, we will turn it into TinyMCE logo :)*/
            success("http://moxiecode.cachefly.net/tinymce/v9/images/logo.png");
        }, 2000);
    },
};

export const toolbar =
    "undo redo | link image | bold italic underline strikethrough | alignleft aligncenter alignright alignjustify |  numlist bullist | fontselect fontsizeselect formatselect paste pastetext | outdent indent | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | link anchor codesample | ltr rtl | code";

export const plugins =
    "preview importcss image quickbars searchreplace autolink autosave save directionality code visualblocks visualchars fullscreen link codesample charmap pagebreak nonbreaking anchor insertdatetime advlist lists wordcount help charmap emoticons";

// add the quickbars plugin if you want add the image upload or table adding feature whenever user enter and goto the next line
