import useAxiosHook from "./AxiosHook";

function usePostCategoryHook() {
    const { service } = useAxiosHook();

    async function getTags() {
        return await service.get("/getTags").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service
            .get("/post-category?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteCategory(id: number) {
        return await service.delete("/post-category/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getTags,
        fetch,
        deleteCategory,
    };
}

export default usePostCategoryHook;
