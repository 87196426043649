import { useContext } from "react";
import { AuthContext } from "../contexts/AuthContext";
import { AuthContextType } from "../types/AuthContext.type";
import useAxiosHook from "./AxiosHook";

function useUserHook() {
    const { service } = useAxiosHook();
    const { user } = useContext<AuthContextType>(AuthContext);

    function getUserList() {
        return service.get("/user/list").then((res: any) => {
            return res.data.items;
        });
    }

    async function getWarehouses() {
        return service.get("user/warehouses").then((res) => res.data);
    }

    async function getUserForEditing(userId: number) {
        return service.get(`user/${userId}`).then((res) => res.data);
    }

    function getRoles() {
        if (!user) {
            return;
        }

        return [
            {
                id: 2,
                name: "Warehouse Owner",
            },
            {
                id: 3,
                name: "Warehouse Employee",
            },
        ];
    }

    function updateUser(user: any) {
        return service
            .put("user/update", { ...user, isSuperAdmin: user.superAdmin })
            .then((res) => res.data)
            .catch((e) => e.response);
    }

    function createUser(user: any) {
        return service
            .post("user/create", {
                ...user,
                isSuperAdmin: user.superAdmin || false,
            })
            .then((res) => res.data)
            .catch((e) => e.response);
    }

    async function getLoggedInUser() {
        return null; // service.get('/user').then(res => res.data);
    }

    return {
        getUserList,
        getWarehouses,
        getUserForEditing,
        getRoles,
        createUser,
        updateUser,
        user,
        getLoggedInUser,
    };
}

export default useUserHook;
