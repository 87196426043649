import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormGroup,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { useQuery } from "@tanstack/react-query";
import moment from "moment";
import { SyntheticEvent, useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";
import { checkProjectValidation } from "../../utils";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function EditProject() {
    // tab changing function
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const { service } = useAxiosHook();

    // get all services data
    const [services, setServices] = useState<any[]>([]);
    const { data: serviceResponse } = useQuery(
        ["get-all-services"],
        () => service.get("getService"),
        {
            refetchOnWindowFocus: false,
        }
    );

    // get all team members data
    const [teams, setTeams] = useState<any[]>([]);
    const { data: teamResponse } = useQuery(
        ["get-edit-team-members"],
        () => service.get("getTeam"),
        {
            refetchOnWindowFocus: false,
        }
    );

    // get all equipments data
    const [equipments, setEquipments] = useState<any[]>([]);
    const { data: equipmentResponse } = useQuery(
        ["get-edit-equipments"],
        () => service.get("getEquipment"),
        {
            refetchOnWindowFocus: false,
        }
    );

    // get all our customers data
    const { data: customerResponse } = useQuery(
        ["get-all-customers"],
        () => service.get("getOurCustomer"),
        {
            refetchOnWindowFocus: false,
        }
    );

    const [projects, setProjects] = useState({
        title: "",
        url: "",
        customer: "",
        short_description: "",
        description: "",
        address: "",
        start_date: "",
        end_date: "",
        meta_title: "",
        meta_description: "",
        hero_image_alt: "",
        heroImage: "",
        heroVideo: "",
        display_home: false,
        status: false,
    });

    // saving images for later use, so that when user upload one
    // image and cancel it, we could refer the original image from here
    const [showAltImage, setShowAltImage] = useState("");

    const { id } = useParams();
    useQuery(
        ["get-single-project"],
        () => serviceAPI.get(`completed-project/${id}`),
        {
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                setProjects({
                    title: res.data.data.title,
                    url: res.data.data.url,
                    customer: res.data.data.our_customer_id,
                    short_description: res.data.data.short_description,
                    description: res.data.data.description,
                    address: res.data.data.address_id,
                    hero_image_alt: res.data.data.hero_image_alt,
                    heroImage: res.data.data.hero_image?.path,
                    heroVideo: res.data.data.video_id,
                    start_date: res.data.data.start_date,
                    end_date: res.data.data.end_date,
                    display_home: res.data.data.display_home,
                    status: res.data.data.status,
                    meta_title: res.data.data.meta_title,
                    meta_description: res.data.data.meta_description,
                });
                setServices(res.data.data.services);
                setTeams(res.data.data.teams);
                setEquipments(res.data.data.equipment);

                setShowAltImage(res.data.data.hero_image?.path);
                const faqs = res.data.data.faqs;
                const transformedFaqs = faqs.map((faq: any) => {
                    return {
                        question: faq.question,
                        answer: faq.answer,
                    };
                });
                faqs.length >= 1 && setFaqInputs(transformedFaqs);
            },
        }
    );

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setProjects((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // handle title change and generete auto slug

    const handleTitleChange = (event) => {
        setProjects((prev) => ({ ...prev, title: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setProjects((prev) => ({ ...prev, url: slugValue }));
    };

    // handling image and videos input
    const [previewImage, setPreviewImage] = useState(null);
    const handleHeroImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setProjects((prev) => ({
                ...prev,
                heroImage: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const previewImageInputRef = useRef(null);

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        // Reset the value of the input element
        previewImageInputRef.current.value = "";
        setProjects((prev) => ({ ...prev, heroImage: showAltImage }));
    };

    // faqs tab section

    const [faqInputs, setFaqInputs] = useState([{ question: "", answer: "" }]);

    const handleQuestionInputChange = (index, event) => {
        const values = [...faqInputs];
        values[index].question = event.target.value;
        setFaqInputs(values);
    };

    const handleAnswerInputChange = (index, event) => {
        const values = [...faqInputs];
        values[index].answer = event.target.value;
        setFaqInputs(values);
    };

    const handleAddInput = () => {
        const values = [...faqInputs];
        values.push({ question: "", answer: "" });
        setFaqInputs(values);
    };

    const handleRemoveInput = (index) => {
        const values = [...faqInputs];
        values.splice(index, 1);
        setFaqInputs(values);
    };

    // faq section ends

    const handleContentChange = (content, editor) => {
        setProjects((prev) => ({ ...prev, description: content }));
    };

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    // submit the form
    const [loading, setLoading] = useState(false);
    const { service: serviceAPI } = useAxiosHook();
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();

        const validationPassed = checkProjectValidation(
            projects,
            services,
            teams,
            equipments,
            faqInputs
        );

        formData.append("title", projects.title);
        formData.append("url", projects.url);
        formData.append("short_description", projects.short_description);
        formData.append("description", projects.description);
        formData.append("display_home", projects.display_home ? "1" : "0");
        formData.append("status", projects.status ? "1" : "0");
        formData.append("our_customer_id", projects.customer);
        formData.append("address_id", projects.address);
        formData.append(
            "start_date",
            moment(projects.start_date).format("YYYY/MM/DD")
        );
        formData.append(
            "end_date",
            moment(projects.end_date).format("YYYY/MM/DD")
        );
        formData.append("image_id", projects.heroImage);
        formData.append("hero_image_alt", projects.hero_image_alt);
        formData.append("video_id", projects.heroVideo);
        formData.append("meta_title", projects.meta_title);
        formData.append("meta_description", projects.meta_description);

        services.forEach((service, index) => {
            formData.append("services[]", service.id);
        });

        teams.forEach((team, index) => {
            formData.append("teams[]", team.id);
        });

        equipments.forEach((equipment, index) => {
            formData.append("equipment[]", equipment.id);
        });

        faqInputs.forEach((faq, index) => {
            formData.append("question[]", faq.question);
            formData.append("answer[]", faq.answer);
        });

        if (validationPassed) {
            try {
                setLoading(true);
                const { data, status } = await serviceAPI.post(
                    `completed-project/edit/${id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "application/json",
                        },
                    }
                );
                setLoading(false);
                if (status === 200) {
                    setAlertInfo({
                        show: true,
                        message: "Project updated",
                    });
                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: null,
                        });
                    }, 3000);
                } else {
                    setAlertInfo({
                        show: true,
                        message: "Something went wrong",
                    });
                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: null,
                        });
                    }, 3000);
                }
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />
            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />
            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Edit Project"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Projects"
                    buttonURL="completed-projects"
                />
                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:text-black dark:shadow-none w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="FAQ" {...a11yProps(1)} />
                                    <Tab label="SEO" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <Box component="div">
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                    name="projects-edit-form"
                                    id="projects-edit-form"
                                    encType="multipart/form-data"
                                    className="myForm"
                                >
                                    <FormGroup>
                                        {/* Information Tab */}
                                        <TabPanel value={value} index={0}>
                                            <Grid container spacing={2}>
                                                <Grid item xs={12} md={8}>
                                                    <TextField
                                                        id="title"
                                                        label="Title"
                                                        name="title"
                                                        variant="outlined"
                                                        fullWidth
                                                        value={projects.title}
                                                        onChange={
                                                            handleTitleChange
                                                        }
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />
                                                    <TextField
                                                        id="url"
                                                        label="URL"
                                                        name="url"
                                                        variant="outlined"
                                                        value={projects.url}
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />
                                                    <TextField
                                                        id="short_description"
                                                        label="Hero Content"
                                                        name="short_description"
                                                        variant="outlined"
                                                        value={
                                                            projects.short_description
                                                        }
                                                        onChange={
                                                            handleInputChange
                                                        }
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />
                                                    <label className="text-gray-800">
                                                        Description *
                                                    </label>
                                                    <TinyEditor
                                                        handleEditorChange={(
                                                            content,
                                                            editor
                                                        ) =>
                                                            handleContentChange(
                                                                content,
                                                                editor
                                                            )
                                                        }
                                                        contentData={
                                                            projects.description
                                                        }
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{
                                                        paddingTop: 0,
                                                        marginTop: "-8px",
                                                        paddingLeft: 0,
                                                    }}
                                                    className="bg-gray-100"
                                                    xs={12}
                                                    md={4}
                                                >
                                                    <Box className="p-3">
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {projects.heroImage &&
                                                                Object.keys(
                                                                    projects.heroImage
                                                                )?.length >=
                                                                    1 && (
                                                                    <>
                                                                        <div className="w-full mb-3 border relative">
                                                                            <img
                                                                                className="h-80 w-full object-cover rounded-md"
                                                                                src={
                                                                                    projects.heroImage
                                                                                }
                                                                                alt="Preview"
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            {previewImage && (
                                                                <div className="w-full relative">
                                                                    <img
                                                                        className="h-80 mb-3 w-full object-cover rounded-md"
                                                                        src={
                                                                            previewImage
                                                                        }
                                                                        alt="Preview"
                                                                    />
                                                                    <button
                                                                        onClick={
                                                                            handleRemovePreviewImage
                                                                        }
                                                                        type="button"
                                                                        className="absolute border border-black rounded text-black right-4 top-4"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M6 18L18 6M6 6l12 12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </div>
                                                            )}
                                                            <TextField
                                                                sx={{
                                                                    mt: 2,
                                                                }}
                                                                fullWidth
                                                                label="Image Alt Text"
                                                                variant="outlined"
                                                                required
                                                                id="hero_image_alt"
                                                                name="hero_image_alt"
                                                                value={
                                                                    projects.hero_image_alt
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                placeholder="Write the Alt text for this image"
                                                                helperText="This field is required for the SEO purpose"
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                color="info"
                                                                startIcon={
                                                                    <FileUploadIcon />
                                                                }
                                                            >
                                                                Upload hero
                                                                image *
                                                                <input
                                                                    type="file"
                                                                    hidden
                                                                    ref={
                                                                        previewImageInputRef
                                                                    }
                                                                    accept="image/*"
                                                                    onChange={
                                                                        handleHeroImage
                                                                    }
                                                                />
                                                            </Button>
                                                            <p
                                                                className="helper-text"
                                                                id="heroImage-helper-text"
                                                            >
                                                                Image upload
                                                                maximum size 5MB
                                                            </p>
                                                            <span className="mt-1 block font-medium">
                                                                Size (1200px X
                                                                1400px)
                                                            </span>
                                                        </Box>

                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="heroVideo"
                                                                label="Project Video URL"
                                                                name="heroVideo"
                                                                variant="outlined"
                                                                fullWidth
                                                                required
                                                                value={
                                                                    projects.heroVideo
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                                helperText="Add the video url of ie(Vimeo, YouTube)"
                                                            />
                                                        </Box>

                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {/* services autocomplete section */}
                                                            <FormControl
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{
                                                                    mt: 2,
                                                                }}
                                                            >
                                                                <Autocomplete
                                                                    options={
                                                                        serviceResponse
                                                                            ?.data
                                                                            ?.data ||
                                                                        []
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option?.name
                                                                    }
                                                                    value={
                                                                        services
                                                                    }
                                                                    isOptionEqualToValue={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option.id ===
                                                                        value.id
                                                                    }
                                                                    multiple
                                                                    onChange={(
                                                                        event,
                                                                        newValue
                                                                    ) => {
                                                                        setServices(
                                                                            newValue
                                                                        );
                                                                    }}
                                                                    id="categories-id-demo"
                                                                    disableCloseOnSelect
                                                                    renderOption={(
                                                                        props,
                                                                        option,
                                                                        {
                                                                            selected,
                                                                        }
                                                                    ) => (
                                                                        <li
                                                                            {...props}
                                                                            key={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    icon
                                                                                }
                                                                                checkedIcon={
                                                                                    checkedIcon
                                                                                }
                                                                                style={{
                                                                                    marginRight: 8,
                                                                                }}
                                                                                checked={
                                                                                    selected
                                                                                }
                                                                            />
                                                                            {
                                                                                option?.name
                                                                            }
                                                                        </li>
                                                                    )}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Services *"
                                                                            placeholder="Select Services"
                                                                            helperText="Select all the services involved in this project"
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {/* teams autocomplete section */}
                                                            <FormControl
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{
                                                                    mt: 2,
                                                                }}
                                                            >
                                                                <Autocomplete
                                                                    options={
                                                                        teamResponse
                                                                            ?.data
                                                                            ?.data ||
                                                                        []
                                                                    }
                                                                    value={
                                                                        teams
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option?.name
                                                                    }
                                                                    isOptionEqualToValue={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option.id ===
                                                                        value.id
                                                                    }
                                                                    multiple
                                                                    onChange={(
                                                                        event,
                                                                        newValue
                                                                    ) => {
                                                                        setTeams(
                                                                            newValue
                                                                        );
                                                                    }}
                                                                    id="categories-id-demo"
                                                                    disableCloseOnSelect
                                                                    renderOption={(
                                                                        props,
                                                                        option,
                                                                        {
                                                                            selected,
                                                                        }
                                                                    ) => (
                                                                        <li
                                                                            {...props}
                                                                            key={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    icon
                                                                                }
                                                                                checkedIcon={
                                                                                    checkedIcon
                                                                                }
                                                                                style={{
                                                                                    marginRight: 8,
                                                                                }}
                                                                                checked={
                                                                                    selected
                                                                                }
                                                                            />
                                                                            {
                                                                                option?.name
                                                                            }
                                                                        </li>
                                                                    )}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Team Members *"
                                                                            placeholder="Select Team Member"
                                                                            helperText="List of team members who worked on this project"
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {/* equipments autocomplete section */}
                                                            <FormControl
                                                                variant="outlined"
                                                                fullWidth
                                                                sx={{
                                                                    mt: 2,
                                                                }}
                                                            >
                                                                <Autocomplete
                                                                    id="categories-id-demo"
                                                                    options={
                                                                        equipmentResponse
                                                                            ?.data
                                                                            ?.data ||
                                                                        []
                                                                    }
                                                                    value={
                                                                        equipments
                                                                    }
                                                                    getOptionLabel={(
                                                                        option
                                                                    ) =>
                                                                        option?.name
                                                                    }
                                                                    isOptionEqualToValue={(
                                                                        option,
                                                                        value
                                                                    ) =>
                                                                        option.id ===
                                                                        value.id
                                                                    }
                                                                    multiple
                                                                    onChange={(
                                                                        event,
                                                                        newValue
                                                                    ) => {
                                                                        setEquipments(
                                                                            newValue
                                                                        );
                                                                    }}
                                                                    disableCloseOnSelect
                                                                    renderOption={(
                                                                        props,
                                                                        option,
                                                                        {
                                                                            selected,
                                                                        }
                                                                    ) => (
                                                                        <li
                                                                            {...props}
                                                                            key={
                                                                                option.id
                                                                            }
                                                                        >
                                                                            <Checkbox
                                                                                icon={
                                                                                    icon
                                                                                }
                                                                                checkedIcon={
                                                                                    checkedIcon
                                                                                }
                                                                                style={{
                                                                                    marginRight: 8,
                                                                                }}
                                                                                checked={
                                                                                    selected
                                                                                }
                                                                            />
                                                                            {
                                                                                option?.name
                                                                            }
                                                                        </li>
                                                                    )}
                                                                    renderInput={(
                                                                        params
                                                                    ) => (
                                                                        <TextField
                                                                            {...params}
                                                                            label="Equipments *"
                                                                            placeholder="Select Equipment"
                                                                            helperText="equipments that has been used in this project"
                                                                        />
                                                                    )}
                                                                />
                                                            </FormControl>
                                                        </Box>

                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <FormControl
                                                                fullWidth
                                                            >
                                                                <InputLabel id="demo-simple-select-label">
                                                                    Customer *
                                                                </InputLabel>
                                                                <Select
                                                                    required
                                                                    labelId="demo-simple-select-label"
                                                                    id="demo-simple-select"
                                                                    value={
                                                                        projects.customer
                                                                    }
                                                                    label="Select Customer"
                                                                    onChange={
                                                                        handleInputChange
                                                                    }
                                                                    name="customer"
                                                                >
                                                                    {customerResponse?.data?.data.map(
                                                                        (
                                                                            customer,
                                                                            index
                                                                        ) => (
                                                                            <MenuItem
                                                                                key={
                                                                                    customer.id
                                                                                }
                                                                                value={
                                                                                    customer.id
                                                                                }
                                                                            >
                                                                                {
                                                                                    customer.name
                                                                                }
                                                                            </MenuItem>
                                                                        )
                                                                    )}
                                                                </Select>
                                                            </FormControl>
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <LocalizationProvider
                                                                dateAdapter={
                                                                    AdapterDayjs
                                                                }
                                                            >
                                                                <LocalizationProvider
                                                                    dateAdapter={
                                                                        AdapterDayjs
                                                                    }
                                                                >
                                                                    <Box
                                                                        sx={{
                                                                            display:
                                                                                "flex",
                                                                            gap: 2,
                                                                        }}
                                                                    >
                                                                        <DatePicker
                                                                            label="Project Start Date"
                                                                            value={
                                                                                projects.start_date
                                                                            }
                                                                            onChange={(
                                                                                newValue
                                                                            ) =>
                                                                                setProjects(
                                                                                    (
                                                                                        prev
                                                                                    ) => ({
                                                                                        ...prev,
                                                                                        start_date:
                                                                                            newValue,
                                                                                    })
                                                                                )
                                                                            }
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                />
                                                                            )}
                                                                        />

                                                                        <DatePicker
                                                                            label="Project End Date"
                                                                            value={
                                                                                projects.end_date
                                                                            }
                                                                            onChange={(
                                                                                newValue
                                                                            ) =>
                                                                                setProjects(
                                                                                    (
                                                                                        prev
                                                                                    ) => ({
                                                                                        ...prev,
                                                                                        end_date:
                                                                                            newValue,
                                                                                    })
                                                                                )
                                                                            }
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextField
                                                                                    {...params}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </Box>
                                                                </LocalizationProvider>
                                                            </LocalizationProvider>
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="address_id"
                                                                label="Address"
                                                                name="address"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={
                                                                    projects.address ||
                                                                    ""
                                                                }
                                                                onChange={
                                                                    handleInputChange
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        {/* SEO Tab */}
                                        <TabPanel value={value} index={2}>
                                            <TextField
                                                id="meta_title"
                                                label="Meta Title"
                                                name="meta_title"
                                                type="text"
                                                value={projects.meta_title}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                sx={{ mb: 3 }}
                                            />
                                            <TextField
                                                id="meta_description"
                                                label="Meta Description"
                                                name="meta_description"
                                                type="text"
                                                value={
                                                    projects.meta_description
                                                }
                                                multiline
                                                rows={4}
                                                onChange={handleInputChange}
                                                variant="outlined"
                                                fullWidth
                                                required
                                                sx={{ mb: 3 }}
                                            />
                                        </TabPanel>

                                        {/* FAQ Tab */}
                                        <TabPanel value={value} index={1}>
                                            {faqInputs.map((input, index) => (
                                                <Box
                                                    key={index}
                                                    mb={2}
                                                    component="div"
                                                    border={1}
                                                    borderColor="ActiveBorder"
                                                    borderRadius={1}
                                                    marginBottom={2}
                                                    padding={2}
                                                >
                                                    <TextField
                                                        id={"question_" + index}
                                                        label="Question"
                                                        variant="outlined"
                                                        name="question"
                                                        fullWidth
                                                        value={input.question}
                                                        onChange={(event) =>
                                                            handleQuestionInputChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                        sx={{ mb: 3 }}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        label="Answer"
                                                        name="answer"
                                                        multiline
                                                        sx={{ mb: 3 }}
                                                        rows={4}
                                                        value={input.answer}
                                                        onChange={(event) =>
                                                            handleAnswerInputChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                    />

                                                    <Button
                                                        sx={{ mr: 4 }}
                                                        onClick={() =>
                                                            handleRemoveInput(
                                                                index
                                                            )
                                                        }
                                                        disabled={
                                                            faqInputs.length ===
                                                            1
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            ))}

                                            <div className="flex justify-end mt-4">
                                                <Button
                                                    onClick={handleAddInput}
                                                    variant="contained"
                                                    startIcon={
                                                        <AddOutlinedIcon />
                                                    }
                                                >
                                                    Add More
                                                </Button>
                                            </div>
                                        </TabPanel>
                                    </FormGroup>

                                    <Box
                                        component="div"
                                        sx={{
                                            borderTop: 1,
                                            borderColor: "divider",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            py: "20px",
                                        }}
                                    >
                                        <div style={{ display: "flex" }}>
                                            <FormControlLabel
                                                className="text-gray-900"
                                                control={
                                                    <Switch
                                                        checked={
                                                            !!projects.status
                                                        }
                                                        name="status"
                                                        value={
                                                            !!projects.status
                                                        }
                                                        onChange={(e) =>
                                                            setProjects(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    status: e
                                                                        .target
                                                                        .checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Status"
                                            />
                                            <FormControlLabel
                                                className="text-gray-900"
                                                control={
                                                    <Switch
                                                        checked={
                                                            !!projects.display_home
                                                        }
                                                        name="display_home"
                                                        value={
                                                            !!projects.display_home
                                                        }
                                                        onChange={(e) =>
                                                            setProjects(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    display_home:
                                                                        e.target
                                                                            .checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Display in Home"
                                            />
                                        </div>
                                        {loading ? (
                                            <Backdrop
                                                sx={{
                                                    color: "#fff",
                                                    zIndex: (theme) =>
                                                        theme.zIndex.drawer + 1,
                                                }}
                                                open={true}
                                            >
                                                <Typography sx={{ mr: 2 }}>
                                                    Saving...
                                                </Typography>
                                                <CircularProgress color="secondary" />
                                            </Backdrop>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                size="large"
                                                type="submit"
                                                endIcon={<SendIcon />}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default EditProject;
