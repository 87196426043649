import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import "./App.css";
import LogInPage from "./components/Layouts/LogInPage";
import AuthProvider from "./contexts/AuthContext";
import NotFound from "./pages/NotFound";
import BrandAdd from "./pages/brand/BrandAdd";
import BrandEdit from "./pages/brand/BrandEdit";
import BrandList from "./pages/brand/BrandList";
import CaseStudyAdd from "./pages/caseStudies/CaseStudyAdd";
import CaseStudyEdit from "./pages/caseStudies/CaseStudyEdit";
import CaseStudyList from "./pages/caseStudies/CaseStudyList";
import CityAdd from "./pages/city/CityAdd";
import CityEdit from "./pages/city/CityEdit";
import CityList from "./pages/city/CityList";
import CompletedProjectAdd from "./pages/completedProject/CompletedProjectAdd";
import CompletedProjectList from "./pages/completedProject/CompletedProjectList";
import EditProject from "./pages/completedProject/EditProject";
import EquipmentAdd from "./pages/equipment/EquipmentAdd";
import EquipmentEdit from "./pages/equipment/EquipmentEdit";
import EquipmentList from "./pages/equipment/EquipmentList";
import CategoryAdd from "./pages/equipmentCategory/CategoryAdd";
import CategoryEdit from "./pages/equipmentCategory/CategoryEdit";
import CategoryList from "./pages/equipmentCategory/CategoryList";
import TypeAdd from "./pages/equipmentType/TypeAdd";
import TypeEdit from "./pages/equipmentType/TypeEdit";
import TypeList from "./pages/equipmentType/TypeList";
import CustomerEdit from "./pages/ourCustomers/EditCustomer";
import OurCustomerAdd from "./pages/ourCustomers/OurCustomerAdd";
import OurCustomerList from "./pages/ourCustomers/OurCustomerList";
import PageAdd from "./pages/page/PageAdd";
import PageEdit from "./pages/page/PageEdit";
import PageList from "./pages/page/PageList";
import PostAdd from "./pages/post/PostAdd";
import PostEdit from "./pages/post/PostEdit";
import PostList from "./pages/post/PostList";
import EditPostCategory from "./pages/postCategory/EditPostCategory";
import PostCategoryAdd from "./pages/postCategory/PostCategoryAdd";
import PostCategoryList from "./pages/postCategory/PostCategoryList";
import EditPostTag from "./pages/postTag/EditPostTag";
import PostTagAdd from "./pages/postTag/PostTagAdd";
import PostTagList from "./pages/postTag/PostTagList";
import ServiceAdd from "./pages/services/ServiceAdd";
import ServiceEdit from "./pages/services/ServiceEdit";
import ServiceList from "./pages/services/ServiceList";
import TeamAdd from "./pages/team/TeamAdd";
import EditTeam from "./pages/team/TeamEdit";
import TeamList from "./pages/team/TeamList";

function App() {
    const queryClient = new QueryClient();

    // const OurCustomerList = React.lazy(
    //     () => import("./pages/ourCustomers/OurCustomerList")
    // );
    // const OurCustomerAdd = React.lazy(
    //     () => import("./pages/ourCustomers/OurCustomerAdd")
    // );

    return (
        <Routes>
            <Route
                path="/"
                element={
                    <QueryClientProvider client={queryClient}>
                        {/* <Home /> */}
                        <AuthProvider>
                            <EquipmentList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment"
                element={
                    <QueryClientProvider client={queryClient}>
                        <EquipmentList />
                        {/* <AuthProvider></AuthProvider> */}
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <EquipmentAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <EquipmentEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/equipment-categories"
                element={
                    <QueryClientProvider client={queryClient}>
                        <CategoryList />
                        {/* <AuthProvider></AuthProvider> */}
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment-category/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CategoryAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment-category/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CategoryEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/cities"
                element={
                    <QueryClientProvider client={queryClient}>
                        <CityList />
                        {/* <AuthProvider></AuthProvider> */}
                    </QueryClientProvider>
                }
            />
            <Route
                path="/city/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CityAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/city/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CityEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/our-customers"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <OurCustomerList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/our-customer/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <OurCustomerAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/our-customer/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CustomerEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/brands"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <BrandList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/brand/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <BrandAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/brand/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <BrandEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/Pages"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PageList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/page/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PageAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/page/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PageEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/completed-projects"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CompletedProjectList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/completed-project/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CompletedProjectAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/completed-project/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <EditProject />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/services"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <ServiceList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/service/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <ServiceAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/service/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <ServiceEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/case-studies"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CaseStudyList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/case-study/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CaseStudyAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/case-study/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <CaseStudyEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/teams"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <TeamList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/team/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <TeamAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/team/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <EditTeam />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/post-categories"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostCategoryList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post-category/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostCategoryAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post-category/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <EditPostCategory />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post-tags"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostTagList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post-tag/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostTagAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post-tag/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <EditPostTag />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/post/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/posts"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <PostList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route
                path="/equipment-type/add"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <TypeAdd />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment-type/edit/:id"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <TypeEdit />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />
            <Route
                path="/equipment-types"
                element={
                    <QueryClientProvider client={queryClient}>
                        <AuthProvider>
                            <TypeList />
                        </AuthProvider>
                    </QueryClientProvider>
                }
            />

            <Route path="/login" element={<LogInPage />} />
            <Route path="/not-found" element={<NotFound />} />
        </Routes>
    );
}

export default App;
