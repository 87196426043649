import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";

import TagInterface from "../../components/Interfaces/TagInterface";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useAxiosHook from "../../hooks/AxiosHook";

function EditPostTag() {
    const { id } = useParams();

    const [tag, setTagValue] = useState<TagInterface>({
        name: "",
        url: "",
        status: false,
    });

    useQuery(["get-post-tag"], () => service.get(`post-tag/${id}`), {
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
            setTagValue({
                name: res.data.data.name,
                url: res.data.data.url,
                status: res.data.data.status,
            });
        },
    });

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setTagValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleNameChange = (event) => {
        setTagValue((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setTagValue((prev) => ({ ...prev, url: slugValue }));
    };

    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });
    const [loading, setLoading] = useState(false);
    const { service } = useAxiosHook();
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("name", tag.name);
        formData.append("url", tag.url);
        formData.append("status", tag.status ? "1" : "0");
        setLoading(true);
        try {
            const response = await service.post(
                `post-tag/edit/${id}`,
                formData
            );
            setLoading(false);
            if (response.status === 200) {
                setAlertInfo({
                    show: true,
                    message: "Post Tag Updated",
                });
                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 3000);
            } else {
                setAlertInfo({
                    show: true,
                    message: "Something went wrong",
                });
                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 3000);
            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Edit Post Tag"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Post Tags"
                    buttonURL="post-tags"
                />
                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 w-full min-h-screen sm:overflow-auto p-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="post-tag-form"
                                id="post-tag-form"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TextField
                                        id="name"
                                        label="Name"
                                        name="name"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        sx={{ mb: 3 }}
                                        value={tag.name}
                                        onChange={handleNameChange}
                                    />
                                    <TextField
                                        id="url"
                                        label="URL"
                                        name="url"
                                        variant="outlined"
                                        fullWidth
                                        required
                                        sx={{ mb: 3 }}
                                        value={tag.url}
                                        onChange={handleChangeValue}
                                    />
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!tag.status}
                                                name="status"
                                                value={!!tag.status}
                                                onChange={(e) =>
                                                    setTagValue((prev) => ({
                                                        ...prev,
                                                        status: e.target
                                                            .checked,
                                                    }))
                                                }
                                            />
                                        }
                                        label="Status"
                                    />
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default EditPostTag;
