import useAxiosHook from "./AxiosHook";

function useBrandHook() {
    const { service } = useAxiosHook();

    async function getBrand() {
        return await service.get("/get-brand").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/brand?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deleteBrand(id: number) {
        return await service.delete("/brand/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getBrand,
        fetch,
        deleteBrand,
    };
}

export default useBrandHook;
