import useAxiosHook from "./AxiosHook";

function useTeamHook() {
    const { service } = useAxiosHook();

    async function getTeam() {
        return await service.get("/getTeam").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/team?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deleteTeam(id: number) {
        return await service.delete("/team/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getTeam,
        fetch,
        deleteTeam,
    };
}

export default useTeamHook;
