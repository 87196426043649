import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";
import { useNavigate } from "react-router";
import CaseStudyInterface from "../../components/Interfaces/CaseStudyInterface";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useAxiosHook from "../../hooks/AxiosHook";
import useCompletedProjectHook from "../../hooks/CompletedProjectHook";
import useOurCustomerHook from "../../hooks/OurCustomerHook";
import useServiceHook from "../../hooks/ServiceHook";
import useTeamHook from "../../hooks/TeamHook";
import { apiKey, init, plugins, toolbar } from "../../utils/tiny-info";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function CaseStudyAdd() {
    // service call
    const { service } = useAxiosHook();

    const { getCustomer } = useOurCustomerHook();
    const { getService } = useServiceHook();
    const { getTeam } = useTeamHook();

    const { data: services } = useQuery({
        queryKey: ["services"],
        queryFn: getService,
        refetchOnWindowFocus: false,
    });

    const { data: teamsData } = useQuery({
        queryKey: ["teams"],
        queryFn: getTeam,
        refetchOnWindowFocus: false,
    });

    // handling the value
    const [loading, setLoading] = useState(false);

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setCaseStudyValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // handling the name and automatic url slug generator
    const handleTitleChange = (event) => {
        setCaseStudyValue((prev) => ({ ...prev, title: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setCaseStudyValue((prev) => ({ ...prev, url: slugValue }));
    };

    // set the case study values
    const [caseStudyValue, setCaseStudyValue] = useState<CaseStudyInterface>({
        completed_project_id: 0,
        hero_image_id: "",
        our_customer_id: 0,
        title: "",
        url: "",
        short_description: "",
        client_quote: "",
        about: "",
        location: "",
        hero_image_alt: "",
        status: false,
        embedded_map: "",
        year: "",
        meta_title: "",
        meta_description: "",
    });

    const editorRef = useRef(null);

    // our customer query
    const { data: customers } = useQuery({
        queryKey: ["customers"],
        queryFn: getCustomer,
        refetchOnWindowFocus: false,
    });

    // Completed project query
    const { getProjects } = useCompletedProjectHook();

    const { data: projects } = useQuery({
        queryKey: ["projects"],
        queryFn: getProjects,
        refetchOnWindowFocus: false,
    });

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // for the faqs
    const handleRemoveField = (index: any) => {
        setFaqsFields(
            faqsFields.slice(0, index).concat(faqsFields.slice(index + 1))
        );
    };

    const [faqsFields, setFaqsFields] = React.useState([
        {
            question: "",
            answer: "",
        },
    ]);

    const handleAddField = () => {
        setFaqsFields([
            ...faqsFields,
            {
                question: "",
                answer: "",
            },
        ]);
    };

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    // Image
    const previewImageInputRef = useRef(null);
    const [heroImage, setHeroImage] = useState("");
    const [previewImage, setPreviewImage] = useState(null);

    const handleHeroImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }

            setHeroImage(event.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        setHeroImage("");
        // Reset the value of the input element
        previewImageInputRef.current.value = null;
    };

    // Our Customer Select
    const [customer, setCustomer] = React.useState("");
    const handleChangeSelect = (event: any) => {
        setCustomer(event.target.value);
    };

    const [completedProject, setCompletedProject] = React.useState("");
    const handleChangeProjectSelect = (event: any) => {
        setCompletedProject(event.target.value);
    };

    const [caseStudyAbout, setCaseStudyAbout] = React.useState("");
    const handleEditorChange = (content, editor) => {
        setCaseStudyAbout(content);
        console.log("Content:", content);
    };

    const navigate = useNavigate();

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (heroImage === "") {
            return alert("Hero image is required.");
        }

        if (caseStudyValue.meta_title === "") {
            return alert("SEO Meta title is required.");
        }

        if (caseStudyValue.meta_description === "") {
            return alert("SEO Meta description is required.");
        }

        const formData = new FormData();
        formData.append("our_customer_id", customer);
        formData.append("completed_project_id", completedProject);
        formData.append("hero_image_id", heroImage);
        formData.append("hero_image_alt", caseStudyValue.hero_image_alt);
        formData.append("title", caseStudyValue.title);
        formData.append("url", caseStudyValue.url);
        formData.append("short_description", caseStudyValue.short_description);
        formData.append("client_quote", caseStudyValue.client_quote);
        formData.append("about", caseStudyAbout);
        formData.append("status", caseStudyValue.status ? "1" : "0");
        formData.append("location", caseStudyValue.location);
        formData.append("embedded_map", caseStudyValue.embedded_map);
        formData.append("year", caseStudyValue.year);
        formData.append("meta_title", caseStudyValue.meta_title);
        formData.append("meta_description", caseStudyValue.meta_description);

        faqsFields.forEach((q, index) => {
            formData.append("question[]", q.question);
            formData.append("answer[]", q.answer);
        });

        teams.forEach((tag, index) => {
            formData.append("teams[]", tag.id);
        });

        ser.forEach((s, index) => {
            formData.append("services[]", s.id);
        });

        try {
            setLoading(true);
            const { data, status } = await service.post(
                "case-study",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            setLoading(false);
            if (status === 200) {
                setAlertInfo({
                    show: true,
                    message: data.message,
                });

                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                    navigate("/case-studies");
                }, 1500);
            }
        } catch (error) {
            setLoading(false);
            alert(error);
            console.log(error);
            setAlertInfo({
                show: true,
                message: "Opps! something wrong.",
            });

            setTimeout(() => {
                setAlertInfo({
                    show: false,
                    message: "",
                });
            }, 1500);
        }
    };

    const [ser, setSer] = React.useState<any[]>([]);
    const [teams, setTeams] = React.useState<any[]>([]);

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Add New Case Studies"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Case Studies"
                    buttonURL="case-studies"
                />

                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500  w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="Faqs" {...a11yProps(1)} />
                                    <Tab label="SEO" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="our-customer-create-form"
                                id="our-customer-create-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                className="bg-white p-4"
                                                xs={12}
                                                md={8}
                                            >
                                                <TextField
                                                    id="title"
                                                    label="Title"
                                                    name="title"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={caseStudyValue.title}
                                                    onChange={handleTitleChange}
                                                />
                                                <TextField
                                                    id="url"
                                                    label="URL"
                                                    name="url"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={caseStudyValue.url}
                                                    onChange={handleChangeValue}
                                                />

                                                <TextField
                                                    id="client_quote"
                                                    label="Client Quote"
                                                    name="client_quote"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={
                                                        caseStudyValue.client_quote
                                                    }
                                                    onChange={handleChangeValue}
                                                />

                                                <TextField
                                                    id="short_description"
                                                    label="Short Description"
                                                    name="short_description"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={
                                                        caseStudyValue.short_description
                                                    }
                                                    onChange={handleChangeValue}
                                                />

                                                <label className="mb-2 block">
                                                    About
                                                </label>
                                                <Box className="mb-4">
                                                    <Editor
                                                        apiKey={apiKey}
                                                        onInit={(evt, editor) =>
                                                            (editorRef.current =
                                                                editor)
                                                        }
                                                        value={caseStudyAbout}
                                                        onEditorChange={
                                                            handleEditorChange
                                                        }
                                                        init={{ init }}
                                                        toolbar={toolbar}
                                                        plugins={plugins}
                                                    />
                                                </Box>

                                                <TextField
                                                    id="embedded_map"
                                                    label="Embedded Map"
                                                    name="embedded_map"
                                                    variant="outlined"
                                                    fullWidth
                                                    multiline
                                                    rows={4}
                                                    sx={{ mb: 3 }}
                                                    value={
                                                        caseStudyValue.embedded_map
                                                    }
                                                    onChange={handleChangeValue}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3 shadow mb-4"
                                                    >
                                                        {previewImage && (
                                                            <Box
                                                                component="div"
                                                                className="w-full border p-2 mb-4 relative"
                                                            >
                                                                <img
                                                                    className="h-80 w-full object-cover rounded-md"
                                                                    src={
                                                                        previewImage
                                                                    }
                                                                    alt="Preview"
                                                                />
                                                                <TextField
                                                                    sx={{
                                                                        mt: 2,
                                                                    }}
                                                                    fullWidth
                                                                    label="Image Alt Text"
                                                                    variant="outlined"
                                                                    required
                                                                    id="hero_image_alt"
                                                                    name="hero_image_alt"
                                                                    value={
                                                                        caseStudyValue.hero_image_alt
                                                                    }
                                                                    onChange={
                                                                        handleChangeValue
                                                                    }
                                                                    placeholder="Write the Alt text for this image"
                                                                    helperText="This field is required for the SEO purpose"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={
                                                                        handleRemovePreviewImage
                                                                    }
                                                                    className="absolute border border-red-500 rounded text-red-500 right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </Box>
                                                        )}

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                            startIcon={
                                                                <FileUploadIcon />
                                                            }
                                                        >
                                                            Hero Image
                                                            (required)
                                                            <input
                                                                type="file"
                                                                hidden
                                                                ref={
                                                                    previewImageInputRef
                                                                }
                                                                accept="image/jpeg,image/*,image/png"
                                                                onChange={
                                                                    handleHeroImage
                                                                }
                                                            />
                                                        </Button>
                                                        <p
                                                            className="helper-text"
                                                            id="heroImage-helper-text"
                                                        >
                                                            Image upload maximum
                                                            size 5MB
                                                        </p>
                                                        <span className="mt-1 block font-medium">
                                                            Size (1200px X
                                                            1400px)
                                                        </span>
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Services
                                                        </label>

                                                        <Autocomplete
                                                            multiple
                                                            value={ser}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setSer(
                                                                    newValue
                                                                );
                                                            }}
                                                            id="service-id-demo"
                                                            options={
                                                                services?.data ||
                                                                []
                                                            }
                                                            disableCloseOnSelect
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Services"
                                                                    placeholder="Select Services"
                                                                />
                                                            )}
                                                        />
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Teams
                                                        </label>

                                                        <Autocomplete
                                                            multiple
                                                            value={teams}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setTeams(
                                                                    newValue
                                                                );
                                                            }}
                                                            id="teams-id-demo"
                                                            options={
                                                                teamsData?.data ||
                                                                []
                                                            }
                                                            disableCloseOnSelect
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Teams"
                                                                    placeholder="Select Teams"
                                                                />
                                                            )}
                                                        />
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Completed Project *
                                                        </label>

                                                        <Select
                                                            required
                                                            value={
                                                                completedProject
                                                            }
                                                            onChange={
                                                                handleChangeProjectSelect
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            label="Completed Project"
                                                        >
                                                            {projects?.data.map(
                                                                (data: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            data.id
                                                                        }
                                                                        value={
                                                                            data.id
                                                                        }
                                                                    >
                                                                        {
                                                                            data.title
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Our Customer *
                                                        </label>

                                                        <Select
                                                            required
                                                            value={customer}
                                                            onChange={
                                                                handleChangeSelect
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            label="Our Customer"
                                                        >
                                                            {customers?.data.map(
                                                                (data: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            data.id
                                                                        }
                                                                        value={
                                                                            data.id
                                                                        }
                                                                    >
                                                                        {
                                                                            data.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Location
                                                        </label>
                                                        <TextField
                                                            id="location"
                                                            label="Location"
                                                            name="location"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={
                                                                caseStudyValue.location
                                                            }
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                        />
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Year
                                                        </label>
                                                        <TextField
                                                            id="year"
                                                            label="Year"
                                                            name="year"
                                                            type="number"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={
                                                                caseStudyValue.year
                                                            }
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                            inputProps={{
                                                                maxLength: 4,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {faqsFields.map((field, index) => (
                                            <Box
                                                component="div"
                                                border={1}
                                                marginBottom={2}
                                                key={index}
                                                padding={2}
                                            >
                                                <TextField
                                                    id={"question_" + index}
                                                    label="Question"
                                                    name="question[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={field.question}
                                                    sx={{ mb: 3 }}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              question:
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                <TextField
                                                    id={"answer_" + index}
                                                    label="Answer"
                                                    name="answer[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    value={field.answer}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              answer: e
                                                                                  .target
                                                                                  .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                {index !== 0 && (
                                                    <Button
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                index
                                                            )
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </Box>
                                        ))}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button
                                                onClick={handleAddField}
                                                variant="contained"
                                                startIcon={<AddOutlinedIcon />}
                                            >
                                                Add More
                                            </Button>
                                        </div>
                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={caseStudyValue.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={4}
                                            sx={{ mb: 3 }}
                                            value={
                                                caseStudyValue.meta_description
                                            }
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <Box component="div">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        caseStudyValue.status
                                                    }
                                                    name="status"
                                                    value={
                                                        caseStudyValue.status
                                                    }
                                                    onChange={(e) =>
                                                        setCaseStudyValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                status: e.target
                                                                    .checked
                                                                    ? true
                                                                    : false,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Status"
                                        />
                                    </Box>
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default CaseStudyAdd;
