import useAxiosHook from "./AxiosHook";

function useEquipmentCategoryHook() {
    const { service } = useAxiosHook();

    async function fetch(page: number) {
        return await service
            .get("/equipment-category?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteEquipmentCategory(id: number) {
        return await service
            .delete("/equipment-category/" + id)
            .then((res: any) => {
                return res.data;
            });
    }

    async function getEquipmentCategories() {
        return await service
            .get("/get-equipment-categories")
            .then((res: any) => {
                return res.data;
            });
    }

    return {
        fetch,
        deleteEquipmentCategory,
        getEquipmentCategories,
    };
}

export default useEquipmentCategoryHook;
