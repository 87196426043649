import { Alert, Snackbar } from "@mui/material";

interface snacBarProps {
    message: String;
    duration: number;
    snackBarOpen?: boolean;
}

export default function Toaster(props: snacBarProps) {
    return (
        <Snackbar open={props.snackBarOpen} autoHideDuration={props.duration}>
            <Alert severity="success" sx={{ width: "100%" }}>
                {props.message}
            </Alert>
        </Snackbar>
    );
}
