import useAxiosHook from "./AxiosHook";

function useEquipmentTypeHook() {
    const { service } = useAxiosHook();

    async function fetch(page: number) {
        return await service
            .get("/equipment-category?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteEquipmentCategory(id: number) {
        return await service
            .delete("/equipment-category/" + id)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteEquipmentType(id: number) {
        return await service
            .delete("/equipment-type/" + id)
            .then((res: any) => {
                return res.data;
            });
    }

    async function getEquipmentType() {
        return await service.get("/get-equipment-types").then((res: any) => {
            return res.data;
        });
    }

    async function fetchType(page: number) {
        return await service
            .get("/equipment-type?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    return {
        fetch,
        deleteEquipmentCategory,
        getEquipmentType,
        fetchType,
        deleteEquipmentType,
    };
}

export default useEquipmentTypeHook;
