import useAxiosHook from "./AxiosHook";

function usePageHook() {
    const { service } = useAxiosHook();

    async function getPage() {
        return await service.get("/page").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/page?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deletePage(id: number) {
        return await service.delete("/page/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getPage,
        fetch,
        deletePage,
    };
}

export default usePageHook;
