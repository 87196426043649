import useAxiosHook from "./AxiosHook";

function useEquipmentHook() {
    const { service } = useAxiosHook();

    async function fetch(page: number) {
        return await service.get("/equipment?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deleteEquipment(id: number) {
        return await service.delete("/equipment/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        fetch,
        deleteEquipment,
    };
}

export default useEquipmentHook;
