import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Editor } from "@tinymce/tinymce-react";
import React, { useRef, useState } from "react";
import { useNavigate, useParams } from "react-router";
import CityInterface from "../../components/Interfaces/CityInterface";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useAxiosHook from "../../hooks/AxiosHook";
import useServiceHook from "../../hooks/ServiceHook";
import { apiKey, init, plugins, toolbar } from "../../utils/tiny-info";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function CityEdit() {
    const { id } = useParams();

    // service call
    const { service } = useAxiosHook();
    const { getService } = useServiceHook();

    const { data: getServices } = useQuery({
        queryKey: ["services"],
        queryFn: getService,
        refetchOnWindowFocus: false,
    });

    const { data: stateResponse } = useQuery(
        ["get-all-states"],
        () => service.get("get-state"),
        {
            refetchOnWindowFocus: false,
        }
    );

    // Service set
    const [services, setServices] = React.useState<any[]>([]);

    // handling the value
    const [loadingBar, setLoadingBar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setCityValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const { isLoading } = useQuery(
        ["single-city-edit"],
        () => service.get(`city/${id}`),
        {
            onSuccess: (res) => {
                let response = res.data.data;

                setCityValue({
                    name: response.name,
                    url: response.url,
                    state_id: response.state_id,
                    shortDescription: response.short_description,
                    description: response.description,
                    content: response.content,
                    status: response.status,
                    meta_title: response.meta_title,
                    meta_description: response.meta_description,
                });

                setPageContent(response.description);
                setPageDescription(response.content);

                const services = response.services;
                const transformedService = services.map((service: any) => {
                    return {
                        id: service.id,
                        name: service.name,
                    };
                });
                services.length >= 1 && setServices(transformedService);

                response.faqs.length >= 1 && setFaqsFields(response.faqs);
                setLoadingBar(false);
            },

            onError: (res) => {
                alert(res);
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        }
    );

    // set the case study values
    const [cityValue, setCityValue] = useState<CityInterface>({
        name: "",
        url: "",
        shortDescription: "",
        state_id: "",
        description: "",
        content: "",
        status: false,
        meta_title: "",
        meta_description: "",
    });

    const editorRef = useRef(null);

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // for the faqs
    const handleRemoveField = (index: any) => {
        setFaqsFields(
            faqsFields.slice(0, index).concat(faqsFields.slice(index + 1))
        );
    };

    const [faqsFields, setFaqsFields] = React.useState([
        {
            question: "",
            answer: "",
        },
    ]);

    const handleAddField = () => {
        setFaqsFields([
            ...faqsFields,
            {
                question: "",
                answer: "",
            },
        ]);
    };

    const [pageContent, setPageContent] = React.useState("");
    const handleEditorChange = (content, editor) => {
        setPageContent(content);
    };

    const [pageDescription, setPageDescription] = React.useState("");
    const handleEditorDescriptionChange = (content, editor) => {
        setPageDescription(content);
    };

    const navigate = useNavigate();

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        setLoadingBar(true);

        if (cityValue.description === "") {
            return alert("Hero Content is required.");
        }

        if (services.length < 1) {
            return alert("Please select the services");
        }

        if (cityValue.meta_title === "") {
            return alert("SEO Meta title is required.");
        }

        if (cityValue.meta_description === "") {
            return alert("SEO Meta description is required.");
        }

        const formData = new FormData();
        formData.append("name", cityValue.name);
        formData.append("url", cityValue.url);
        formData.append("short_description", cityValue.shortDescription);
        formData.append("description", pageContent);
        formData.append("content", pageDescription);
        formData.append("status", cityValue.status ? "1" : "0");
        formData.append("state_id", cityValue.state_id);
        formData.append("meta_title", cityValue.meta_title);
        formData.append("meta_description", cityValue.meta_description);

        faqsFields.forEach((q, index) => {
            formData.append("question[]", q.question);
            formData.append("answer[]", q.answer);
        });

        services.forEach((s, index) => {
            formData.append("services[]", s.id);
        });

        try {
            setLoading(true);
            const response = await service.post("city/edit/" + id, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });

            if (response.status === 201) {
                setAlertInfo({
                    show: true,
                    message: response.data.message,
                });

                setTimeout(() => {
                    setLoadingBar(false);
                    setAlertInfo({
                        show: false,
                        message: "",
                    });

                    navigate("/cities");
                }, 1500);
                setLoading(false);
                setLoadingBar(false);
                return true;
            }
            setAlertInfo({
                show: true,
                message: response.data.message,
            });

            setTimeout(() => {
                setLoadingBar(false);
                setAlertInfo({
                    show: false,
                    message: "",
                });
            }, 1000);

            setLoading(false);
            setLoadingBar(false);
        } catch (error) {
            alert(error);
            setLoading(false);
            console.log(error);
            setAlertInfo({
                show: true,
                message: "Opps! something wrong.",
            });
            setTimeout(() => {
                setLoadingBar(false);
                setAlertInfo({
                    show: false,
                    message: "",
                });

                navigate("/cities");
            }, 1500);
        }
    };

    const handleNameChange = (event) => {
        setCityValue((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setCityValue((prev) => ({ ...prev, url: slugValue }));
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            {loadingBar && (
                <div style={{ width: "100%", position: "absolute" }}>
                    <LinearProgress />
                </div>
            )}

            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="City Edit"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Cities"
                    buttonURL="cities"
                />

                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500  w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="Faqs" {...a11yProps(1)} />
                                    <Tab label="SEO" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="our-customer-create-form"
                                id="our-customer-create-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                className="bg-white p-4"
                                                xs={12}
                                                md={8}
                                            >
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <TextField
                                                        id="name"
                                                        label="Name"
                                                        name="name"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                        value={cityValue.name}
                                                        onChange={
                                                            handleNameChange
                                                        }
                                                    />

                                                    <TextField
                                                        id="url"
                                                        label="URL"
                                                        name="url"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                        value={cityValue.url}
                                                        onChange={
                                                            handleChangeValue
                                                        }
                                                    />
                                                </Box>

                                                <TextField
                                                    id="shortDescription"
                                                    label="Short Description"
                                                    name="shortDescription"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    rows={4}
                                                    multiline
                                                    sx={{ mb: 3 }}
                                                    value={
                                                        cityValue.shortDescription
                                                    }
                                                    onChange={handleChangeValue}
                                                />

                                                <label className="mb-2 block">
                                                    Hero Content *
                                                </label>

                                                <Box className="mb-4">
                                                    <Editor
                                                        apiKey={apiKey}
                                                        onInit={(evt, editor) =>
                                                            (editorRef.current =
                                                                editor)
                                                        }
                                                        value={pageContent}
                                                        onEditorChange={
                                                            handleEditorChange
                                                        }
                                                        init={{ init }}
                                                        toolbar={toolbar}
                                                        plugins={plugins}
                                                    />
                                                </Box>
                                                <label className="mb-2 block">
                                                    Content *
                                                </label>

                                                <Box className="mb-4">
                                                    <Editor
                                                        apiKey={apiKey}
                                                        onInit={(evt, editor) =>
                                                            (editorRef.current =
                                                                editor)
                                                        }
                                                        value={pageDescription}
                                                        onEditorChange={
                                                            handleEditorDescriptionChange
                                                        }
                                                        init={{ init }}
                                                        toolbar={toolbar}
                                                        plugins={plugins}
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            State *
                                                        </label>
                                                        <Select
                                                            required
                                                            className="w-full"
                                                            labelId="state-simple-select-label"
                                                            id="state-simple-select"
                                                            value={
                                                                cityValue.state_id ||
                                                                ""
                                                            }
                                                            label="Select State *"
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                            name="state_id"
                                                        >
                                                            {stateResponse?.data?.data.map(
                                                                (
                                                                    state,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            state.id
                                                                        }
                                                                        value={
                                                                            state.id
                                                                        }
                                                                    >
                                                                        {
                                                                            state.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Services*
                                                        </label>

                                                        <Autocomplete
                                                            multiple
                                                            value={services}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setServices(
                                                                    newValue
                                                                );
                                                            }}
                                                            id="service-id-demo"
                                                            options={
                                                                getServices?.data ||
                                                                []
                                                            }
                                                            disableCloseOnSelect
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Services*"
                                                                    placeholder="Select Services"
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {faqsFields.map((field, index) => (
                                            <Box
                                                component="div"
                                                border={1}
                                                marginBottom={2}
                                                key={index}
                                                padding={2}
                                            >
                                                <TextField
                                                    id={"question_" + index}
                                                    label="Question"
                                                    name="question[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={field.question}
                                                    sx={{ mb: 3 }}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              question:
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                <TextField
                                                    id={"answer_" + index}
                                                    label="Answer"
                                                    name="answer[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    value={field.answer}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              answer: e
                                                                                  .target
                                                                                  .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                {index !== 0 && (
                                                    <Button
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                index
                                                            )
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </Box>
                                        ))}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button
                                                onClick={handleAddField}
                                                variant="contained"
                                                startIcon={<AddOutlinedIcon />}
                                            >
                                                Add More
                                            </Button>
                                        </div>
                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={cityValue.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={4}
                                            sx={{ mb: 3 }}
                                            value={cityValue.meta_description}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <Box component="div">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        cityValue.status
                                                            ? true
                                                            : false
                                                    }
                                                    name="status"
                                                    value={!!cityValue.status}
                                                    onChange={(e) =>
                                                        setCityValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                status: e.target
                                                                    .checked
                                                                    ? true
                                                                    : false,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Status"
                                        />
                                    </Box>
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",

                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>

                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default CityEdit;
