import useAxiosHook from "./AxiosHook";

function useCaseStudyHook() {
    const { service } = useAxiosHook();

    async function fetch(page: number) {
        return await service
            .get("/case-study?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteCaseStudy(id: number) {
        return await service.delete("/case-study/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        fetch,
        deleteCaseStudy,
    };
}

export default useCaseStudyHook;
