import useAxiosHook from "./AxiosHook";

function usePostHook() {
    const { service } = useAxiosHook();

    async function getCategories() {
        return await service.get("/getCategories").then((res: any) => {
            return res.data;
        });
    }

    async function getAuthor() {
        return await service.get("/getTeam").then((res: any) => {
            return res.data;
        });
    }

    async function getTags() {
        return await service.get("/getTags").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/post?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deletePost(id: number) {
        return await service.delete("/post/" + id).then((res: any) => {
            return res.data;
        });
    }

    async function getPostEditData(id: string) {
        return await service.get("/post/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getCategories,
        getAuthor,
        getTags,
        deletePost,
        fetch,
        getPostEditData,
    };
}

export default usePostHook;
