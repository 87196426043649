import { Snackbar } from "@mui/material";
import { useState } from "react";

function SnackBarAlert({ show, message }) {
    const [state, setState] = useState<any>({
        vertical: "top",
        horizontal: "center",
    });

    const { vertical, horizontal } = state;

    return (
        <div>
            <Snackbar
                anchorOrigin={{ vertical, horizontal }}
                open={show}
                message={message}
                key={vertical + horizontal}
            />
        </div>
    );
}

export default SnackBarAlert;
