import TextField from "@mui/material/TextField";
import { MobileDatePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import * as React from "react";

interface DatePickerProps {
    label: string;
    value: Date | null;
    onChange: (date: Date | null) => void;
}

const DatePickerComponent: React.FC<DatePickerProps> = ({
    label,
    value,
    onChange,
}) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <MobileDatePicker
                label={label}
                value={value}
                onChange={onChange}
                renderInput={(params: any) => (
                    <TextField fullWidth {...params} />
                )}
            />
        </LocalizationProvider>
    );
};

export default DatePickerComponent;
