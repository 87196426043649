import { Editor } from "@tinymce/tinymce-react";
import { useRef } from "react";
import { apiKey, init, plugins, toolbar } from "../../utils/tiny-info";

function TinyEditor({ contentData, handleEditorChange }) {
    const editorRef = useRef(null);
    const log = () => {
        if (editorRef.current) {
            console.log(editorRef.current.getContent());
        }
    };

    return (
        <div>
            <Editor
                apiKey={apiKey}
                onInit={(evt, editor) => (editorRef.current = editor)}
                value={contentData}
                onEditorChange={handleEditorChange}
                init={{ init }}
                toolbar={toolbar}
                plugins={plugins}
            />
        </div>
    );
}

export default TinyEditor;
