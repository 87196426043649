import useAxiosHook from "./AxiosHook";

function useCityHook() {
    const { service } = useAxiosHook();

    async function getCities() {
        return await service.get("/cities").then((res: any) => {
            return res.data;
        });
    }

    async function getStates() {
        return await service.get("/get-state").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/city?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deleteCity(id: number) {
        return await service.delete("/city/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getCities,
        fetch,
        deleteCity,
        getStates,
    };
}

export default useCityHook;
