import AcUnitIcon from "@mui/icons-material/AcUnit";
import AssessmentIcon from "@mui/icons-material/Assessment";
import CameraOutdoorIcon from "@mui/icons-material/CameraOutdoor";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import DesignServicesIcon from "@mui/icons-material/DesignServices";
import DynamicFeedIcon from "@mui/icons-material/DynamicFeed";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import GroupIcon from "@mui/icons-material/Group";
import Groups2Icon from "@mui/icons-material/Groups2";
import InboxIcon from "@mui/icons-material/Inbox";
import MiscellaneousServicesIcon from "@mui/icons-material/MiscellaneousServices";
import NoteAltIcon from "@mui/icons-material/NoteAlt";
import PostAddIcon from "@mui/icons-material/PostAdd";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import StyleIcon from "@mui/icons-material/Style";
import {
    Collapse,
    Drawer,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import React, { useContext } from "react";
import { Link } from "react-router-dom";
// import { Context } from "../../contexts/Store";
import { AuthContext } from "../../contexts/AuthContext";
import { AuthContextType } from "../../types/AuthContext.type";
import AwingLogo from "../Images/awing_logo.png";

function Sidebar() {
    const [open, setOpen] = React.useState<boolean>(true);
    const { user } = useContext<AuthContextType>(AuthContext);

    const [dropDownOpen, setDropDownOpen] = React.useState(false);

    const handleClick = () => {
        setDropDownOpen(!dropDownOpen);
    };

    const [blogDropDownOpen, setBlogDropDownOpen] = React.useState(false);

    const handleClickBlog = () => {
        setBlogDropDownOpen(!blogDropDownOpen);
    };

    // const handleDrawerOpen = () => {
    //     setOpen(true);
    // };

    // const handleDrawerClose = () => {
    //     setOpen(false);
    // };

    const menu: any = document.querySelector(".sideDrawer");

    const drawerOpen = (e: any) => {
        setOpen(true);
        menu?.classList.add("opened");
    };

    const drawerClose = (e: any) => {
        setOpen(false);
        menu?.classList.remove("opened");
    };

    return (
        <>
            <Drawer
                onMouseEnter={drawerOpen}
                onMouseLeave={drawerClose}
                className="sideDrawer"
                variant="permanent"
                open={open}
            >
                <List>
                    <div className="shadow-sm logo-wrapper flex items-center">
                        <img
                            className={open ? " w-8 h-12" : "w-8 h-12"}
                            src={AwingLogo}
                            alt="logo"
                        />
                    </div>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/services"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <MiscellaneousServicesIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Services"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItemButton onClick={handleClick}>
                        <ListItemIcon>
                            <CameraOutdoorIcon />
                        </ListItemIcon>
                        <ListItemText primary="Equipment" />
                        {dropDownOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse in={dropDownOpen} timeout="auto" unmountOnExit>
                        <List disablePadding to="/equipment" component={Link}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <CameraOutdoorIcon />
                                </ListItemIcon>
                                <ListItemText primary="Equipment List" />
                            </ListItemButton>
                        </List>
                        <List
                            disablePadding
                            to="/equipment-types"
                            component={Link}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <AssessmentIcon />
                                </ListItemIcon>
                                <ListItemText primary="Types" />
                            </ListItemButton>
                        </List>
                        <List
                            disablePadding
                            to="/equipment-categories"
                            component={Link}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <CollectionsBookmarkIcon />
                                </ListItemIcon>
                                <ListItemText primary="Categories" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/brands"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <AcUnitIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Brand"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/our-customers"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <GroupIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Our Customers"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/completed-projects"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <DesignServicesIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Projects"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/case-studies"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <NoteAltIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Case Studies"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/pages"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <QuestionAnswerIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Page"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/teams"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <Groups2Icon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Team"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    {/* <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/blocks"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <GridViewIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Block"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem> */}

                    {/* <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/posts"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <PostAddIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Posts"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem> 
                    
                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/post-categories"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <DynamicFeedIcon />
                            </ListItemIcon>
                            <ListItemText
                                primary={"Post Categories"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>*/}

                    <ListItem
                        disablePadding
                        sx={{ display: "block" }}
                        to="/cities"
                        component={Link}
                    >
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? "initial" : "center",
                                px: 2.5,
                            }}
                        >
                            <ListItemIcon
                                sx={{
                                    minWidth: 0,
                                    mr: open ? 3 : "auto",
                                    justifyContent: "center",
                                }}
                            >
                                <StyleIcon />
                            </ListItemIcon>

                            <ListItemText
                                primary={"Cities"}
                                sx={{ opacity: open ? 1 : 0 }}
                            />
                        </ListItemButton>
                    </ListItem>

                    <ListItemButton onClick={handleClickBlog}>
                        <ListItemIcon>
                            <InboxIcon />
                        </ListItemIcon>
                        <ListItemText primary="Blog" />
                        {blogDropDownOpen ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                    <Collapse
                        in={blogDropDownOpen}
                        timeout="auto"
                        unmountOnExit
                    >
                        <List disablePadding to="/posts" component={Link}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <PostAddIcon />
                                </ListItemIcon>
                                <ListItemText primary="Posts" />
                            </ListItemButton>
                        </List>
                        <List disablePadding to="/post-tags" component={Link}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <StyleIcon />
                                </ListItemIcon>
                                <ListItemText primary="Post Tags" />
                            </ListItemButton>
                        </List>
                        <List
                            disablePadding
                            to="/post-categories"
                            component={Link}
                        >
                            <ListItemButton>
                                <ListItemIcon>
                                    <DynamicFeedIcon />
                                </ListItemIcon>
                                <ListItemText primary="Post Categories" />
                            </ListItemButton>
                        </List>
                    </Collapse>

                    {user && user.role === "SuperAdmin" && (
                        <ListItem
                            disablePadding
                            sx={{ display: "block" }}
                            to="/users"
                            component={Link}
                        >
                            <ListItemButton
                                sx={{
                                    minHeight: 48,
                                    justifyContent: open ? "initial" : "center",
                                    px: 2.5,
                                }}
                            >
                                <ListItemIcon
                                    sx={{
                                        minWidth: 0,
                                        mr: open ? 3 : "auto",
                                        justifyContent: "center",
                                    }}
                                >
                                    <GroupIcon />
                                </ListItemIcon>
                                <ListItemText
                                    primary={"Users"}
                                    sx={{ opacity: open ? 1 : 0 }}
                                />
                            </ListItemButton>
                        </ListItem>
                    )}
                </List>
            </Drawer>
            {/*
        <div className="">
          {/* <div className="logo pt-3 ">
              <img src="https://nocookie.balingwiredirect.com/img/bwd-logo.jpg" />
            </div> 
            </div >
          */}
        </>
    );
}

export default Sidebar;
