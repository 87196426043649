import useAxiosHook from "./AxiosHook";

function usePostTagHook() {
    const { service } = useAxiosHook();

    async function getTags() {
        return await service.get("/getTags").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/post-tag?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deleteTag(id: number) {
        return await service.delete("/post-tag/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getTags,
        fetch,
        deleteTag,
    };
}

export default usePostTagHook;
