import { useQuery } from "@tanstack/react-query";
import { createContext, useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import useAxiosHook from "../hooks/AxiosHook";
import useUserHook from "../hooks/UserHook";
import { Context } from "./Store";

export const AuthContext: any = createContext("auth");

const AuthProvider = ({ children }: any) => {
    const [userClient] = useState(localStorage.getItem("awing-admin-client"));
    // const [user, setUser] = useState<UserType>();
    const { setState, state } = useContext(Context);
    const { service } = useAxiosHook();
    const { getLoggedInUser } = useUserHook();

    const { data: user } = useQuery(["login"], () => {
        return getLoggedInUser();
    });

    return userClient ? (
        <AuthContext.Provider value={{ user }}>{children}</AuthContext.Provider>
    ) : (
        <Navigate to="/login" />
    );
};

export default AuthProvider;
