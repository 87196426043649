import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import EquipmentCategoryInterface from "../../components/Interfaces/EquipmentCategoryInterface";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useAxiosHook from "../../hooks/AxiosHook";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function CategoryAdd() {
    // service call
    const { service } = useAxiosHook();

    // handling the value
    const [loadingBar, setLoadingBar] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const { data: categoryResponse } = useQuery(
        ["get-all-types"],
        () => service.get("get-equipment-types"),
        {
            refetchOnWindowFocus: false,
        }
    );

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setCategoryValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // set the case study values
    const [categoryValue, setCategoryValue] =
        useState<EquipmentCategoryInterface>({
            name: "",
            url: "",
            about: "",
            type_id: "",
            status: false,
            meta_title: "",
            meta_description: "",
        });

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // for the faqs
    // const handleRemoveField = (index: any) => {
    //     setFaqsFields(
    //         faqsFields.slice(0, index).concat(faqsFields.slice(index + 1))
    //     );
    // };

    // const [faqsFields, setFaqsFields] = React.useState([
    //     {
    //         question: "",
    //         answer: "",
    //     },
    // ]);

    // const handleAddField = () => {
    //     setFaqsFields([
    //         ...faqsFields,
    //         {
    //             question: "",
    //             answer: "",
    //         },
    //     ]);
    // };

    // handling the name and automatic url slug generator
    const handleNameChange = (event) => {
        setCategoryValue((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setCategoryValue((prev) => ({ ...prev, url: slugValue }));
    };

    const navigate = useNavigate();

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (categoryValue.meta_title === "") {
            return alert("SEO Meta title is required.");
        }

        if (categoryValue.meta_description === "") {
            return alert("SEO Meta description is required.");
        }

        setLoadingBar(true);

        const formData = new FormData();
        formData.append("name", categoryValue.name);
        formData.append("url", categoryValue.url);
        formData.append("about", categoryValue.about);
        formData.append("equipment_type_id", categoryValue.type_id);
        formData.append("status", categoryValue.status ? "1" : "0");
        formData.append("meta_title", categoryValue.meta_title);
        formData.append("meta_description", categoryValue.meta_description);

        // faqsFields.forEach((q, index) => {
        //     formData.append("question[]", q.question);
        //     formData.append("answer[]", q.answer);
        // });

        try {
            setLoading(true);
            const response = await service.post(
                "equipment-category",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                setAlertInfo({
                    show: true,
                    message: response.data.message,
                });

                setTimeout(() => {
                    setLoadingBar(false);
                    setAlertInfo({
                        show: false,
                        message: "",
                    });

                    navigate("/equipment-categories");
                }, 1500);
            }
            setLoading(false);
            setLoadingBar(false);
        } catch (error) {
            alert(error);
            setLoading(false);
            console.log(error);
            setAlertInfo({
                show: true,
                message: "Opps! something wrong.",
            });
            setTimeout(() => {
                setLoadingBar(false);
                setAlertInfo({
                    show: false,
                    message: "",
                });

                navigate("/equipment-types");
            }, 1500);
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            {loadingBar && (
                <div style={{ width: "100%", position: "absolute" }}>
                    <LinearProgress />
                </div>
            )}

            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Equipment Category Add"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Equipment Categories"
                    buttonURL="equipment-categories"
                />

                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500  w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    {/* <Tab label="Faqs" {...a11yProps(1)} /> */}
                                    <Tab label="SEO" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="our-customer-create-form"
                                id="our-customer-create-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                className="bg-white p-4"
                                                xs={12}
                                                md={8}
                                            >
                                                <TextField
                                                    id="name"
                                                    label="Name"
                                                    name="name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={categoryValue.name}
                                                    onChange={handleNameChange}
                                                />

                                                <TextField
                                                    id="url"
                                                    label="URL"
                                                    name="url"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={categoryValue.url}
                                                    onChange={handleChangeValue}
                                                />

                                                <TextField
                                                    id="about"
                                                    label="About"
                                                    name="about"
                                                    variant="outlined"
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    sx={{ mb: 3 }}
                                                    value={categoryValue.about}
                                                    onChange={handleChangeValue}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Equipment Type *
                                                        </label>
                                                        <Select
                                                            required
                                                            className="w-full"
                                                            labelId="state-simple-select-label"
                                                            id="state-simple-select"
                                                            value={
                                                                categoryValue.type_id ||
                                                                ""
                                                            }
                                                            label="Select State *"
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                            name="type_id"
                                                        >
                                                            {categoryResponse?.data?.data.map(
                                                                (
                                                                    state,
                                                                    index
                                                                ) => (
                                                                    <MenuItem
                                                                        key={
                                                                            state.id
                                                                        }
                                                                        value={
                                                                            state.id
                                                                        }
                                                                    >
                                                                        {
                                                                            state.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    {/* <TabPanel value={value} index={1}>
                                        {faqsFields.map((field, index) => (
                                            <Box
                                                component="div"
                                                border={1}
                                                marginBottom={2}
                                                key={index}
                                                padding={2}
                                            >
                                                <TextField
                                                    id={"question_" + index}
                                                    label="Question"
                                                    name="question[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={field.question}
                                                    sx={{ mb: 3 }}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              question:
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                <TextField
                                                    id={"answer_" + index}
                                                    label="Answer"
                                                    name="answer[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    value={field.answer}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              answer: e
                                                                                  .target
                                                                                  .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                {index !== 0 && (
                                                    <Button
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                index
                                                            )
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </Box>
                                        ))}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button
                                                onClick={handleAddField}
                                                variant="contained"
                                                startIcon={<AddOutlinedIcon />}
                                            >
                                                Add More
                                            </Button>
                                        </div>
                                    </TabPanel> */}

                                    <TabPanel value={value} index={1}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={categoryValue.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={4}
                                            sx={{ mb: 3 }}
                                            value={
                                                categoryValue.meta_description
                                            }
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <Box component="div">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        categoryValue.status
                                                    }
                                                    name="status"
                                                    value={categoryValue.status}
                                                    onChange={(e) =>
                                                        setCategoryValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                status: e.target
                                                                    .checked
                                                                    ? true
                                                                    : false,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Status"
                                        />
                                    </Box>
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",

                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>

                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default CategoryAdd;
