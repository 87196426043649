export const checkVideoServiceValidation = (
    service,
    blocks,
    faqs,
    heroImage
) => {
    if (service.service_type === "") {
        return alert("Service type is required");
    }
    if (heroImage === "") {
        return alert("Service hero image is required");
    }
    if (blocks[0].name === "") {
        return alert("Service block is required");
    }
    if (blocks[0].content === "") {
        return alert("Description is required");
    }
    if (faqs[0].question === "" || faqs[0].answer === "") {
        return alert("FAQ is required");
    }

    if (
        service.meta_title === "" ||
        service.meta_description === "" ||
        service.meta_keyword === ""
    ) {
        return alert("Meta fields cannot be empty");
    }

    return true;
};

export const checkEventServiceValidation = (service) => {
    if (service.service_type === "") {
        return alert("Service type is required");
    }

    if (
        service.meta_title === "" ||
        service.meta_description === "" ||
        service.meta_keyword === ""
    ) {
        return alert("Meta fields cannot be empty");
    }

    return true;
};

export const checkCustomerValidation = (customer) => {
    console.log("customer", customer);

    if (customer.logo === "") {
        return alert("Logo cannot be empty, upload the logo");
    }
    if (customer.about === "") {
        return alert("Customer about is required");
    }
    return true;
};

export const checkProjectValidation = (
    project,
    services,
    teams,
    equipments,
    faqs
) => {
    if (project.description === "") {
        return alert("Project description is required");
    }

    if (project.start_date === "") {
        return alert("Project start date is required");
    }

    if (project.end_date === "") {
        return alert("Project end date is required");
    }

    if (project.heroImage === "") {
        return alert("Hero image is required");
    }

    if (project.heroVideo === "") {
        return alert("Hero video is required");
    }
    if (services?.length < 1) {
        return alert("Choose at least one service");
    }
    if (teams?.length < 1) {
        return alert("Choose the team members who worked on this project.");
    }
    if (equipments?.length < 1) {
        return alert(
            "Choose the team list of equipments used on this project."
        );
    }
    if (faqs[0].question === "" || faqs[0].answer === "") {
        return alert("FAQ is required");
    }
    if (
        project.meta_title === "" ||
        project.meta_description === "" ||
        project.meta_keyword === ""
    ) {
        return alert("Meta fields cannot be empty, they are for SEO purpose");
    }

    return true;
};

export const checkTeamValidation = (team, services, stats, faqs) => {
    console.log("customer", team);

    if (team.aboutMe === "") {
        return alert("About Member is required");
    }
    if (team.heroImage === "") {
        return alert("Member avatar cannot be empty");
    }
    if (services?.length < 1) {
        return alert("Choose at leas one service for the member");
    }
    if (
        team.meta_title === "" ||
        team.meta_description === "" ||
        team.meta_keyword === ""
    ) {
        return alert("Meta fields cannot be empty, they are for SEO purpose");
    }
    if (stats[0].title === "" || stats[0].description === "") {
        return alert("Fill at least one member stats");
    }
    if (faqs[0].question === "" || faqs[0].answer === "") {
        return alert("FAQ's  required");
    }
    return true;
};
