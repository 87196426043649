import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useRef, useState } from "react";
import { useParams } from "react-router-dom";

import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";
import { checkCustomerValidation } from "../../utils";

function CustomerEdit() {
    const [customer, setCustomer] = useState({
        name: "",
        // email: "",
        // mobile: "",
        logo_alt: "",
        website: "",
        about: "",
        position: "",
        logo: "",
        status: false,
        display_home: false,
    });

    const { id } = useParams();

    const [storeAltImage, setStoreAltImage] = useState("");

    useQuery(
        ["get-single-team-member"],
        () => service.get(`our-customer/${id}`),
        {
            refetchOnWindowFocus: false,
            onSuccess: (res) => {
                setCustomer({
                    name: res.data.data.name,
                    // email: res.data.data.email,
                    // mobile: res.data.data.mobile,
                    website: res.data.data.website,
                    about: res.data.data.about,
                    position: res.data.data.position,
                    logo: res.data.data.logo?.path,
                    logo_alt: res.data.data.logo_alt,
                    status: res.data.data.status,
                    display_home: res.data.data.display_home,
                });
                setStoreAltImage(res.data.data.logo?.path);
            },
        }
    );

    const [previewImage, setPreviewImage] = useState<any>("");

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setCustomer((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleContentChange = (content, editor) => {
        setCustomer((prev) => ({ ...prev, about: content }));
    };

    const handleLogo = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setCustomer((prev) => ({ ...prev, logo: event.target.files[0] }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const previewImageInputRef = useRef(null);

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        previewImageInputRef.current.value = null;

        setCustomer((prev) => ({ ...prev, logo: storeAltImage }));
    };

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    const { service } = useAxiosHook();
    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();

        const validationPassed = checkCustomerValidation(customer);

        formData.append("name", customer.name);
        // formData.append("email", customer.email);
        formData.append("about", customer.about);
        // formData.append("mobile", customer.mobile);
        formData.append("website", customer.website);
        formData.append("position", customer.position);
        formData.append("logo_id", customer.logo);
        formData.append("logo_alt", customer.logo_alt);
        formData.append("status", customer.status ? "1" : "0");
        formData.append("display_home", customer.display_home ? "1" : "0");

        if (validationPassed) {
            try {
                setLoading(true);
                const { status, data } = await service.post(
                    `our-customer/edit/${id}`,
                    formData,
                    {
                        headers: {
                            "Content-Type": "multipart/form-data",
                            Accept: "application/json",
                        },
                    }
                );
                setLoading(false);
                if (status === 200) {
                    setLoading(false);
                    setAlertInfo({
                        show: true,
                        message: data.message,
                    });
                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: null,
                        });
                    }, 3000);
                } else {
                    setLoading(false);
                    setAlertInfo({
                        show: false,
                        message: "Something went wrong",
                    });
                }
            } catch (error) {
                setLoading(false);
            }
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />
            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />
            <div className="content w-full bg-lightPrimary  min-h-screen">
                <Header
                    headTitle="Edit Customer"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Our Customer List"
                    buttonURL="our-customers"
                />
                <Box
                    component="div"
                    className="pt-4 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500  w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box component="div">
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                    name="our-customer-create-form"
                                    id="our-customer-create-form"
                                    encType="multipart/form-data"
                                    className="myForm pt-5"
                                >
                                    <FormGroup>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                className="bg-white p-4"
                                                xs={12}
                                                md={8}
                                            >
                                                <TextField
                                                    id="name"
                                                    label="Name"
                                                    name="name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    value={customer.name}
                                                    onChange={handleInputChange}
                                                    sx={{ mb: 3 }}
                                                />

                                                {/* <TextField
                                                    id="email"
                                                    label="Email"
                                                    type="email"
                                                    name="email"
                                                    value={customer.email}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                /> */}

                                                <span className="text-gray-800">
                                                    About *
                                                </span>

                                                <TinyEditor
                                                    handleEditorChange={(
                                                        content,
                                                        editor
                                                    ) =>
                                                        handleContentChange(
                                                            content,
                                                            editor
                                                        )
                                                    }
                                                    contentData={customer.about}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        {previewImage && (
                                                            <div className="border bg-white p-2 mb-2 shadow w-full relative">
                                                                <img
                                                                    className="h-80 w-full object-cover rounded-md"
                                                                    src={
                                                                        previewImage
                                                                    }
                                                                    alt="Preview"
                                                                />

                                                                <button
                                                                    onClick={
                                                                        handleRemovePreviewImage
                                                                    }
                                                                    type="button"
                                                                    className="absolute border border-red-500 rounded text-red-500 right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}

                                                        {/* show the image from backend */}

                                                        {customer.logo &&
                                                            Object.keys(
                                                                customer.logo
                                                            ).length >= 1 && (
                                                                <>
                                                                    <div className="w-full relative mb-4">
                                                                        <img
                                                                            className="h-80 w-full object-cover rounded-md"
                                                                            src={
                                                                                customer.logo
                                                                            }
                                                                            alt={`${customer.name}`}
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                        <TextField
                                                            sx={{
                                                                mt: 2,
                                                            }}
                                                            fullWidth
                                                            id="outlined-basic"
                                                            label="Image Alt Text"
                                                            variant="outlined"
                                                            required
                                                            name="logo_alt"
                                                            value={
                                                                customer.logo_alt
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            placeholder="Write the Alt text for this image"
                                                            helperText="This field is required for the SEO purpose"
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                        >
                                                            Upload Logo *
                                                            <input
                                                                name="logo_id"
                                                                type="file"
                                                                accept="image/*"
                                                                ref={
                                                                    previewImageInputRef
                                                                }
                                                                hidden
                                                                onChange={
                                                                    handleLogo
                                                                }
                                                            />
                                                        </Button>
                                                    </Box>
                                                    {/* <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <TextField
                                                            id="mobile"
                                                            label="Mobile"
                                                            name="mobile"
                                                            type="tel"
                                                            variant="outlined"
                                                            value={
                                                                customer.mobile
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            fullWidth
                                                        />
                                                    </Box> */}
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <TextField
                                                            id="website"
                                                            label="Website"
                                                            name="website"
                                                            type="url"
                                                            variant="outlined"
                                                            value={
                                                                customer.website
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            fullWidth
                                                        />
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <TextField
                                                            id="position"
                                                            label="Position"
                                                            name="position"
                                                            type="number"
                                                            variant="outlined"
                                                            focused
                                                            value={
                                                                customer.position
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            fullWidth
                                                            inputProps={{
                                                                maxLength: 2,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </FormGroup>

                                    <Box
                                        component="div"
                                        sx={{
                                            borderTop: 1,
                                            borderColor: "divider",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            py: "20px",
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            className="text-gray-900"
                                        >
                                            <FormControlLabel
                                                className="text-gray-800"
                                                control={
                                                    <Switch
                                                        checked={
                                                            !!customer.display_home
                                                        }
                                                        name="status"
                                                        value={
                                                            !!customer.display_home
                                                        }
                                                        onChange={(e) =>
                                                            setCustomer(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    display_home:
                                                                        e.target
                                                                            .checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Display in Home"
                                            />

                                            <FormControlLabel
                                                className="text-gray-800"
                                                control={
                                                    <Switch
                                                        checked={
                                                            !!customer.status
                                                        }
                                                        name="status"
                                                        value={
                                                            !!customer.status
                                                        }
                                                        onChange={(e) =>
                                                            setCustomer(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    status: e
                                                                        .target
                                                                        .checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Status"
                                            />
                                        </Box>

                                        {loading ? (
                                            <Backdrop
                                                sx={{
                                                    color: "#fff",
                                                    zIndex: (theme) =>
                                                        theme.zIndex.drawer + 1,
                                                }}
                                                open={true}
                                            >
                                                <Typography sx={{ mr: 2 }}>
                                                    Saving...
                                                </Typography>
                                                <CircularProgress color="secondary" />
                                            </Backdrop>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                size="large"
                                                type="submit"
                                                endIcon={<SendIcon />}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default CustomerEdit;
