import useAxiosHook from "./AxiosHook";

function useOurCustomerHook() {
    const { service } = useAxiosHook();

    async function getCustomer() {
        return await service.get("/getOurCustomer").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service
            .get("/our-customer?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteCustomer(id: number) {
        return await service.delete("/our-customer/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getCustomer,
        fetch,
        deleteCustomer,
    };
}

export default useOurCustomerHook;
