import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    Alert,
    Box,
    CircularProgress,
    LinearProgress,
    Pagination,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";

import EditIcon from "@mui/icons-material/Edit";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";

import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useServiceHook from "../../hooks/ServiceHook";

type Metas = {
    count: number;
    currentPage: number;
    nextPage?: number | null;
    prevPage?: number | null;
    totalPage: number;
};

function ServiceList() {
    const [rows, setRows] = useState<any>([]);
    const [page, setPage] = useState<number>(1);
    const [loadingBar, setLoadingBar] = useState<boolean>(false);
    const [meta, setMeta] = useState<Metas>({
        count: 1,
        currentPage: 1,
        totalPage: 1,
    });

    const { fetch, deleteService } = useServiceHook();

    const { isLoading } = useQuery(["ocCache"], () => fetch(meta.currentPage), {
        onSuccess: (data) => {
            setLoadingBar(true);
            meta.currentPage = Number(data.data.current_page);
            meta.count = data.data.total;
            meta.nextPage = Number(data.data.next_page_url?.split("="));
            meta.prevPage = Number(data.data.prev_page_url?.split("="));
            meta.totalPage = Number(data.data.last_page);
            setRows(data.data.data);
            setMeta(meta);
            setLoadingBar(false);
        },
        refetchOnWindowFocus: false,
    });

    async function handlePagination(
        event: React.ChangeEvent<unknown>,
        value: number
    ) {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        // window.scrollTo({ top: 0, behavior: "smooth" });

        setLoadingBar(true);

        await fetch(value).then((data: any) => {
            meta.currentPage = Number(data.data.current_page);
            meta.count = data.data.total;
            meta.nextPage = Number(data.data.next_page_url?.split("="));
            meta.prevPage = Number(data.data.prev_page_url?.split("="));
            meta.totalPage = Number(data.data.last_page);
            setRows(data.data.data);
            setMeta(meta);
            setLoadingBar(false);
        });

        setPage(value);
    }

    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    async function deleteRecord(id: number) {
        let text = "Are you sure you want to delete this record!";
        if (window.confirm(text) === true) {
            setLoadingBar(true);
            await deleteService(id).then((data: any) => {
                setAlertInfo({
                    show: true,
                    message: data.message,
                });

                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 1500);
            });
            await fetch(meta.currentPage).then((data: any) => {
                meta.currentPage = Number(data.data.current_page);
                meta.count = data.data.total;
                meta.nextPage = Number(data.data.next_page_url?.split("="));
                meta.prevPage = Number(data.data.prev_page_url?.split("="));
                meta.totalPage = Number(data.data.last_page);
                setRows(data.data.data);
                setMeta(meta);
                setLoadingBar(false);
            });
        }
    }

    return (
        <div className="flex">
            <Sidebar />

            {/* <Toaster message={message} duration={3000} snackBarOpen={isOpen} /> */}
            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            <div className="content w-full bg-gray-100 min-h-screen">
                {loadingBar && (
                    <div style={{ width: "100%" }}>
                        <LinearProgress />
                    </div>
                )}
                <Header
                    headTitle="Services"
                    buttonIcon={<AddIcon />}
                    buttonText="Add New Service"
                    buttonURL="service/add"
                />
                <Box
                    component="div"
                    className="p-5 mx-auto mb-auto h-full min-h-[100vh]"
                >
                    {/* <div>{JSON.stringify(isLoading)}</div> */}
                    {isLoading && (
                        <Box sx={{ display: "flex", justifyContent: "center" }}>
                            <CircularProgress size="5rem" />
                        </Box>
                    )}

                    {!isLoading && !loadingBar && (
                        <Box
                            component="div"
                            className="bg-clip-border shadow-3xl shadow-shadow-500 dark:text-white dark:shadow-none w-full min-h-screen sm:overflow-auto"
                        >
                            {rows && rows.length < 1 && (
                                <Alert severity="warning">
                                    There is no service available!
                                </Alert>
                            )}
                            {rows && rows.length > 0 && (
                                <>
                                    <TableContainer component={Paper}>
                                        <Table
                                            sx={{ minWidth: 650 }}
                                            aria-label="simple table"
                                        >
                                            <TableHead className="">
                                                <TableRow>
                                                    <TableCell>Name</TableCell>
                                                    <TableCell>
                                                        Service Type
                                                    </TableCell>
                                                    <TableCell>
                                                        Display Home
                                                    </TableCell>
                                                    <TableCell>
                                                        Status
                                                    </TableCell>
                                                    <TableCell
                                                        style={{ width: 120 }}
                                                    >
                                                        Action
                                                    </TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {rows.map(
                                                    (row: any, k: number) => (
                                                        <TableRow
                                                            key={row.id}
                                                            sx={{
                                                                "&:last-child td, &:last-child th":
                                                                    {
                                                                        border: 0,
                                                                    },
                                                            }}
                                                            className="myTable hover:bg-gray-200 transition-all duration-200"
                                                        >
                                                            <TableCell>
                                                                {row.name}
                                                            </TableCell>
                                                            <TableCell>
                                                                {
                                                                    row
                                                                        .service_category
                                                                        ?.name
                                                                }
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.display_home
                                                                    ? "Yes"
                                                                    : "No"}
                                                            </TableCell>
                                                            <TableCell>
                                                                {row.status
                                                                    ? "Active"
                                                                    : "Inactive"}
                                                            </TableCell>
                                                            <TableCell>
                                                                <Link
                                                                    to={`/service/edit/${row.id}`}
                                                                >
                                                                    <Tooltip title="Edit">
                                                                        <IconButton>
                                                                            <EditIcon />
                                                                        </IconButton>
                                                                    </Tooltip>
                                                                </Link>
                                                                <Tooltip title="Delete">
                                                                    <IconButton
                                                                        onClick={() =>
                                                                            deleteRecord(
                                                                                row.id
                                                                            )
                                                                        }
                                                                    >
                                                                        <DeleteIcon
                                                                            className={
                                                                                "delete" +
                                                                                row.id
                                                                            }
                                                                        />
                                                                    </IconButton>
                                                                </Tooltip>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                )}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <Box
                                        className="flex justify-center mt-8 mb-8"
                                        component="div"
                                    >
                                        <Pagination
                                            count={meta.totalPage}
                                            onChange={handlePagination}
                                            page={page}
                                        />
                                    </Box>
                                </>
                            )}
                        </Box>
                    )}
                </Box>
            </div>
        </div>
    );
}

export default ServiceList;
