import AddIcon from "@mui/icons-material/Add";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    AccordionDetails,
    Backdrop,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    SnackbarOrigin,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import { ChangeEvent, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import {
    Box,
    FormControlLabel,
    FormGroup,
    Grid,
    Switch,
    Tab,
    Tabs,
    TextField,
} from "@mui/material";
import { SyntheticEvent } from "react";

import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";
import {
    checkEventServiceValidation,
    checkVideoServiceValidation,
} from "../../utils";

export interface State extends SnackbarOrigin {
    open: boolean;
}

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

interface ServiceState {
    name: string;
    url: string;
    short_description: string;
    heroVideo: string;
    service_type: any;
    description: string;
    status: any;
    display_home: any;
    meta_title: string;
    meta_description: string;
    hero_image_alt: string;
}

interface BlockState {
    name: string;
    contentHead: string;
    content: string;
    image: any;
    image_alt: any;
    imagePosition: string;
    contentPosition: string;
    contentCaption: string;
    blockPosition: string;
    background: string;
    previewBlockImage: any;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function ServiceAdd() {
    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // Information Tab
    const [service, setService] = useState<ServiceState>({
        name: "",
        url: "",
        heroVideo: "",
        short_description: "",
        service_type: "",
        description: "",
        status: false,
        display_home: false,
        meta_title: "",
        meta_description: "",
        hero_image_alt: "",
    });

    const handleInformationInputChange = (e: any) => {
        const { name, value } = e.target;
        setService((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const [showBlock, setShowBlock] = useState(true);

    const [tabNumber, setTabNumber] = useState({
        tab_1: 0,
        tab_2: 1,
        tab_3: 2,
        tab_4: 3,
    });

    /**
     * I want to set the service type first, and then check the condition,
     * I need to use the control flow of this code, that's why I have
     * written the solution inside the setService state.
     */

    const handleServiceTypeChange = (event) => {
        setService((prev) => {
            const updatedService = {
                ...prev,
                service_type: event.target.value,
            };

            if (updatedService.service_type !== 1) {
                setShowBlock(false);
                setTabNumber({
                    tab_1: 0,
                    tab_2: 4,
                    tab_3: 3,
                    tab_4: 1,
                });
            } else {
                setShowBlock(true);
                setTabNumber({
                    tab_1: 0,
                    tab_2: 1,
                    tab_3: 2,
                    tab_4: 3,
                });
            }

            return updatedService;
        });
    };

    // handle title change and generate auto slug
    const handleNameChange = (event) => {
        setService((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setService((prev) => ({ ...prev, url: slugValue }));
    };

    // image uploading functionalities
    const previewImageInputRef = useRef(null);
    const [heroImage, setHeroImage] = useState("");
    const [previewImage, setPreviewImage] = useState(null);

    const handleHeroImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setHeroImage(event.target.files[0]);
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        setHeroImage("");
        // Reset the value of the input element
        previewImageInputRef.current.value = null;
    };

    // starting of thee Block Section
    const [blockInputs, setBlockInputs] = useState<BlockState[]>([
        {
            name: "",
            contentHead: "",
            content: "",
            image: "",
            image_alt: "",
            imagePosition: "",
            contentPosition: "",
            contentCaption: "",
            blockPosition: "",
            background: "",
            previewBlockImage: "",
        },
    ]);

    const handleBlockAddInput = () => {
        const values = [...blockInputs];
        values.push({
            name: "",
            contentHead: "",
            content: "",
            image: "",
            image_alt: "",
            imagePosition: "",
            contentPosition: "",
            contentCaption: "",
            blockPosition: "",
            background: "",
            previewBlockImage: "",
        });
        setBlockInputs(values);
    };

    const handleBlockRemoveInput = (index) => {
        const values = [...blockInputs];
        values.splice(index, 1);
        setBlockInputs(values);
    };

    const handleBlockInputChange = (index, event) => {
        const { name, value } = event.target;
        setBlockInputs((prevInputs) => {
            const updatedInputs = [...prevInputs];
            updatedInputs[index] = {
                ...updatedInputs[index],
                [name]: value,
            };
            return updatedInputs;
        });
    };

    const previewBlockImageInputRef = useRef(null);

    const handleHeroBlockImage = (
        index: number,
        event: ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setBlockInputs((prev) => {
                const updatedBlockImage = [...prev];
                updatedBlockImage[index].image = event.target.files[0];
                return updatedBlockImage;
            });

            const reader = new FileReader();
            reader.onloadend = () => {
                setBlockInputs((prev) => {
                    const updatedPreviewImage = [...prev];
                    updatedPreviewImage[index].previewBlockImage =
                        reader.result;
                    return updatedPreviewImage;
                });
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemoveBlockPreviewImage = (index) => {
        setBlockInputs((prev) => {
            const updatedBlockImage = [...prev];
            updatedBlockImage[index].image = null;
            return updatedBlockImage;
        });
        setBlockInputs((prev) => {
            const updatedPreviewImage = [...prev];
            updatedPreviewImage[index].previewBlockImage = null;
            return updatedPreviewImage;
        });
        // Reset the value of the input element
        previewBlockImageInputRef.current.value = null;
    };

    const handleBlockContentChange = (index, content) => {
        const updatedInputs = [...blockInputs];
        updatedInputs[index].content = content;
        setBlockInputs(updatedInputs);
    };
    // Ending of block Section

    // starting of the FAQ section
    const [faqInputs, setFaqInputs] = useState([{ question: "", answer: "" }]);

    const handleQuestionInputChange = (index, event) => {
        const values = [...faqInputs];
        values[index].question = event.target.value;
        setFaqInputs(values);
    };

    const handleAnswerInputChange = (index, event) => {
        const values = [...faqInputs];
        values[index].answer = event.target.value;
        setFaqInputs(values);
    };

    const handleFaqAddInput = () => {
        const values = [...faqInputs];
        values.push({ question: "", answer: "" });
        setFaqInputs(values);
    };

    const handleFaqRemoveInput = (index) => {
        const values = [...faqInputs];
        values.splice(index, 1);
        setFaqInputs(values);
    };
    // ending of the faq section

    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    // submit the form
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const { service: serviceAPI } = useAxiosHook();
    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();

        let validationPassed;
        if (showBlock) {
            validationPassed = checkVideoServiceValidation(
                service,
                blockInputs,
                faqInputs,
                heroImage
            );
        } else {
            validationPassed = checkEventServiceValidation(service);
        }

        formData.append("name", service.name);
        formData.append("url", service.url);
        formData.append("short_description", service.short_description);
        formData.append("description", service.description);
        formData.append("hero_video_id", service.heroVideo);
        formData.append("status", service.status ? "1" : "0");
        formData.append("service_category_id", service.service_type);
        formData.append("display_home", service.display_home ? "1" : "0");
        formData.append("hero_image_id", heroImage);
        formData.append("meta_title", service.meta_title);
        formData.append("meta_description", service.meta_description);
        formData.append("hero_image_alt", service.hero_image_alt);

        blockInputs.forEach((block, index) => {
            formData.append("block_name[]", block.name);
            formData.append("content_head[]", block.contentHead);
            formData.append("content_caption[]", block.contentCaption);
            formData.append("content[]", block.content);
            formData.append("block_image_id[" + index + "]", block.image);
            formData.append("block_image_alt[" + index + "]", block.image_alt);
            formData.append("block_image_position[]", block.imagePosition);
            formData.append("content_position[]", block.contentPosition);
            formData.append("block_position[]", block.blockPosition);
            formData.append("block_background[]", block.background);
        });

        faqInputs.forEach((faq, index) => {
            formData.append("question[]", faq.question);
            formData.append("answer[]", faq.answer);
        });

        if (validationPassed) {
            try {
                setLoading(true);
                const response = await serviceAPI.post("service", formData, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                    },
                });
                setLoading(false);
                if (response.status === 200) {
                    setAlertInfo({
                        show: true,
                        message: "Service added successfully",
                    });
                    setTimeout(() => {
                        navigate("/services");
                    }, 1500);
                    return;
                }
                setAlertInfo({
                    show: true,
                    message: "Something went wrong",
                });
                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 1500);
            } catch (error) {
                console.log(error);
                setLoading(false);
            }
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />
            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Add New Service"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Services"
                    buttonURL="services"
                />

                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 dark:text-white dark:shadow-none w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(tabNumber.tab_1)}
                                    />
                                    {showBlock && (
                                        <Tab
                                            label="Block"
                                            {...a11yProps(tabNumber.tab_2)}
                                        />
                                    )}
                                    {showBlock && (
                                        <Tab
                                            label="FAQ"
                                            {...a11yProps(tabNumber.tab_3)}
                                        />
                                    )}

                                    <Tab
                                        label="SEO"
                                        {...a11yProps(tabNumber.tab_4)}
                                    />
                                </Tabs>
                            </Box>
                            <Box component="div">
                                <form
                                    onSubmit={handleSubmit}
                                    autoComplete="off"
                                    name="service-create-form"
                                    id="service-create-form"
                                    encType="multipart/form-data"
                                    className="myForm"
                                >
                                    <FormGroup>
                                        {/* Information Tab */}

                                        <TabPanel
                                            value={value}
                                            index={tabNumber.tab_1}
                                        >
                                            <Grid container spacing={2}>
                                                <Grid
                                                    item
                                                    className="bg-white p-4"
                                                    xs={12}
                                                    md={8}
                                                >
                                                    <TextField
                                                        id="name"
                                                        label="Name"
                                                        name="name"
                                                        value={service.name}
                                                        onChange={
                                                            handleNameChange
                                                        }
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />
                                                    <TextField
                                                        id="url"
                                                        label="URL"
                                                        name="url"
                                                        value={service.url}
                                                        onChange={
                                                            handleInformationInputChange
                                                        }
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        sx={{ mb: 3 }}
                                                    />
                                                    <TextField
                                                        multiline
                                                        id="hero_short_description"
                                                        label="Service Description"
                                                        name="short_description"
                                                        variant="outlined"
                                                        fullWidth
                                                        required
                                                        rows={4}
                                                        value={
                                                            service.short_description
                                                        }
                                                        onChange={
                                                            handleInformationInputChange
                                                        }
                                                        sx={{ mb: 3 }}
                                                    />

                                                    <label className="mb-2 block">
                                                        Service Type *
                                                    </label>
                                                    <FormControl fullWidth>
                                                        <InputLabel id="demo-simple-select-label">
                                                            Service Type *
                                                        </InputLabel>
                                                        <Select
                                                            required
                                                            labelId="demo-simple-select-label"
                                                            id="demo-simple-select"
                                                            value={
                                                                service.service_type
                                                            }
                                                            label="Select Service Type"
                                                            onChange={
                                                                handleServiceTypeChange
                                                            }
                                                            name="service_type"
                                                        >
                                                            <MenuItem value={1}>
                                                                Video Production
                                                            </MenuItem>
                                                            <MenuItem value={2}>
                                                                Photography
                                                            </MenuItem>
                                                        </Select>
                                                    </FormControl>
                                                </Grid>
                                                <Grid
                                                    item
                                                    style={{
                                                        paddingTop: 0,
                                                        marginTop: "-8px",
                                                        paddingLeft: 0,
                                                    }}
                                                    className="bg-gray-100"
                                                    xs={12}
                                                    md={4}
                                                >
                                                    <Box className="p-3">
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            {previewImage && (
                                                                <Box
                                                                    component="div"
                                                                    className="w-full border p-2 mb-4 relative"
                                                                >
                                                                    <img
                                                                        className="h-80 w-full object-cover object-top rounded-md"
                                                                        src={
                                                                            previewImage
                                                                        }
                                                                        ref={
                                                                            previewImageInputRef
                                                                        }
                                                                        alt="Preview"
                                                                    />
                                                                    <TextField
                                                                        sx={{
                                                                            mt: 2,
                                                                        }}
                                                                        fullWidth
                                                                        id="hero_image_alt"
                                                                        name="hero_image_alt"
                                                                        label="Image Alt Text"
                                                                        variant="outlined"
                                                                        required
                                                                        placeholder="Write the Alt text for this image"
                                                                        helperText="This field is required for the SEO purpose"
                                                                        value={
                                                                            service.hero_image_alt
                                                                        }
                                                                        onChange={
                                                                            handleInformationInputChange
                                                                        }
                                                                    />
                                                                    <button
                                                                        type="button"
                                                                        onClick={
                                                                            handleRemovePreviewImage
                                                                        }
                                                                        className="absolute border border-red-500 border-black rounded text-black right-4 top-4"
                                                                    >
                                                                        <svg
                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                            fill="none"
                                                                            viewBox="0 0 24 24"
                                                                            strokeWidth="1.5"
                                                                            stroke="currentColor"
                                                                            className="w-6 h-6 text-red-500"
                                                                        >
                                                                            <path
                                                                                strokeLinecap="round"
                                                                                strokeLinejoin="round"
                                                                                d="M6 18L18 6M6 6l12 12"
                                                                            />
                                                                        </svg>
                                                                    </button>
                                                                </Box>
                                                            )}

                                                            <Button
                                                                variant="contained"
                                                                component="label"
                                                                color="info"
                                                                startIcon={
                                                                    <FileUploadIcon />
                                                                }
                                                            >
                                                                Upload Hero
                                                                Image (Required)
                                                                <input
                                                                    type="file"
                                                                    ref={
                                                                        previewImageInputRef
                                                                    }
                                                                    hidden
                                                                    accept="image/*"
                                                                    onChange={
                                                                        handleHeroImage
                                                                    }
                                                                />
                                                            </Button>
                                                            <p
                                                                className="helper-text"
                                                                id="heroImage-helper-text"
                                                            >
                                                                Image upload
                                                                maximum size 5
                                                                MB
                                                            </p>
                                                            <span className="mt-1 block font-medium">
                                                                Size (1200px X
                                                                1400px)
                                                            </span>
                                                        </Box>
                                                        <Box
                                                            component="div"
                                                            className="border bg-white p-3.5 shadow mb-4"
                                                        >
                                                            <TextField
                                                                id="heroVideo"
                                                                label="Hero Video"
                                                                name="heroVideo"
                                                                variant="outlined"
                                                                fullWidth
                                                                value={
                                                                    service.heroVideo
                                                                }
                                                                helperText="Add the video url of ie(Vimeo, YouTube)"
                                                                onChange={
                                                                    handleInformationInputChange
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Grid>
                                            </Grid>
                                        </TabPanel>

                                        {/* FAQ Tab */}
                                        <TabPanel
                                            value={value}
                                            index={tabNumber.tab_3}
                                        >
                                            {faqInputs.map((input, index) => (
                                                <Box
                                                    key={index}
                                                    mb={2}
                                                    component="div"
                                                    border={1}
                                                    borderColor="ActiveBorder"
                                                    borderRadius={1}
                                                    marginBottom={2}
                                                    padding={2}
                                                >
                                                    <TextField
                                                        id={"question_" + index}
                                                        label="Question"
                                                        variant="outlined"
                                                        name="question"
                                                        fullWidth
                                                        value={input.question}
                                                        onChange={(event) =>
                                                            handleQuestionInputChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                        sx={{ mb: 3 }}
                                                    />

                                                    <TextField
                                                        fullWidth
                                                        id="outlined-multiline-static"
                                                        label="Answer"
                                                        name="answer"
                                                        multiline
                                                        sx={{ mb: 3 }}
                                                        rows={4}
                                                        value={input.answer}
                                                        onChange={(event) =>
                                                            handleAnswerInputChange(
                                                                index,
                                                                event
                                                            )
                                                        }
                                                    />

                                                    <Button
                                                        sx={{ mr: 4 }}
                                                        onClick={() =>
                                                            handleFaqRemoveInput(
                                                                index
                                                            )
                                                        }
                                                        disabled={
                                                            faqInputs.length ===
                                                            1
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                    >
                                                        Remove
                                                    </Button>
                                                </Box>
                                            ))}

                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "end",
                                                }}
                                            >
                                                <Button
                                                    onClick={handleFaqAddInput}
                                                    variant="contained"
                                                    startIcon={
                                                        <AddOutlinedIcon />
                                                    }
                                                >
                                                    Add More
                                                </Button>
                                            </div>
                                        </TabPanel>

                                        {/* Block Tab */}
                                        <TabPanel
                                            value={value}
                                            index={tabNumber.tab_2}
                                        >
                                            {blockInputs.map((input, index) => (
                                                <Accordion
                                                    key={index}
                                                    sx={{ mb: 2 }}
                                                >
                                                    <AccordionSummary
                                                        expandIcon={
                                                            <ExpandMoreIcon />
                                                        }
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Box
                                                            sx={{
                                                                display: "flex",
                                                                width: "100%",
                                                                alignItems:
                                                                    "center",
                                                                justifyContent:
                                                                    "space-between",
                                                            }}
                                                        >
                                                            <Typography>
                                                                Block{" "}
                                                                {index + 1}
                                                            </Typography>

                                                            <IconButton
                                                                sx={{ mr: 4 }}
                                                                onClick={() =>
                                                                    handleBlockRemoveInput(
                                                                        index
                                                                    )
                                                                }
                                                                aria-label="delete"
                                                                disabled={
                                                                    blockInputs.length ===
                                                                    1
                                                                }
                                                            >
                                                                <DeleteIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </AccordionSummary>
                                                    <AccordionDetails
                                                        sx={{ px: 4 }}
                                                    >
                                                        <Grid
                                                            container
                                                            spacing={2}
                                                        >
                                                            <Grid item md={8}>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        id="name"
                                                                        label="Block Name"
                                                                        name="name"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        value={
                                                                            input.name
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            mb: 3,
                                                                        }}
                                                                    />
                                                                </Grid>
                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        id="brand"
                                                                        label="Content Head"
                                                                        name="contentHead"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        fullWidth
                                                                        required
                                                                        value={
                                                                            input.contentHead
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            mb: 3,
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TextField
                                                                        id="brand"
                                                                        multiline
                                                                        label="Content Caption"
                                                                        name="contentCaption"
                                                                        type="text"
                                                                        variant="outlined"
                                                                        required
                                                                        placeholder="content that will render right after your content head"
                                                                        fullWidth
                                                                        value={
                                                                            input.contentCaption
                                                                        }
                                                                        rows={4}
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                        sx={{
                                                                            mb: 3,
                                                                        }}
                                                                    />
                                                                </Grid>

                                                                <label className="text-gray-800 block mb-2">
                                                                    Description
                                                                    *
                                                                </label>

                                                                <Grid
                                                                    item
                                                                    xs={12}
                                                                >
                                                                    <TinyEditor
                                                                        handleEditorChange={(
                                                                            content
                                                                        ) =>
                                                                            handleBlockContentChange(
                                                                                index,
                                                                                content
                                                                            )
                                                                        }
                                                                        contentData={
                                                                            input.content
                                                                        }
                                                                    />
                                                                </Grid>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={4}
                                                            >
                                                                {input.previewBlockImage && (
                                                                    <div className="border w-full mb-4 relative">
                                                                        <img
                                                                            className="h-80 w-full object-cover rounded-md border"
                                                                            src={
                                                                                input.previewBlockImage
                                                                            }
                                                                            alt="Preview"
                                                                        />
                                                                        <TextField
                                                                            sx={{
                                                                                mt: 2,
                                                                            }}
                                                                            fullWidth
                                                                            name="image_alt"
                                                                            id="outlined-basic"
                                                                            label="Image Alt Text"
                                                                            variant="outlined"
                                                                            required
                                                                            value={
                                                                                input.image_alt
                                                                            }
                                                                            onChange={(
                                                                                event
                                                                            ) =>
                                                                                handleBlockInputChange(
                                                                                    index,
                                                                                    event
                                                                                )
                                                                            }
                                                                            placeholder="Write the Alt text for this image"
                                                                            helperText="This field is required for the SEO purpose"
                                                                        />
                                                                        <button
                                                                            type="button"
                                                                            onClick={() =>
                                                                                handleRemoveBlockPreviewImage(
                                                                                    index
                                                                                )
                                                                            }
                                                                            className="absolute border border-red-500 rounded text-black right-4 top-4"
                                                                        >
                                                                            <svg
                                                                                xmlns="http://www.w3.org/2000/svg"
                                                                                fill="none"
                                                                                viewBox="0 0 24 24"
                                                                                strokeWidth="1.5"
                                                                                stroke="currentColor"
                                                                                className="w-6 h-6 text-red-500"
                                                                            >
                                                                                <path
                                                                                    strokeLinecap="round"
                                                                                    strokeLinejoin="round"
                                                                                    d="M6 18L18 6M6 6l12 12"
                                                                                />
                                                                            </svg>
                                                                        </button>
                                                                    </div>
                                                                )}
                                                                <Button
                                                                    variant="contained"
                                                                    component="label"
                                                                    color="info"
                                                                    fullWidth
                                                                    sx={{
                                                                        py: 1.5,
                                                                    }}
                                                                    startIcon={
                                                                        <FileUploadIcon />
                                                                    }
                                                                >
                                                                    Block Image
                                                                    *
                                                                    <input
                                                                        type="file"
                                                                        ref={
                                                                            previewBlockImageInputRef
                                                                        }
                                                                        hidden
                                                                        accept="image/*"
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleHeroBlockImage(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                    />
                                                                </Button>
                                                                <p
                                                                    className="helper-text"
                                                                    id="heroImage-helper-text"
                                                                >
                                                                    Image upload
                                                                    maximum size
                                                                    5 MB
                                                                </p>
                                                                <span className="mt-1 block font-medium">
                                                                    Size (800px
                                                                    X 800px)
                                                                </span>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                            >
                                                                <FormControl
                                                                    fullWidth
                                                                    sx={{
                                                                        mb: 3,
                                                                    }}
                                                                >
                                                                    <InputLabel id="demo-simple-select-label">
                                                                        Image
                                                                        Position
                                                                        *
                                                                    </InputLabel>
                                                                    <Select
                                                                        required
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Image Position"
                                                                        name="imagePosition"
                                                                        value={
                                                                            input.imagePosition
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                    >
                                                                        <MenuItem
                                                                            value={
                                                                                "right"
                                                                            }
                                                                        >
                                                                            Right
                                                                            Align
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            value={
                                                                                "left"
                                                                            }
                                                                        >
                                                                            Left
                                                                            Align
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                            >
                                                                <FormControl
                                                                    fullWidth
                                                                    sx={{
                                                                        mb: 3,
                                                                    }}
                                                                >
                                                                    <InputLabel id="demo-simple-select-label">
                                                                        Content
                                                                        Position
                                                                        *
                                                                    </InputLabel>
                                                                    <Select
                                                                        required
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Content Position"
                                                                        name="contentPosition"
                                                                        value={
                                                                            input.contentPosition
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                    >
                                                                        <MenuItem
                                                                            value={
                                                                                "right"
                                                                            }
                                                                        >
                                                                            Right
                                                                            Align
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            value={
                                                                                "left"
                                                                            }
                                                                        >
                                                                            Left
                                                                            Align
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>
                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                            >
                                                                <FormControl
                                                                    fullWidth
                                                                    sx={{
                                                                        mb: 3,
                                                                    }}
                                                                >
                                                                    <InputLabel id="demo-simple-select-label">
                                                                        Background
                                                                        *
                                                                    </InputLabel>
                                                                    <Select
                                                                        required
                                                                        labelId="demo-simple-select-label"
                                                                        id="demo-simple-select"
                                                                        label="Select Background"
                                                                        name="background"
                                                                        value={
                                                                            input.background
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                    >
                                                                        <MenuItem
                                                                            value={
                                                                                "white"
                                                                            }
                                                                        >
                                                                            White
                                                                        </MenuItem>
                                                                        <MenuItem
                                                                            value={
                                                                                "gray"
                                                                            }
                                                                        >
                                                                            Gray
                                                                        </MenuItem>
                                                                    </Select>
                                                                </FormControl>
                                                            </Grid>

                                                            <Grid
                                                                item
                                                                xs={12}
                                                                md={3}
                                                            >
                                                                <FormControl
                                                                    fullWidth
                                                                    sx={{
                                                                        mb: 3,
                                                                    }}
                                                                >
                                                                    <TextField
                                                                        fullWidth
                                                                        id="outlined-multiline-static"
                                                                        label="Block Priority"
                                                                        name="blockPosition"
                                                                        type={
                                                                            "number"
                                                                        }
                                                                        value={
                                                                            input.blockPosition
                                                                        }
                                                                        onChange={(
                                                                            event
                                                                        ) =>
                                                                            handleBlockInputChange(
                                                                                index,
                                                                                event
                                                                            )
                                                                        }
                                                                    />
                                                                </FormControl>
                                                            </Grid>
                                                        </Grid>
                                                    </AccordionDetails>
                                                </Accordion>
                                            ))}

                                            <Button
                                                sx={{ mt: 4 }}
                                                onClick={handleBlockAddInput}
                                                variant="outlined"
                                                startIcon={<AddIcon />}
                                            >
                                                Add More
                                            </Button>
                                        </TabPanel>

                                        {/* SEO Tab */}
                                        <TabPanel
                                            value={value}
                                            index={tabNumber.tab_4}
                                        >
                                            <TextField
                                                id="meta_title"
                                                label="Meta Title"
                                                required
                                                name="meta_title"
                                                type="text"
                                                value={service.meta_title}
                                                onChange={
                                                    handleInformationInputChange
                                                }
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mb: 2 }}
                                            />
                                            <TextField
                                                multiline
                                                rows={4}
                                                id="meta_description"
                                                label="Meta Description"
                                                required
                                                name="meta_description"
                                                value={service.meta_description}
                                                onChange={
                                                    handleInformationInputChange
                                                }
                                                type="text"
                                                variant="outlined"
                                                fullWidth
                                                sx={{ mb: 2 }}
                                            />
                                        </TabPanel>
                                    </FormGroup>

                                    <Box
                                        component="div"
                                        sx={{
                                            borderTop: 1,
                                            borderColor: "divider",
                                            display: "flex",
                                            justifyContent: "space-between",
                                            py: "20px",
                                        }}
                                    >
                                        <Box
                                            component="div"
                                            className="text-gray-900"
                                        >
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={service.status}
                                                        name="status"
                                                        value={service.status}
                                                        onChange={(e) =>
                                                            setService(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    status: e
                                                                        .target
                                                                        .checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Status"
                                            />
                                            <FormControlLabel
                                                control={
                                                    <Switch
                                                        checked={
                                                            service.display_home
                                                        }
                                                        name="status"
                                                        value={
                                                            service.display_home
                                                        }
                                                        onChange={(e) =>
                                                            setService(
                                                                (prev) => ({
                                                                    ...prev,
                                                                    display_home:
                                                                        e.target
                                                                            .checked,
                                                                })
                                                            )
                                                        }
                                                    />
                                                }
                                                label="Display In Home"
                                            />
                                        </Box>

                                        {loading ? (
                                            <Backdrop
                                                sx={{
                                                    color: "#fff",
                                                    zIndex: (theme) =>
                                                        theme.zIndex.drawer + 1,
                                                }}
                                                open={true}
                                            >
                                                <Typography sx={{ mr: 2 }}>
                                                    Saving...
                                                </Typography>
                                                <CircularProgress color="secondary" />
                                            </Backdrop>
                                        ) : (
                                            <Button
                                                variant="outlined"
                                                size="large"
                                                type="submit"
                                                endIcon={<SendIcon />}
                                            >
                                                Save
                                            </Button>
                                        )}
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default ServiceAdd;
