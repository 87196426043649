import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import DeleteIcon from "@mui/icons-material/Delete";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { useParams } from "react-router";
import EquipmentInterface from "../../components/Interfaces/EquipmentInterface";

import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";
import useEquipmentCategoryHook from "../../hooks/EquipmentCategoryHook";
import useEquipmentTypeHook from "../../hooks/EquipmentTypeHook";
import useTeamHook from "../../hooks/TeamHook";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

function EquipmentEdit() {
    const { id } = useParams();

    // API's Calls
    const { service } = useAxiosHook();

    const { getEquipmentCategories } = useEquipmentCategoryHook();

    const { data: equipmentCategory } = useQuery({
        queryKey: ["equipmentCategory"],
        queryFn: getEquipmentCategories,
        refetchOnWindowFocus: false,
    });

    const { getEquipmentType } = useEquipmentTypeHook();

    const { data: equipmentTypes } = useQuery({
        queryKey: ["getEquipmentTypes"],
        queryFn: getEquipmentType,
        refetchOnWindowFocus: false,
    });

    const { getTeam } = useTeamHook();

    const { data: teamsData } = useQuery({
        queryKey: ["getTeam"],
        queryFn: getTeam,
        refetchOnWindowFocus: false,
    });

    const [equipmentValue, setEquipmentValue] = useState<EquipmentInterface>({
        hero_image_id: "",
        featured_image_id: "",
        hero_video_id: "",
        name: "",
        url: "",
        short_description: "",
        description: "",
        status: false,
        display_home: false,
        teams: [],
        position: "",
        equipment_category_id: "",
        equipment_type_id: "",
        meta_title: "",
        meta_description: "",
        hero_image_alt: "",
        featured_image_alt: "",
    });

    const handleChangeEquipmentValue = (e: any) => {
        const { name, value } = e.target;

        setEquipmentValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    // changing title with url slug
    const handleNameChange = (event) => {
        setEquipmentValue((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setEquipmentValue((prev) => ({ ...prev, url: slugValue }));
    };

    const [value, setValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [faqsFields, setFaqsFields] = React.useState([
        {
            question: "",
            answer: "",
        },
    ]);

    const handleAddField = () => {
        setFaqsFields([
            ...faqsFields,
            {
                question: "",
                answer: "",
            },
        ]);
    };

    const handleRemoveField = (index: any) => {
        setFaqsFields(
            faqsFields.slice(0, index).concat(faqsFields.slice(index + 1))
        );
    };

    const [category, setCategory] = useState("");

    const handleChangeSelectCategory = (event: any) => {
        setCategory(event.target.value);
    };

    const [types, setTypes] = useState("");

    const handleChangeSelectType = (event: any) => {
        setTypes(event.target.value);
    };

    // Image
    const [previewHeroImage, setPreviewHeroImage] = useState(null);
    const [showAltImage, setShowAltImage] = useState("");
    const previewImageInputRef = useRef(null);

    const handleImageChange = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            console.log(Math.round(event.target.files[0].size / (1024 * 1024)));

            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }

            setEquipmentValue((prev) => ({
                ...prev,
                hero_image_id: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewHeroImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewImage = () => {
        setPreviewHeroImage(null);
        // Reset the value of the input element
        previewImageInputRef.current.value = "";
        setEquipmentValue((prev) => ({ ...prev, hero_image_id: showAltImage }));
    };

    // Featured Image
    const [previewFeaturedImage, setPreviewFeaturedImage] = useState(null);
    const [showAltFeaturedImage, setShowAltFeaturedImage] = useState("");
    const previewFeaturedImageInputRef = useRef(null);

    const handleFeaturedImageChange = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }

            setEquipmentValue((prev) => ({
                ...prev,
                featured_image_id: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewFeaturedImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };
    const handleRemovePreviewFeaturedImage = () => {
        setPreviewFeaturedImage(null);
        // Reset the value of the input element
        previewFeaturedImageInputRef.current.value = "";
        setEquipmentValue((prev) => ({
            ...prev,
            featured_image_id: showAltFeaturedImage,
        }));
    };

    const [teams, setTeams] = React.useState<any[]>([]);

    const [equipmentContent, setEquipmentContent] = React.useState("");
    const handleContentChange = (content, editor) => {
        setEquipmentContent(content);
    };

    const [loading, setLoading] = useState(false);

    const handleSubmit = async (event: any) => {
        event.preventDefault();

        if (equipmentValue.hero_image_id === "") {
            return alert("Hero image is required");
        }

        if (equipmentValue.featured_image_id === "") {
            return alert("Featured image is required");
        }

        if (equipmentContent === "") {
            return alert("Content is required");
        }

        if (equipmentValue.meta_title === "") {
            return alert("SEO Meta title is required");
        }

        if (equipmentValue.meta_description === "") {
            return alert("SEO Meta description is required");
        }

        const formData = new FormData();
        formData.append("name", equipmentValue.name);
        formData.append("url", equipmentValue.url);
        formData.append("short_description", equipmentValue.short_description);
        formData.append("description", equipmentContent);
        formData.append("equipment_type_id", types);
        formData.append("equipment_category_id", category);
        formData.append("hero_image_id", equipmentValue.hero_image_id);
        formData.append("featured_image_id", equipmentValue.featured_image_id);
        formData.append("hero_video_id", equipmentValue.hero_video_id);
        formData.append("status", equipmentValue.status ? "1" : "0");
        formData.append(
            "display_home",
            equipmentValue.display_home ? "1" : "0"
        );
        formData.append("position", equipmentValue.position);
        formData.append("meta_title", equipmentValue.meta_title);
        formData.append("meta_description", equipmentValue.meta_description);
        formData.append("hero_image_alt", equipmentValue.hero_image_alt);
        formData.append(
            "featured_image_alt",
            equipmentValue.featured_image_alt
        );

        faqsFields.forEach((q, index) => {
            formData.append("question[]", q.question);
            formData.append("answer[]", q.answer);
        });

        teams.forEach((tag, index) => {
            formData.append("teams[]", tag.id);
        });

        try {
            setLoading(true);
            const response = await service.post(
                "equipment/edit/" + id,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            if (response.status === 200) {
                setAlertInfo({
                    show: true,
                    message: response.data.message,
                });

                setTimeout(() => {
                    setLoading(false);
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 1500);
                return true;
            }

            setAlertInfo({
                show: true,
                message: "Opps! something gone wrong",
            });
            setTimeout(() => {
                setLoading(false);
                setAlertInfo({
                    show: false,
                    message: "",
                });
            }, 1500);

            setLoading(false);

            setLoading(false);
        } catch (error) {
            alert(error);
            setLoading(false);
            console.log(error);
            setAlertInfo({
                show: true,
                message: error,
            });
            setTimeout(() => {
                setLoading(false);
                setAlertInfo({
                    show: false,
                    message: "",
                });
            }, 1500);
        }
    };

    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    const { isLoading } = useQuery(
        ["single-equipment-edit"],
        () => service.get(`equipment/${id}`),
        {
            onSuccess: (res) => {
                let response = res.data.data;

                setEquipmentContent(res.data.data.description);

                setCategory(res.data.data.equipment_category_id);

                setTypes(res.data.data.equipment_type_id);

                setEquipmentValue({
                    teams: [],
                    hero_image_id: response.hero_image?.path,
                    featured_image_id: response.featured_image?.path,
                    hero_video_id: response.hero_video_id,
                    name: response.name,
                    url: response.url,
                    short_description: response.short_description,
                    description: equipmentContent,
                    equipment_type_id: response.equipment_type_id,
                    equipment_category_id: response.equipment_category_id,
                    status: response.status,
                    display_home: response.display_home,
                    position: response.position,
                    meta_title: response.meta_title,
                    meta_description: response.meta_description,
                    hero_image_alt: response.hero_image_alt,
                    featured_image_alt: response.featured_image_alt,
                });

                const teamsArray: string[] = [];

                res.data.data.teams.forEach((t: any) => {
                    teamsArray.push(t);
                });

                const faqs = res.data.data.faqs;
                const transformedFaqs = faqs.map((faq: any) => {
                    return {
                        question: faq.question,
                        answer: faq.answer,
                    };
                });

                faqs.length >= 1 && setFaqsFields(transformedFaqs);

                setTeams(teamsArray);

                setShowAltImage(response.hero_image?.path);
                setShowAltFeaturedImage(response.featured_image?.path);
            },

            onError: (res) => {
                setTimeout(() => {
                    setAlertInfo({
                        show: true,
                        message: "Opps! something wrong",
                    });
                }, 1500);
                alert(res);
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        }
    );

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            <div className="content w-full bg-lightPrimary min-h-screen">
                {isLoading && (
                    <div style={{ width: "100%" }}>
                        <LinearProgress />
                    </div>
                )}

                <Header
                    headTitle="Equipment Edit"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Equipment"
                    buttonURL="equipment"
                />

                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500  w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="Faqs" {...a11yProps(1)} />
                                    <Tab label="SEO" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="equipment-form"
                                id="equipment-form"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                className="bg-white p-4"
                                                item
                                                xs={12}
                                                md={8}
                                            >
                                                <TextField
                                                    id="name"
                                                    label="Name"
                                                    name="name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={equipmentValue.name}
                                                    onChange={handleNameChange}
                                                />
                                                <TextField
                                                    id="url"
                                                    label="URL"
                                                    name="url"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={equipmentValue.url}
                                                    onChange={
                                                        handleChangeEquipmentValue
                                                    }
                                                />
                                                <TextField
                                                    id="short_description"
                                                    label="Short Description"
                                                    name="short_description"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={
                                                        equipmentValue.short_description
                                                    }
                                                    onChange={
                                                        handleChangeEquipmentValue
                                                    }
                                                />
                                                <label className="block mb-2">
                                                    Content *
                                                </label>
                                                <Box
                                                    component="div"
                                                    className="border-4 mt-2 mb-8 border-gray-300 rounded"
                                                >
                                                    <TinyEditor
                                                        handleEditorChange={(
                                                            content,
                                                            editor
                                                        ) =>
                                                            handleContentChange(
                                                                content,
                                                                editor
                                                            )
                                                        }
                                                        contentData={
                                                            equipmentContent
                                                        }
                                                    />
                                                </Box>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        gap: 2,
                                                    }}
                                                >
                                                    <Box
                                                        component="div"
                                                        className="w-full"
                                                    >
                                                        <label className="block mb-2">
                                                            Category *
                                                        </label>
                                                        <Select
                                                            value={category}
                                                            onChange={
                                                                handleChangeSelectCategory
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            label="Category"
                                                        >
                                                            {equipmentCategory?.data.map(
                                                                (data: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            data.id
                                                                        }
                                                                        value={
                                                                            data.id
                                                                        }
                                                                    >
                                                                        {
                                                                            data.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="w-full"
                                                    >
                                                        <label className="mb-2 block">
                                                            Teams
                                                        </label>

                                                        <Autocomplete
                                                            multiple
                                                            value={teams}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setTeams(
                                                                    newValue
                                                                );
                                                            }}
                                                            id="team-id-demo"
                                                            options={
                                                                teamsData?.data ||
                                                                []
                                                            }
                                                            disableCloseOnSelect
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            isOptionEqualToValue={(
                                                                option,
                                                                value
                                                            ) =>
                                                                option.id ===
                                                                value.id
                                                            }
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li {...props}>
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Teams"
                                                                    placeholder="Select Teams"
                                                                />
                                                            )}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        {equipmentValue.hero_image_id &&
                                                            Object.keys(
                                                                equipmentValue.hero_image_id
                                                            )?.length >= 1 && (
                                                                <>
                                                                    <div className="w-full border relative">
                                                                        <img
                                                                            className="h-80 w-full object-cover rounded-md"
                                                                            src={
                                                                                equipmentValue.hero_image_id
                                                                            }
                                                                            alt="Preview"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                        {previewHeroImage && (
                                                            <div className="w-full relative">
                                                                <img
                                                                    src={
                                                                        previewHeroImage
                                                                    }
                                                                    className="h-80 w-full object-cover border rounded-md"
                                                                    alt="Preview"
                                                                />
                                                                <button
                                                                    onClick={
                                                                        handleRemovePreviewImage
                                                                    }
                                                                    type="button"
                                                                    className="absolute border border-red-500 rounded text-black right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6 text-red-500"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}

                                                        <TextField
                                                            sx={{
                                                                mt: 2,
                                                                mb: 1,
                                                            }}
                                                            fullWidth
                                                            id="hero_image_alt"
                                                            name="hero_image_alt"
                                                            label="Image Alt Text"
                                                            variant="outlined"
                                                            required
                                                            value={
                                                                equipmentValue.hero_image_alt
                                                            }
                                                            onChange={
                                                                handleChangeEquipmentValue
                                                            }
                                                            placeholder="Write the Alt text for this image"
                                                            helperText="This field is required for the SEO purpose"
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                            startIcon={
                                                                <FileUploadIcon />
                                                            }
                                                        >
                                                            Hero Image
                                                            (required)
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept="image/jpeg,image/gif,image/png"
                                                                onChange={
                                                                    handleImageChange
                                                                }
                                                                ref={
                                                                    previewImageInputRef
                                                                }
                                                            />
                                                        </Button>
                                                        <p
                                                            className="helper-text"
                                                            id="heroImage-helper-text"
                                                        >
                                                            Image upload maximum
                                                            size 5MB
                                                        </p>
                                                        <span className="mt-1 block font-medium">
                                                            Size (1200px X
                                                            1400px)
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        {equipmentValue.featured_image_id &&
                                                            Object.keys(
                                                                equipmentValue.featured_image_id
                                                            )?.length >= 1 && (
                                                                <>
                                                                    <div className="w-full border mb-2 relative">
                                                                        <img
                                                                            className="h-80 w-full object-cover rounded-md"
                                                                            src={
                                                                                equipmentValue.featured_image_id
                                                                            }
                                                                            alt="Preview"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                        {previewFeaturedImage && (
                                                            <div className="w-full mb-2 relative">
                                                                <img
                                                                    src={
                                                                        previewFeaturedImage
                                                                    }
                                                                    className="h-80 w-full object-cover rounded-md"
                                                                    alt="Preview"
                                                                />
                                                                <button
                                                                    onClick={
                                                                        handleRemovePreviewFeaturedImage
                                                                    }
                                                                    type="button"
                                                                    className="absolute border border-red-500 rounded right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6 text-red-500"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}

                                                        <TextField
                                                            sx={{
                                                                mt: 1,
                                                            }}
                                                            fullWidth
                                                            id="featured_image_alt"
                                                            name="featured_image_alt"
                                                            label="Featured Image Alt Text"
                                                            variant="outlined"
                                                            required
                                                            value={
                                                                equipmentValue.featured_image_alt
                                                            }
                                                            onChange={
                                                                handleChangeEquipmentValue
                                                            }
                                                            placeholder="Write the Alt text for this image"
                                                            helperText="This field is required for the SEO purpose"
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                            startIcon={
                                                                <FileUploadIcon />
                                                            }
                                                        >
                                                            Featured Image
                                                            (required)
                                                            <input
                                                                type="file"
                                                                hidden
                                                                accept="image/jpeg,image/*,image/png"
                                                                onChange={
                                                                    handleFeaturedImageChange
                                                                }
                                                                ref={
                                                                    previewFeaturedImageInputRef
                                                                }
                                                            />
                                                        </Button>
                                                        <p
                                                            className="helper-text"
                                                            id="heroImage-helper-text"
                                                        >
                                                            Image upload maximum
                                                            size 5MB
                                                        </p>
                                                        <span className="mt-1 block font-medium">
                                                            Size (600px X 600px)
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <TextField
                                                            id="hero_video_id"
                                                            label="Hero Video"
                                                            name="hero_video_id"
                                                            variant="outlined"
                                                            fullWidth
                                                            value={
                                                                equipmentValue.hero_video_id
                                                            }
                                                            onChange={
                                                                handleChangeEquipmentValue
                                                            }
                                                            helperText="Add the video url of ie(Vimeo, YouTube)"
                                                        />
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Types
                                                        </label>

                                                        <Select
                                                            value={types}
                                                            onChange={
                                                                handleChangeSelectType
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            label="Type"
                                                        >
                                                            {equipmentTypes?.data.map(
                                                                (data: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            data.id
                                                                        }
                                                                        value={
                                                                            data.id
                                                                        }
                                                                    >
                                                                        {
                                                                            data.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Position
                                                        </label>
                                                        <TextField
                                                            id="position"
                                                            label="Position"
                                                            name="position"
                                                            variant="outlined"
                                                            fullWidth
                                                            type="number"
                                                            value={
                                                                equipmentValue.position ||
                                                                ""
                                                            }
                                                            onChange={
                                                                handleChangeEquipmentValue
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {faqsFields.map((field, index) => (
                                            <Box
                                                component="div"
                                                border={1}
                                                marginBottom={2}
                                                key={index}
                                                padding={2}
                                            >
                                                <TextField
                                                    id={"question_" + index}
                                                    label="Question"
                                                    name="question[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={field.question}
                                                    sx={{ mb: 3 }}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              question:
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                <TextField
                                                    id={"answer_" + index}
                                                    label="Answer"
                                                    name="answer[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    value={field.answer}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              answer: e
                                                                                  .target
                                                                                  .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                {index !== 0 && (
                                                    <Button
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                index
                                                            )
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </Box>
                                        ))}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button
                                                onClick={handleAddField}
                                                variant="contained"
                                                startIcon={<AddOutlinedIcon />}
                                            >
                                                Add More
                                            </Button>
                                        </div>
                                    </TabPanel>
                                    <TabPanel value={value} index={2}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={equipmentValue.meta_title}
                                            onChange={
                                                handleChangeEquipmentValue
                                            }
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            rows={4}
                                            multiline
                                            sx={{ mb: 3 }}
                                            value={
                                                equipmentValue.meta_description
                                            }
                                            onChange={
                                                handleChangeEquipmentValue
                                            }
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <Box>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        !!equipmentValue.status
                                                    }
                                                    name="status"
                                                    value={
                                                        !!equipmentValue.status
                                                    }
                                                    onChange={(e) =>
                                                        setEquipmentValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                status: e.target
                                                                    .checked,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Status"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        !!equipmentValue.display_home
                                                    }
                                                    name="display_home"
                                                    value={
                                                        !!equipmentValue.display_home
                                                    }
                                                    onChange={(e) =>
                                                        setEquipmentValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                display_home:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Display Home"
                                        />
                                    </Box>
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default EquipmentEdit;
