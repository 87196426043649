import useAxiosHook from "./AxiosHook";

function useServiceHook() {
    const { service } = useAxiosHook();

    async function getService() {
        return await service.get("/getService").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service.get("/service?page=" + page).then((res: any) => {
            return res.data;
        });
    }

    async function deleteService(id: number) {
        return await service.delete("/service/" + id).then((res: any) => {
            return res.data;
        });
    }

    return {
        getService,
        fetch,
        deleteService,
    };
}

export default useServiceHook;
