import { createTheme, ThemeProvider } from "@mui/material/styles";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import Store from "./contexts/Store";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);

const theme = createTheme({
    typography: {
        fontFamily: "Inter",
    },
    palette: {
        primary: {
            main: "#ED1C24",
        },
        secondary: {
            main: "#E33E7F",
        },
    },
});

root.render(
    <BrowserRouter>
        <Store>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </Store>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
