import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { useParams } from "react-router-dom";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import useAxiosHook from "../../hooks/AxiosHook";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function EditPostCategory() {
    const { id } = useParams();

    const [postCategory, setPostcategory] = useState<any>({
        id: null,
        name: "",
        url: "",
        status: false,
        meta_title: "",
        meta_description: "",
    });

    useQuery(["get-post-category"], () => service.get(`post-category/${id}`), {
        refetchOnWindowFocus: false,
        onSuccess: (res) => {
            setPostcategory({
                name: res.data.data.name,
                url: res.data.data.url,
                status: res.data.data.status,
                meta_title: res.data.data.meta_title,
                meta_description: res.data.data.meta_description,
            });
        },
    });

    const [value, setValue] = useState(0);
    const handleChange = (event: any, newValue: number) => {
        setValue(newValue);
    };

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setPostcategory((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleNameChange = (event) => {
        setPostcategory((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setPostcategory((prev) => ({ ...prev, url: slugValue }));
    };

    const { service } = useAxiosHook();

    const [loading, setLoading] = useState(false);

    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });
    const handleSubmit = async (event: any) => {
        event.preventDefault();

        const formData = new FormData();
        formData.append("name", postCategory.name);
        formData.append("url", postCategory.url);
        formData.append("status", postCategory.status ? "1" : "0");
        formData.append("meta_title", postCategory.meta_title);
        formData.append("meta_description", postCategory.meta_description);
        setLoading(true);
        try {
            const response = await service.post(
                `post-category/edit/${id}`,
                formData
            );
            setLoading(false);
            if (response.status === 200) {
                setAlertInfo({
                    show: true,
                    message: "Post Category Updated",
                });
                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 3000);
            }
        } catch (error) {
            console.log(error);
            setLoading(false);
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            <div className="content w-full bg-lightPrimary  min-h-screen">
                <Header
                    headTitle="Edit Post Category"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Post Categories"
                    buttonURL="post-categories"
                />
                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="SEO" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="our-customer-create-form"
                                id="our-customer-create-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <TextField
                                            id="name"
                                            label="Name"
                                            name="name"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={postCategory.name}
                                            onChange={handleNameChange}
                                        />
                                        <TextField
                                            id="url"
                                            label="URL"
                                            name="url"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={postCategory.url}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            type="tel"
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mb: 3 }}
                                            value={postCategory.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            type="text"
                                            variant="outlined"
                                            fullWidth
                                            sx={{ mb: 3 }}
                                            value={
                                                postCategory.meta_description
                                            }
                                            multiline
                                            rows={4}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <FormControlLabel
                                        control={
                                            <Switch
                                                checked={!!postCategory.status}
                                                name="status"
                                                value={!!postCategory.status}
                                                onChange={(e) =>
                                                    setPostcategory((prev) => ({
                                                        ...prev,
                                                        status: e.target
                                                            .checked,
                                                    }))
                                                }
                                            />
                                        }
                                        label="Status"
                                    />
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Update
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default EditPostCategory;
