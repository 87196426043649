import CheckBoxIcon from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Autocomplete,
    Backdrop,
    Box,
    Button,
    Checkbox,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    LinearProgress,
    MenuItem,
    Select,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";

import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { SyntheticEvent, useRef, useState } from "react";
import { useParams } from "react-router";

import DatePickerComponent from "../../components/DatePickerComponent";
import PostInterface from "../../components/Interfaces/PostInterface";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";
import usePostHook from "../../hooks/PostHook";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function PostEdit() {
    const { id } = useParams();
    const { service } = useAxiosHook();

    const { getCategories, getAuthor, getTags } = usePostHook();

    const { data: postCategories, refetch: categoryRefetch } = useQuery({
        queryKey: ["categories"],
        queryFn: getCategories,
        refetchOnWindowFocus: false,
    });

    const { data: postAuthor } = useQuery({
        queryKey: ["authors"],
        queryFn: getAuthor,
        refetchOnWindowFocus: false,
    });

    const { data: postTags, refetch: tagRefetch } = useQuery({
        queryKey: ["tags"],
        queryFn: getTags,
        refetchOnWindowFocus: false,
    });

    const [value, setValue] = useState(0);
    const handleChange = (event: SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    const [author, setTaqableType] = useState("");

    const handleChangeSelect = (event: any) => {
        setTaqableType(event.target.value);
    };

    const [postContent, setPostContent] = useState("");
    const handleContentChange = (content, editor) => {
        setPostContent(content);
    };

    const [selectedDate, setSelectedDate] = useState<Date | null>(null);

    const handleDateChange = (date: Date | null) => {
        setSelectedDate(date);
    };

    const [postInput, setPostValue] = useState<PostInterface>({
        title: "",
        url: "",
        short_description: "",
        media_alt: "",
        status: true,
        displayHome: false,
        position: "",
        content: "",
        media: "",
        author: 0,
        publishAt: new Date(),
        meta_title: "",
        meta_description: "",
    });

    const [loading, setLoading] = useState(false);
    const [loadingBar, setLoadingBar] = useState<boolean>(false);

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setPostValue((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleTitleChange = (event) => {
        setPostValue((prev) => ({ ...prev, title: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setPostValue((prev) => ({ ...prev, url: slugValue }));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        if (postInput.media === "") {
            return alert("Featured image is required.");
        }

        if (postTag.length < 1) {
            return alert("Post tag is required.");
        }

        if (postCategory.length < 1) {
            return alert("Post category is required.");
        }

        if (postCategory.length < 1) {
            return alert("Post category is required.");
        }

        const d = new Date(dayjs(selectedDate).format("L"));
        let postDate =
            d.getFullYear() + "/" + (d.getMonth() + 1) + "/" + d.getDate();

        if (postDate === "") {
            return alert("Post date is required.");
        }

        if (postInput.meta_title === "") {
            return alert("SEO Meta title is required.");
        }

        if (postInput.meta_description === "") {
            return alert("SEO Meta description is required.");
        }

        if (postInput.short_description === "") {
            return alert("Client Quote is required.");
        }

        const formData = new FormData();
        formData.append("title", postInput.title);
        formData.append("url", postInput.url);
        formData.append("short_description", postInput.short_description);
        formData.append("media_id", postInput.media);
        formData.append("media_alt", postInput.media_alt);
        formData.append("content", postContent);
        formData.append("status", postInput.status);
        formData.append("display_home", postInput.displayHome);
        formData.append("position", postInput.position);
        formData.append("post_date", postDate);
        formData.append("author_id", author);
        formData.append("meta_title", postInput.meta_title);
        formData.append("meta_description", postInput.meta_description);

        postTag.forEach((tag, index) => {
            formData.append("tags[]", tag.id);
        });

        postCategory.forEach((cat, index) => {
            formData.append("categories[]", cat.id);
        });

        try {
            setLoading(true);
            const response = await service.post("post/edit/" + id, formData, {
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            });
            setLoading(false);
            if (response.status === 200) {
                setAlertInfo({
                    show: true,
                    message: response.data.message,
                });
                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 1500);
            }

            setLoading(false);
        } catch (error) {
            alert(error);
            setLoading(false);
            console.log(error);
        }
    };

    const [postCategory, setPostCategory] = useState<any[]>([]);
    const [postTag, setPostTag] = useState<any[]>([]);

    // saving images for later use, so that when user upload one
    // image and cancel it, we could refer the original image from here
    const [showAltImage, setShowAltImage] = useState("");

    const { isLoading } = useQuery(
        ["single-post-edit"],
        () => service.get(`post/${id}`),
        {
            onSuccess: (res) => {
                setLoadingBar(true);

                let response = res.data.data;

                setPostValue({
                    title: response.title,
                    url: response.url,
                    short_description: response.short_description,
                    media_alt: response.media_alt,
                    status: response.status,
                    displayHome: response.display_home,
                    position: response.position,
                    content: response.content,
                    media: response.media?.path,
                    author: response.author_id,
                    publishAt: response.publish_at,
                    meta_title: response.meta_title,
                    meta_description: response.meta_description,
                });

                setSelectedDate(response.publish_at);
                setPostContent(response.content);

                setTaqableType(response.author_id);

                const categories = response.categories;
                const transformedCategories = categories.map(
                    (category: any) => {
                        return {
                            id: category.id,
                            name: category.name,
                        };
                    }
                );
                categories.length >= 1 &&
                    setPostCategory(transformedCategories);

                const tags = response.tags;
                const transformedTags = tags.map((tag: any) => {
                    return {
                        id: tag.id,
                        name: tag.name,
                    };
                });

                tags.length >= 1 && setPostTag(transformedTags);

                // setFeaturedImage(res.data.data.media?.path);
                setShowAltImage(res.data.data.media?.path);

                setLoadingBar(false);
            },

            onError: (res) => {
                alert(res);
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        }
    );

    const [previewImage, setPreviewImage] = useState(null);

    // image remove functionality
    const previewImageInputRef = useRef(null);

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        // Reset the value of the input element
        previewImageInputRef.current.value = "";
        setPostValue((prev) => ({ ...prev, media: showAltImage }));
    };

    const handleImageChange = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setPostValue((prev) => ({
                ...prev,
                media: event.target.files[0],
            }));

            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    // adding the shortcut for post tag and post category creatio
    // post category section
    const [showCategory, setShowCategory] = useState(false);
    const [showTag, setShowTag] = useState(false);

    const [postCategoryName, setPostCategoryName] = useState("");
    const [postCategorySlug, setPostCategorySlug] = useState("");

    const handlePCNameChange = (event) => {
        setPostCategoryName(event.target.value);

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setPostCategorySlug(slugValue);
    };

    // submitting post category form

    const [pcLoading, setPcLoading] = useState(false);
    const savePostCategory = async () => {
        const formData = new FormData();
        formData.append("name", postCategoryName);
        formData.append("url", postCategorySlug);
        formData.append("status", "1");

        if (postCategoryName && postCategorySlug) {
            setPcLoading(true);
            try {
                const response = await service.post("post-category", formData);
                setPcLoading(false);
                if (response.status === 200) {
                    setAlertInfo({
                        show: true,
                        message: response.data.message,
                    });

                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: "",
                        });
                    }, 1500);

                    setPostCategoryName("");
                    setPostCategorySlug("");
                    categoryRefetch();
                } else {
                    setAlertInfo({
                        show: true,
                        message: "Something worng",
                    });
                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: "",
                        });
                    }, 1500);
                }
            } catch (error) {
                setPcLoading(false);
                console.log(error);
            }
        }
    };

    // post tag section

    const [postTagName, setPostTagName] = useState("");
    const [postTagSlug, setPostTagSlug] = useState("");

    const handlePTNameChange = (event) => {
        setPostTagName(event.target.value);

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setPostTagSlug(slugValue);
    };

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    // submitting post tag form
    const [ptLoading, setPtLoading] = useState(false);
    const savePostTag = async () => {
        const formData = new FormData();
        formData.append("name", postTagName);
        formData.append("url", postTagSlug);
        formData.append("status", "1");

        if (postTagName && postTagSlug) {
            setPtLoading(true);
            try {
                const response = await service.post("post-tag", formData);
                setPtLoading(false);
                if (response.status === 200) {
                    setAlertInfo({
                        show: true,
                        message: response.data.message,
                    });
                    setPostTagName("");
                    setPostTagSlug("");
                    tagRefetch();

                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: "",
                        });
                    }, 1500);
                } else {
                    setAlertInfo({
                        show: true,
                        message: "Something went wrong",
                    });
                    setTimeout(() => {
                        setAlertInfo({
                            show: false,
                            message: "",
                        });
                    }, 1500);
                }
            } catch (error) {
                setPcLoading(false);
                console.log(error);
            }
        }
    };

    const handleTagKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault(); // Prevent parent form submission
            savePostTag();
        }
    };

    const handleCategoryKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault(); // Prevent parent form submission
            savePostCategory();
        }
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            {isLoading && (
                <div style={{ width: "100%", position: "absolute" }}>
                    <LinearProgress />
                </div>
            )}

            <Box
                component="div"
                className="content w-full bg-lightPrimary min-h-screen"
            >
                <Header
                    headTitle="Post Edit"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Post List"
                    buttonURL="posts"
                />
                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500 w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="SEO" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="post-form"
                                id="post-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup className="formGroup">
                                    {/* Information Section */}
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                className="bg-white p-4"
                                                item
                                                xs={12}
                                                md={8}
                                            >
                                                <TextField
                                                    id="title"
                                                    label="Title"
                                                    name="title"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={postInput.title}
                                                    onChange={handleTitleChange}
                                                />
                                                <TextField
                                                    id="url"
                                                    label="URL"
                                                    name="url"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={postInput.url}
                                                    onChange={handleChangeValue}
                                                />
                                                <TextField
                                                    id="short_description"
                                                    label="Quote"
                                                    name="short_description"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                    value={
                                                        postInput.short_description
                                                    }
                                                    onChange={handleChangeValue}
                                                />

                                                <label className="mb-2 block">
                                                    Content
                                                </label>
                                                <Box
                                                    component="div"
                                                    className="border-4 mt-2 mb-8 border-gray-400 rounded"
                                                >
                                                    <TinyEditor
                                                        handleEditorChange={(
                                                            content,
                                                            editor
                                                        ) =>
                                                            handleContentChange(
                                                                content,
                                                                editor
                                                            )
                                                        }
                                                        contentData={
                                                            postContent
                                                        }
                                                    />
                                                </Box>
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow"
                                                        sx={{
                                                            mb: 2,
                                                            width: "100%",
                                                        }}
                                                    >
                                                        <label className="mb-2 block">
                                                            Publish Date
                                                        </label>

                                                        <DatePickerComponent
                                                            label="Publish Date"
                                                            value={selectedDate}
                                                            onChange={
                                                                handleDateChange
                                                            }
                                                        />
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        {postInput.media &&
                                                            Object.keys(
                                                                postInput.media
                                                            )?.length >= 1 && (
                                                                <>
                                                                    <div className="w-full mb-4 relative">
                                                                        <img
                                                                            className="h-80 w-full object-cover rounded-md"
                                                                            src={
                                                                                postInput.media
                                                                            }
                                                                            alt="Preview"
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                        {previewImage && (
                                                            <div className="w-full mb-4 relative">
                                                                <img
                                                                    className="h-80 w-full object-cover mb-4 rounded-md"
                                                                    src={
                                                                        previewImage
                                                                    }
                                                                    alt="Preview"
                                                                />
                                                                <TextField
                                                                    fullWidth
                                                                    id="outlined-basic"
                                                                    label="Image Alt Text"
                                                                    variant="outlined"
                                                                    required
                                                                    placeholder="Write the Alt text for this image"
                                                                    helperText="This field is required for the SEO purpose"
                                                                />
                                                                <button
                                                                    type="button"
                                                                    onClick={
                                                                        handleRemovePreviewImage
                                                                    }
                                                                    className="absolute border border-red-500 rounded text-red-500 right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                            startIcon={
                                                                <FileUploadIcon />
                                                            }
                                                        >
                                                            Featured Image
                                                            <input
                                                                ref={
                                                                    previewImageInputRef
                                                                }
                                                                type="file"
                                                                hidden
                                                                accept="image/*"
                                                                onChange={
                                                                    handleImageChange
                                                                }
                                                            />
                                                        </Button>
                                                        <p
                                                            className="helper-text"
                                                            id="heroImage-helper-text"
                                                        >
                                                            Image upload maximum
                                                            size 5MB
                                                        </p>
                                                        <span className="mt-1 block font-medium">
                                                            Size (1200px X
                                                            1400px)
                                                        </span>
                                                        <TextField
                                                            sx={{
                                                                mt: 2,
                                                            }}
                                                            fullWidth
                                                            label="Image Alt Text"
                                                            variant="outlined"
                                                            required
                                                            id="media_alt"
                                                            name="media_alt"
                                                            value={
                                                                postInput.media_alt
                                                            }
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                            placeholder="Write the Alt text for this image"
                                                            helperText="This field is required for the SEO purpose"
                                                        />
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Author *
                                                        </label>

                                                        <Select
                                                            value={author}
                                                            onChange={
                                                                handleChangeSelect
                                                            }
                                                            sx={{
                                                                width: "100%",
                                                            }}
                                                            label="Author"
                                                        >
                                                            {postAuthor?.data.map(
                                                                (data: any) => (
                                                                    <MenuItem
                                                                        key={
                                                                            data.id
                                                                        }
                                                                        value={
                                                                            data.id
                                                                        }
                                                                    >
                                                                        {
                                                                            data.name
                                                                        }
                                                                    </MenuItem>
                                                                )
                                                            )}
                                                        </Select>
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <label className="mb-2 block">
                                                            Categories *
                                                        </label>

                                                        <Autocomplete
                                                            id="category-id-demo"
                                                            options={
                                                                postCategories?.data ||
                                                                []
                                                            }
                                                            value={postCategory}
                                                            multiple
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setPostCategory(
                                                                    newValue
                                                                );
                                                            }}
                                                            disableCloseOnSelect
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li
                                                                    {...props}
                                                                    key={
                                                                        option.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Categories"
                                                                    placeholder="Select Categories"
                                                                />
                                                            )}
                                                        />
                                                        <p
                                                            className="underline my-2 cursor-pointer"
                                                            onClick={() =>
                                                                setShowCategory(
                                                                    (prev) =>
                                                                        !prev
                                                                )
                                                            }
                                                        >
                                                            Add new post
                                                            category
                                                        </p>
                                                        {showCategory && (
                                                            <div>
                                                                <TextField
                                                                    id="title"
                                                                    label="Category Name"
                                                                    name="postCategoryName"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{
                                                                        mb: 3,
                                                                    }}
                                                                    value={
                                                                        postCategoryName
                                                                    }
                                                                    onChange={
                                                                        handlePCNameChange
                                                                    }
                                                                    onKeyDown={
                                                                        handleCategoryKeyDown
                                                                    }
                                                                />

                                                                <Box display="flex">
                                                                    <div>
                                                                        {pcLoading ? (
                                                                            <CircularProgress color="secondary" />
                                                                        ) : (
                                                                            <Button
                                                                                onClick={
                                                                                    savePostCategory
                                                                                }
                                                                                color="success"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                type="button"
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            className="!ml-2 "
                                                                            variant="outlined"
                                                                            size="small"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setShowCategory(
                                                                                    (
                                                                                        prev
                                                                                    ) =>
                                                                                        !prev
                                                                                )
                                                                            }
                                                                        >
                                                                            cancel
                                                                        </Button>
                                                                    </div>
                                                                </Box>
                                                            </div>
                                                        )}
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 mb-4 shadow"
                                                    >
                                                        <label className="mb-2 block">
                                                            Tags *
                                                        </label>

                                                        <Autocomplete
                                                            id="tag-id-demo"
                                                            options={
                                                                postTags?.data ||
                                                                []
                                                            }
                                                            multiple
                                                            value={postTag}
                                                            getOptionLabel={(
                                                                option
                                                            ) => option?.name}
                                                            onChange={(
                                                                event,
                                                                newValue
                                                            ) => {
                                                                setPostTag(
                                                                    newValue
                                                                );
                                                            }}
                                                            disableCloseOnSelect
                                                            renderOption={(
                                                                props,
                                                                option,
                                                                { selected }
                                                            ) => (
                                                                <li
                                                                    {...props}
                                                                    key={
                                                                        option.id
                                                                    }
                                                                >
                                                                    <Checkbox
                                                                        icon={
                                                                            icon
                                                                        }
                                                                        checkedIcon={
                                                                            checkedIcon
                                                                        }
                                                                        style={{
                                                                            marginRight: 8,
                                                                        }}
                                                                        checked={
                                                                            selected
                                                                        }
                                                                    />
                                                                    {
                                                                        option?.name
                                                                    }
                                                                </li>
                                                            )}
                                                            renderInput={(
                                                                params
                                                            ) => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Tags"
                                                                    placeholder="Select Tags"
                                                                />
                                                            )}
                                                        />

                                                        <p
                                                            className="underline my-2 cursor-pointer"
                                                            onClick={() =>
                                                                setShowTag(
                                                                    (prev) =>
                                                                        !prev
                                                                )
                                                            }
                                                        >
                                                            Add new post tag
                                                        </p>
                                                        {showTag && (
                                                            <div>
                                                                <TextField
                                                                    id="post_tag_create"
                                                                    label="Tag Name"
                                                                    name="postTagName"
                                                                    variant="outlined"
                                                                    fullWidth
                                                                    sx={{
                                                                        mb: 3,
                                                                    }}
                                                                    value={
                                                                        postTagName
                                                                    }
                                                                    onChange={
                                                                        handlePTNameChange
                                                                    }
                                                                    onKeyDown={
                                                                        handleTagKeyDown
                                                                    }
                                                                />

                                                                <Box display="flex">
                                                                    <div>
                                                                        {ptLoading ? (
                                                                            <CircularProgress color="secondary" />
                                                                        ) : (
                                                                            <Button
                                                                                onClick={
                                                                                    savePostTag
                                                                                }
                                                                                color="success"
                                                                                variant="outlined"
                                                                                size="small"
                                                                                type="button"
                                                                            >
                                                                                Add
                                                                            </Button>
                                                                        )}
                                                                    </div>
                                                                    <div>
                                                                        <Button
                                                                            className="!ml-2 "
                                                                            variant="outlined"
                                                                            size="small"
                                                                            type="button"
                                                                            onClick={() =>
                                                                                setShowTag(
                                                                                    (
                                                                                        prev
                                                                                    ) =>
                                                                                        !prev
                                                                                )
                                                                            }
                                                                        >
                                                                            cancel
                                                                        </Button>
                                                                    </div>
                                                                </Box>
                                                            </div>
                                                        )}
                                                    </Box>

                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow"
                                                    >
                                                        <label className="mb-2 block">
                                                            Position
                                                        </label>
                                                        <TextField
                                                            id="position"
                                                            label="Position"
                                                            name="position"
                                                            type="tel"
                                                            variant="outlined"
                                                            fullWidth
                                                            inputProps={{
                                                                maxLength: 2,
                                                            }}
                                                            value={
                                                                postInput.position
                                                            }
                                                            onChange={
                                                                handleChangeValue
                                                            }
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>

                                    {/* SEO Section */}
                                    <TabPanel value={value} index={1}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={postInput.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            rows={4}
                                            multiline
                                            sx={{ mb: 3 }}
                                            value={postInput.meta_description}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <Box component="div">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={!!postInput.status}
                                                    name="status"
                                                    value={!!postInput.status}
                                                    onChange={(e) =>
                                                        setPostValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                status: e.target
                                                                    .checked,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Status"
                                        />
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        !!postInput.displayHome
                                                    }
                                                    name="displayHome"
                                                    value={
                                                        !!postInput.displayHome
                                                    }
                                                    onChange={(e) =>
                                                        setPostValue(
                                                            (prev) => ({
                                                                ...prev,
                                                                displayHome:
                                                                    e.target
                                                                        .checked,
                                                            })
                                                        )
                                                    }
                                                />
                                            }
                                            label="Display Home"
                                        />
                                    </Box>

                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",

                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>

                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}

export default PostEdit;
