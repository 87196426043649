import useAxiosHook from "./AxiosHook";

function useCompletedProjectHook() {
    const { service } = useAxiosHook();

    async function getProjects() {
        return await service.get("/getProjects").then((res: any) => {
            return res.data;
        });
    }

    async function fetch(page: number) {
        return await service
            .get("/completed-project?page=" + page)
            .then((res: any) => {
                return res.data;
            });
    }

    async function deleteProject(id: number) {
        return await service
            .delete("/completed-project/" + id)
            .then((res: any) => {
                return res.data;
            });
    }

    return {
        getProjects,
        fetch,
        deleteProject,
    };
}

export default useCompletedProjectHook;
