import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import SendIcon from "@mui/icons-material/Send";
import {
    Backdrop,
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    FormGroup,
    Grid,
    LinearProgress,
    Switch,
    Tab,
    Tabs,
    TextField,
    Typography,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { useParams } from "react-router";
import Header from "../../components/Layouts/Header";
import Sidebar from "../../components/Layouts/Sidebar";
import SnackBarAlert from "../../components/shared/SnackBarAlert";
import TinyEditor from "../../components/shared/TinyEditor";
import useAxiosHook from "../../hooks/AxiosHook";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
}

function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3, px: 0 }}>{children}</Box>}
        </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        "aria-controls": `simple-tabpanel-${index}`,
    };
}

function BrandEdit() {
    const { id } = useParams();

    const { isLoading } = useQuery(
        ["single-case-edit"],
        () => service.get(`brand/${id}`),
        {
            onSuccess: (res) => {
                let response = res.data.data;

                setBrand({
                    heroImage: response.hero_image?.path,
                    logo: response.logo?.path,
                    logo_alt: response.logo_alt,
                    hero_image_alt: response.hero_image_alt,
                    name: response.name,
                    url: response.url,
                    website: response.website,
                    about: response.about,
                    status: response.status,
                    position: response.position,
                    meta_title: response.meta_title,
                    meta_description: response.meta_description,
                });

                setShowAltImage(response.hero_image?.path);
                setShowAltLogo(response.logo?.path);
                response.faqs.length >= 1 && setFaqsFields(response.faqs);
            },

            onError: (res) => {
                alert(res);
            },
            refetchOnWindowFocus: false,
            refetchOnReconnect: true,
        }
    );

    const [brand, setBrand] = useState({
        name: "",
        url: "",
        website: "",
        about: "",
        position: "",
        logo: "",
        logo_alt: "",
        heroImage: "",
        hero_image_alt: "",
        status: false,
        meta_title: "",
        meta_description: "",
    });

    const handleChangeValue = (e: any) => {
        const { name, value } = e.target;
        setBrand((prevValues) => ({
            ...prevValues,
            [name]: value,
        }));
    };

    const handleInputChange = (e: any) => {
        const { name, value } = e.target;
        setBrand((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const [value, setValue] = React.useState(0);
    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    // for the faqs
    const handleRemoveField = (index: any) => {
        setFaqsFields(
            faqsFields.slice(0, index).concat(faqsFields.slice(index + 1))
        );
    };

    const handleContentChange = (content, editor) => {
        setBrand((prev) => ({ ...prev, about: content }));
    };

    // alert section
    const [alertInfo, setAlertInfo] = useState({
        show: false,
        message: "",
    });

    // Hero Image
    const previewImageInputRef = useRef(null);
    const [heroImage, setHeroImage] = useState("");
    const [previewImage, setPreviewImage] = useState(null);
    const [showAltImage, setShowAltImage] = useState("");

    const handleHeroImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setBrand((prev) => ({
                ...prev,
                heroImage: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewImage(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewImage = () => {
        setPreviewImage(null);
        setHeroImage("");
        // Reset the value of the input element
        previewImageInputRef.current.value = null;
        setBrand((prev) => ({ ...prev, heroImage: showAltImage }));
    };

    // Logo Image
    const previewLogoInputRef = useRef(null);
    const [logoImage, setLogoImage] = useState("");
    const [previewLogo, setPreviewLogo] = useState(null);
    const [showAltLogo, setShowAltLogo] = useState("");

    const handleLogoImage = (event: any) => {
        if (event.target.files && event.target.files.length > 0) {
            if (Math.round(event.target.files[0].size / (1024 * 1024)) > 5) {
                return alert(
                    "The image size is greater than 5 MB, choose another image."
                );
            }
            setBrand((prev) => ({
                ...prev,
                logo: event.target.files[0],
            }));
            const reader = new FileReader();
            reader.onloadend = () => {
                setPreviewLogo(reader.result);
            };
            reader.readAsDataURL(event.target.files[0]);
        }
    };

    const handleRemovePreviewLogo = () => {
        setPreviewLogo(null);
        setLogoImage("");
        // Reset the value of the input element
        previewLogoInputRef.current.value = null;
        setBrand((prev) => ({ ...prev, logo: showAltLogo }));
    };

    const { service } = useAxiosHook();
    const [loading, setLoading] = useState(false);
    const [faqsFields, setFaqsFields] = React.useState([
        {
            question: "",
            answer: "",
        },
    ]);

    // handling the name and automatic url slug generator
    const handleTitleChange = (event) => {
        setBrand((prev) => ({ ...prev, name: event.target.value }));

        const slugValue = event.target.value
            .toLowerCase()
            .replace(/\s+/g, "-") // Replace whitespace with hyphens
            .replace(/[^a-zA-Z0-9-]/g, ""); // Remove non-alphanumeric characters except hyphens
        setBrand((prev) => ({ ...prev, url: slugValue }));
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const formData = new FormData();

        if (brand.heroImage === "") {
            return alert("Hero image is required.");
        }

        if (brand.logo === "") {
            return alert("Logo image is required.");
        }

        if (brand.meta_title === "") {
            return alert("SEO Meta title is required.");
        }

        if (brand.meta_description === "") {
            return alert("SEO Meta description is required.");
        }

        formData.append("name", brand.name);
        formData.append("url", brand.url);
        formData.append("about", brand.about);
        formData.append("website", brand.website);
        formData.append("position", brand.position);
        formData.append("logo_id", brand.logo);
        formData.append("logo_alt", brand.logo_alt);
        formData.append("hero_image_id", brand.heroImage);
        formData.append("hero_image_alt", brand.hero_image_alt);
        formData.append("status", brand.status ? "1" : "0");
        formData.append("meta_title", brand.meta_title);
        formData.append("meta_description", brand.meta_description);

        faqsFields.forEach((q, index) => {
            formData.append("question[]", q.question);
            formData.append("answer[]", q.answer);
        });

        setLoading(true);

        try {
            const { status, data } = await service.post(
                "brand/edit/" + id,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                        Accept: "application/json",
                    },
                }
            );
            setLoading(false);
            if (status === 200) {
                setLoading(false);
                setAlertInfo({
                    show: true,
                    message: data.message,
                });
                setTimeout(() => {
                    setAlertInfo({
                        show: false,
                        message: "",
                    });
                }, 1500);
            } else {
                setLoading(false);
                setAlertInfo({
                    show: false,
                    message: "Something went wrong",
                });
            }
        } catch (error) {
            setLoading(false);
        }
    };

    const handleAddField = () => {
        setFaqsFields([
            ...faqsFields,
            {
                question: "",
                answer: "",
            },
        ]);
    };

    return (
        <Box component="div" className="flex">
            <Sidebar />

            <SnackBarAlert show={alertInfo.show} message={alertInfo.message} />

            <div className="content w-full bg-lightPrimary min-h-screen">
                <Header
                    headTitle="Edit Brand"
                    buttonIcon={<ReplyIcon />}
                    buttonText="Brands"
                    buttonURL="brands"
                />
                {isLoading && (
                    <div style={{ width: "100%" }}>
                        <LinearProgress />
                    </div>
                )}

                <Box
                    component="div"
                    className="pt-5 mx-auto mb-auto h-full min-h-[84vh] p-4 md:pr-2"
                >
                    <Box
                        component="div"
                        className="bg-white bg-clip-border shadow-3xl shadow-shadow-500  w-full min-h-screen sm:overflow-auto px-6"
                    >
                        <Box component="div" sx={{ width: "100%" }}>
                            <Box
                                component="div"
                                sx={{ borderBottom: 1, borderColor: "divider" }}
                            >
                                <Tabs
                                    value={value}
                                    onChange={handleChange}
                                    aria-label="basic tabs"
                                >
                                    <Tab
                                        label="Information"
                                        {...a11yProps(0)}
                                    />
                                    <Tab label="Faqs" {...a11yProps(1)} />
                                    <Tab label="SEO" {...a11yProps(2)} />
                                </Tabs>
                            </Box>
                            <Box
                                component="form"
                                autoComplete="off"
                                name="brand-create-form"
                                id="brand-create-form"
                                encType="multipart/form-data"
                                className="myForm"
                                onSubmit={handleSubmit}
                            >
                                <FormGroup>
                                    <TabPanel value={value} index={0}>
                                        <Grid container spacing={2}>
                                            <Grid
                                                item
                                                className="bg-white p-4"
                                                xs={12}
                                                md={8}
                                            >
                                                <TextField
                                                    id="name"
                                                    label="Name"
                                                    name="name"
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    value={brand.name}
                                                    onChange={handleTitleChange}
                                                    sx={{ mb: 3 }}
                                                />

                                                <TextField
                                                    id="url"
                                                    label="URL"
                                                    name="url"
                                                    value={brand.url}
                                                    onChange={handleInputChange}
                                                    variant="outlined"
                                                    fullWidth
                                                    required
                                                    sx={{ mb: 3 }}
                                                />

                                                <span className="text-gray-800 m-2">
                                                    About *
                                                </span>

                                                <TinyEditor
                                                    handleEditorChange={(
                                                        content,
                                                        editor
                                                    ) =>
                                                        handleContentChange(
                                                            content,
                                                            editor
                                                        )
                                                    }
                                                    contentData={brand.about}
                                                />
                                            </Grid>
                                            <Grid
                                                item
                                                style={{
                                                    paddingTop: 0,
                                                    marginTop: "-8px",
                                                    paddingLeft: 0,
                                                }}
                                                className="bg-gray-100"
                                                xs={12}
                                                md={4}
                                            >
                                                <Box className="p-3">
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        {brand.logo &&
                                                            Object.keys(
                                                                brand.logo
                                                            )?.length >= 1 && (
                                                                <div className="w-full mb-4 relative border">
                                                                    <img
                                                                        className="h-80 w-full object-cover rounded-md"
                                                                        src={
                                                                            brand.logo
                                                                        }
                                                                        alt="Preview"
                                                                    />
                                                                </div>
                                                            )}

                                                        {previewLogo && (
                                                            <div className="mb-4 w-full relative border">
                                                                <img
                                                                    className="h-80 w-full object-cover rounded-md"
                                                                    src={
                                                                        previewLogo
                                                                    }
                                                                    alt="Preview"
                                                                />

                                                                <button
                                                                    onClick={
                                                                        handleRemovePreviewLogo
                                                                    }
                                                                    type="button"
                                                                    className="absolute border border-black rounded text-black right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}

                                                        <TextField
                                                            sx={{
                                                                mt: 1,
                                                            }}
                                                            fullWidth
                                                            id="logo_alt"
                                                            name="logo_alt"
                                                            value={
                                                                brand.logo_alt
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            label="Logo Image Alt Text"
                                                            variant="outlined"
                                                            required
                                                            placeholder="Write the Alt text for this image"
                                                            helperText="This field is required for the SEO purpose"
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                        >
                                                            Upload Logo *
                                                            <input
                                                                name="logo_id"
                                                                type="file"
                                                                ref={
                                                                    previewLogoInputRef
                                                                }
                                                                hidden
                                                                accept="image/*"
                                                                onChange={
                                                                    handleLogoImage
                                                                }
                                                            />
                                                        </Button>
                                                        <p
                                                            className="helper-text"
                                                            id="heroImage-helper-text"
                                                        >
                                                            Image upload maximum
                                                            size 5MB
                                                        </p>
                                                        <span className="mt-1 block font-medium">
                                                            Size (800px X 800px)
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        {brand.heroImage &&
                                                            Object.keys(
                                                                brand.heroImage
                                                            )?.length >= 1 && (
                                                                <div className="w-full mb-4 relative border">
                                                                    <img
                                                                        className="h-80 w-full object-cover rounded-md"
                                                                        src={
                                                                            brand.heroImage
                                                                        }
                                                                        alt="Preview"
                                                                    />
                                                                </div>
                                                            )}

                                                        {previewImage && (
                                                            <div className="w-full border mb-4 relative">
                                                                <img
                                                                    className="h-80 w-full object-cover  rounded-md"
                                                                    src={
                                                                        previewImage
                                                                    }
                                                                    alt="Preview"
                                                                />

                                                                <button
                                                                    onClick={
                                                                        handleRemovePreviewImage
                                                                    }
                                                                    type="button"
                                                                    className="absolute border border-black rounded text-black right-4 top-4"
                                                                >
                                                                    <svg
                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                        fill="none"
                                                                        viewBox="0 0 24 24"
                                                                        strokeWidth="1.5"
                                                                        stroke="currentColor"
                                                                        className="w-6 h-6"
                                                                    >
                                                                        <path
                                                                            strokeLinecap="round"
                                                                            strokeLinejoin="round"
                                                                            d="M6 18L18 6M6 6l12 12"
                                                                        />
                                                                    </svg>
                                                                </button>
                                                            </div>
                                                        )}
                                                        <TextField
                                                            sx={{
                                                                mt: 1,
                                                            }}
                                                            fullWidth
                                                            id="outlined-basic"
                                                            label="Hero Image Alt Text"
                                                            variant="outlined"
                                                            required
                                                            name="hero_image_alt"
                                                            value={
                                                                brand.hero_image_alt
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            placeholder="Write the Alt text for this image"
                                                            helperText="This field is required for the SEO purpose"
                                                        />

                                                        <Button
                                                            variant="contained"
                                                            component="label"
                                                            color="info"
                                                        >
                                                            Upload Hero Image *
                                                            <input
                                                                name="logo_id"
                                                                type="file"
                                                                ref={
                                                                    previewImageInputRef
                                                                }
                                                                hidden
                                                                accept="image/*"
                                                                onChange={
                                                                    handleHeroImage
                                                                }
                                                            />
                                                        </Button>
                                                        <p
                                                            className="helper-text"
                                                            id="heroImage-helper-text"
                                                        >
                                                            Image upload maximum
                                                            size 5MB
                                                        </p>
                                                        <span className="mt-1 block font-medium">
                                                            Size (1200px X
                                                            1400px)
                                                        </span>
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <TextField
                                                            id="website"
                                                            label="Website"
                                                            name="website"
                                                            type="url"
                                                            variant="outlined"
                                                            value={
                                                                brand.website
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            fullWidth
                                                        />
                                                    </Box>
                                                    <Box
                                                        component="div"
                                                        className="border bg-white p-3.5 shadow mb-4"
                                                    >
                                                        <TextField
                                                            id="position"
                                                            label="Position"
                                                            name="position"
                                                            type="number"
                                                            variant="outlined"
                                                            value={
                                                                brand.position
                                                            }
                                                            onChange={
                                                                handleInputChange
                                                            }
                                                            fullWidth
                                                            inputProps={{
                                                                maxLength: 2,
                                                            }}
                                                        />
                                                    </Box>
                                                </Box>
                                            </Grid>
                                        </Grid>
                                    </TabPanel>
                                    <TabPanel value={value} index={1}>
                                        {faqsFields.map((field, index) => (
                                            <Box
                                                component="div"
                                                border={1}
                                                marginBottom={2}
                                                key={index}
                                                padding={2}
                                            >
                                                <TextField
                                                    id={"question_" + index}
                                                    label="Question"
                                                    name="question[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    value={field.question}
                                                    sx={{ mb: 3 }}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              question:
                                                                                  e
                                                                                      .target
                                                                                      .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                <TextField
                                                    id={"answer_" + index}
                                                    label="Answer"
                                                    name="answer[]"
                                                    variant="outlined"
                                                    fullWidth
                                                    rows={4}
                                                    multiline
                                                    value={field.answer}
                                                    onChange={(e) =>
                                                        setFaqsFields(
                                                            faqsFields.map(
                                                                (f, i) =>
                                                                    i === index
                                                                        ? {
                                                                              ...f,
                                                                              answer: e
                                                                                  .target
                                                                                  .value,
                                                                          }
                                                                        : f
                                                            )
                                                        )
                                                    }
                                                />
                                                {index !== 0 && (
                                                    <Button
                                                        onClick={() =>
                                                            handleRemoveField(
                                                                index
                                                            )
                                                        }
                                                        variant="outlined"
                                                        startIcon={
                                                            <DeleteIcon />
                                                        }
                                                        sx={{ mt: 3 }}
                                                    >
                                                        Remove
                                                    </Button>
                                                )}
                                            </Box>
                                        ))}
                                        <div
                                            style={{
                                                display: "flex",
                                                justifyContent: "end",
                                            }}
                                        >
                                            <Button
                                                onClick={handleAddField}
                                                variant="contained"
                                                startIcon={<AddOutlinedIcon />}
                                            >
                                                Add More
                                            </Button>
                                        </div>
                                    </TabPanel>

                                    <TabPanel value={value} index={2}>
                                        <TextField
                                            id="meta_title"
                                            label="Meta Title"
                                            name="meta_title"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            sx={{ mb: 3 }}
                                            value={brand.meta_title}
                                            onChange={handleChangeValue}
                                        />
                                        <TextField
                                            id="meta_description"
                                            label="Meta Description"
                                            name="meta_description"
                                            variant="outlined"
                                            fullWidth
                                            required
                                            multiline
                                            rows={4}
                                            sx={{ mb: 3 }}
                                            value={brand.meta_description}
                                            onChange={handleChangeValue}
                                        />
                                    </TabPanel>
                                </FormGroup>
                                <Box
                                    component="div"
                                    sx={{
                                        borderTop: 1,
                                        borderColor: "divider",
                                        display: "flex",
                                        justifyContent: "space-between",
                                        py: "20px",
                                    }}
                                >
                                    <Box component="div">
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={
                                                        brand.status
                                                            ? true
                                                            : false
                                                    }
                                                    name="status"
                                                    value={!!brand.status}
                                                    onChange={(e) =>
                                                        setBrand((prev) => ({
                                                            ...prev,
                                                            status: e.target
                                                                .checked
                                                                ? true
                                                                : false,
                                                        }))
                                                    }
                                                />
                                            }
                                            label="Status"
                                        />
                                    </Box>
                                    {loading ? (
                                        <Backdrop
                                            sx={{
                                                color: "#fff",
                                                zIndex: (theme) =>
                                                    theme.zIndex.drawer + 1,
                                            }}
                                            open={true}
                                        >
                                            <Typography sx={{ mr: 2 }}>
                                                Saving...
                                            </Typography>
                                            <CircularProgress color="secondary" />
                                        </Backdrop>
                                    ) : (
                                        <Button
                                            variant="outlined"
                                            size="large"
                                            type="submit"
                                            endIcon={<SendIcon />}
                                        >
                                            Save
                                        </Button>
                                    )}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </div>
        </Box>
    );
}

export default BrandEdit;
